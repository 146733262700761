import uploadBase from "@/store/modules/uploadBase";
import getFileExt from "@/utils/getFileExt";
import trimExt from "@/utils/trimExt";
import { FILE_STATUS } from "@/utils/constants";

const { READY, FAILED, MISSING, AWAITING } = FILE_STATUS;

const state = { ...uploadBase.state() };

const getters = {
  ...uploadBase.getters,
  filesByType: (state, getters) => (projectId, ext) => {
    return getters.files(projectId).filter((file) => {
      return getFileExt(file.file.name) === ext;
    });
  },
  filesToUpload: (state, getters) => (projectId) => {
    return getters
      .filesByStatus(AWAITING, projectId)
      .filter((f) => getFileExt(f.file.name) !== "json");
  },
  getPairedFile: (state, getters) => (projectId, file) => {
    if (!file) return;
    const match = getters.files(projectId).find((f) => {
      if (getFileExt(file.file.name) === getFileExt(f.file.name)) return false;
      return trimExt(file.file.name) === trimExt(f.file.name);
    });
    return match;
  },
  rowData: (state, getters) => (projectId) => {
    return getters
      .files(projectId)
      .map((f) => {
        const pairedFile = getters.getPairedFile(projectId, f);
        return {
          ...f,
          document_name: f.file.name + (pairedFile ? " (Paired)" : ""),
          actions: {
            enabled: [READY, AWAITING, MISSING, FAILED].includes(f.status),
            name: f.file.name,
          },
          status: {
            message: f.status,
            tooltip: f.tooltip,
          },
        };
      })
      .filter((row) => row)
      .filter((row) => {
        return (
          getFileExt(row.file.name) !== "json" || row.status.message === MISSING
        );
      });
  },
};

const mutations = {
  ...uploadBase.mutations,
};

const actions = {
  ...uploadBase.actions,
  addFiles: ({ commit, dispatch }, payload) => {
    commit("addFiles", payload);
    dispatch("setOrphans", payload);
  },
  setOrphans: ({ commit, getters }, { projectId }) => {
    const readyFiles = getters.filesByStatus(READY, projectId);
    const orphans = readyFiles.filter((file) => {
      const pairedFile = getters.getPairedFile(projectId, file);
      return getFileExt(file.file.name) === "json" && !pairedFile;
    });

    commit("setFileStatusMany", { projectId, files: orphans, status: MISSING });
  },
  removeFile: ({ commit, getters }, payload) => {
    const file = getters.fileByName(payload.projectId, payload.name);
    const pairedFile = getters.getPairedFile(payload.projectId, file);
    commit("removeFile", payload);
    if (pairedFile) {
      commit("removeFile", {
        projectId: payload.projectId,
        name: pairedFile.file.name,
      });
    }
  },
  initiateUpload: async ({ dispatch, getters, commit }, projectId) => {
    dispatch("prepareForUpload", { projectId });
    if (getters.uploading(projectId)) return;

    commit("setUploadStatus", { projectId, uploading: true });
    while (getters.filesToUpload(projectId).length) {
      const file = getters.filesToUpload(projectId)[0];
      const pairedFile = getters.getPairedFile(projectId, file);
      await dispatch("uploadFile", { file, pairedFile, projectId });
    }
    dispatch("summaryNotification", { projectId });
    dispatch("refreshProjectDocuments", { projectId }, { root: true });
    commit("setUploadStatus", { projectId, uploading: false });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
