<template>
  <nav
    class="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-gray-700"
    aria-label="Sidebar"
  >
    <div class="px-2 space-y-1">
      <router-link
        v-if="accountIdParam"
        :to="{ name: 'Projects', params: { accountId: accountIdParam } }"
        class="flex items-center px-2 py-2 text-sm font-medium leading-6 rounded-md group"
        :class="
          isActiveLink('Projects')
            ? 'bg-gray-700 text-white'
            : 'text-gray-100 hover:text-white hover:bg-gray-900'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 mr-4 text-gray-200 shrink-0"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
          />
        </svg>
        Projects
      </router-link>
    </div>
    <div class="pt-6 mt-6">
      <div class="px-2 space-y-1">
        <router-link
          v-if="accountIdParam"
          :to="{ name: 'Password', params: { accountId: accountIdParam } }"
          class="flex items-center px-2 py-2 text-sm font-medium leading-6 rounded-md group"
          :class="
            isActiveLink('Settings')
              ? 'bg-gray-700 text-white'
              : 'text-gray-100 hover:text-white hover:bg-gray-900'
          "
          aria-current="page"
        >
          <svg
            class="w-6 h-6 mr-4 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          Settings
        </router-link>
        <router-link
          :to="{ name: 'Help' }"
          class="flex items-center px-2 py-2 text-sm font-medium leading-6 rounded-md group"
          :class="
            isActiveLink('Help')
              ? 'bg-gray-700 text-white'
              : 'text-gray-100 hover:text-white hover:bg-gray-900'
          "
          aria-current="page"
        >
          <svg
            class="w-6 h-6 mr-4 text-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          Help
        </router-link>
      </div>
      <div v-if="account" class="absolute bottom-0 w-full">
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <div
            v-if="showUserDropdown"
            class="absolute w-full -mb-2 overflow-y-auto shadow-lg bottom-20 max-h-96"
          >
            <div
              class="bg-white border-solid divide-y divide-gray-200 shadow-xs focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <div class="py-1" role="none">
                <a
                  class="block px-4 py-2 text-sm text-gray-700 border-solid cursor-pointer hover:bg-gray-100"
                  role="menuitem"
                  @click="signOut()"
                >
                  Sign out
                </a>
              </div>
              <div
                v-if="Object.keys(otherAccounts).length > 0"
                class="py-1"
                role="none"
              >
                <ol>
                  <li v-for="acc in otherAccounts" :key="acc.id">
                    <a
                      class="flex flex-col px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                      role="menuitem"
                      data-test="account-name"
                      @click="changeAccount(acc.id)"
                    >
                      <span class="truncate">
                        {{ acc.display_name }}
                      </span>
                      <span class="text-gray-400 truncate">
                        {{ username }}
                      </span>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </transition>
        <div>
          <button
            id="options-menu-button"
            v-click-away="hideUserDropdown"
            type="button"
            data-test="account-switcher"
            class="group w-full bg-gray-700 px-3.5 py-4 text-sm text-left font-medium text-gray-700 hover:bg-gray-900 focus:outline-none focus:ring-0 focus:ring-offset-0"
            aria-expanded="false"
            aria-haspopup="true"
            :class="{ 'bg-gray-900': showUserDropdown }"
            @click="toggleShowUserDropdown"
          >
            <span class="flex items-center justify-between w-full">
              <span class="flex items-center justify-between min-w-0 space-x-3">
                <button
                  id="user-menu"
                  class="flex items-center max-w-xs text-sm text-white rounded-full focus:outline-none focus:shadow-solid"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  <span
                    class="w-8 h-8 overflow-hidden rounded-full bg-brand-blue"
                  >
                    <svg
                      class="w-full h-full text-gray-200"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                      ></path>
                    </svg>
                  </span>
                </button>
                <span class="flex flex-col flex-1 min-w-0 text-sm">
                  <span
                    class="text-gray-300 truncate"
                    data-test="current-account"
                  >
                    {{ account.display_name }}
                  </span>
                  <span class="text-gray-500 truncate" :title="username">
                    {{ username }}
                  </span>
                </span>
              </span>
              <span
                class="w-5 h-5 text-gray-400 shrink-0 group-hover:text-gray-500"
              >
                <app-icon-chevron-up-down />
              </span>
            </span>
          </button>
        </div>
      </div>
      <div v-else class="absolute bottom-0 w-full">
        <div
          class="bg-gray-700 border-solid divide-y divide-gray-200 shadow-xs focus:outline-none"
        >
          <a
            class="flex items-center px-4 py-4 text-sm font-medium leading-6 text-gray-100 cursor-pointer hover:text-white hover:bg-gray-900"
            role="menuitem"
            @click="signOut()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 mr-4 text-gray-200"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            Sign out
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import IconChevronUpDown from "@/components/icons/ChevronUpDown.vue";

export default {
  components: {
    "app-icon-chevron-up-down": IconChevronUpDown,
  },

  data() {
    return {
      showUserDropdown: false,
      mobileMenuOpen: false,
      links: [],
    };
  },

  computed: {
    username() {
      return this.$store.getters.username;
    },
    accountId() {
      return (
        this.$route.params.accountId && parseInt(this.$route.params.accountId)
      );
    },
    lastActiveAccountId() {
      return this.$store.getters.lastActiveAccountId;
    },
    accountIdParam() {
      return this.accountId || this.$store.getters.lastActiveAccountId;
    },
    otherAccounts() {
      const allAccounts = this.$store.getters.accountsList;
      const filterOutAccountId = this.accountId || this.lastActiveAccountId;
      return allAccounts.filter((acc) => acc.id !== filterOutAccountId);
    },
    account() {
      return this.$store.getters.account(
        this.accountId || this.lastActiveAccountId
      );
    },
  },

  methods: {
    toggleShowUserDropdown() {
      this.showUserDropdown = !this.showUserDropdown;
    },
    hideUserDropdown() {
      this.showUserDropdown = false;
    },
    signOut() {
      this.$store.dispatch("signOut");
    },
    isActiveLink(link) {
      const fullPath = this.$route.fullPath.replace(/^\/accounts\/\d+/i, "");
      return fullPath.split("/")[1]?.toLowerCase() === link.toLowerCase();
    },
    changeAccount(id) {
      this.$store.dispatch("changeAccount", id);
      this.showUserDropdown = false;
    },
  },
};
</script>
