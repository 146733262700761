import api from "@/api";
import { FILE_STATUS, NOTIFICATION_TYPE } from "@/utils/constants";
import errorDetailsToString from "@/utils/errorDetailsToString";
const { READY, FAILED, COMPLETE, AWAITING } = FILE_STATUS;
const opts = { useInterceptCancelToken: false, interceptError: false };

const state = () => ({
  files: {},
  uploading: {},
  log: [],
});

const getters = {
  files: (state) => (projectId) => {
    return Object.values(state.files).filter((f) => f.projectId === projectId);
  },
  filesByStatus: (state, getters) => (STATUS, projectId) => {
    return getters.files(projectId).filter((f) => f.status === STATUS);
  },
  fileByName: (state) => (projectId, fileName) => {
    return state.files[`${projectId}_${fileName}`];
  },
  rowData: (state, getters) => (projectId) => {
    return getters.files(projectId).map((f) => {
      return {
        ...f,
        document_name: f.file.name,
        actions: {
          enabled: [READY, AWAITING, FAILED].includes(f.status),
          name: f.file.name,
        },
        status: {
          message: f.status,
          tooltip: f.tooltip,
        },
      };
    });
  },
  readyDocCount: (state, getters) => (projectId) =>
    getters.filesByStatus(FILE_STATUS.READY, projectId).length,
  uploading: (state) => (projectId) => state.uploading[projectId],
  uploadCount: (state) => (projectId) =>
    state.log.filter((file) => {
      return file.projectId === projectId && file.status === COMPLETE;
    }).length,
  errorCount: (state) => (projectId) =>
    state.log.filter((file) => {
      return file.projectId === projectId && file.status === FAILED;
    }).length,
};

const mutations = {
  addFiles: (state, { files, projectId }) => {
    for (let file of files) {
      const addFile = { status: FILE_STATUS.READY, projectId, file };
      state.files[`${projectId}_${file.name}`] = addFile;
    }
  },
  removeFile: (state, { name, projectId }) => {
    delete state.files[`${projectId}_${name}`];
  },
  setFileStatus: (state, { file, status, tooltip, projectId }) => {
    const key = `${projectId}_${file.file.name}`;
    const newFile = { ...state.files[key], status, tooltip };
    state.files[key] = newFile;
    state.log = state.log.concat(newFile);
  },
  setFileStatusMany: (state, { projectId, status, files }) => {
    for (let file of files) {
      const key = `${projectId}_${file.file.name}`;
      const newFile = { ...state.files[key], status };
      state.files[key] = newFile;
      state.log = state.log.concat(newFile);
    }
  },
  setUploadStatus: (state, { projectId, uploading }) => {
    state.uploading[projectId] = uploading;
  },
  clearLog: (state, { projectId }) => {
    state.log = state.log.filter((file) => file.projectId !== projectId);
  },
};

const actions = {
  addFiles: ({ commit }, payload) => commit("addFiles", payload),
  removeFile: ({ commit }, payload) => commit("removeFile", payload),
  initiateUpload: async ({ dispatch, getters, commit }, projectId) => {
    dispatch("prepareForUpload", { projectId });
    if (getters.uploading(projectId)) return;

    commit("setUploadStatus", { projectId, uploading: true });
    while (getters.filesByStatus(AWAITING, projectId).length) {
      const file = getters.filesByStatus(AWAITING, projectId)[0];
      await dispatch("uploadFile", { file, projectId });
    }
    dispatch("summaryNotification", { projectId });
    dispatch("refreshProjectDocuments", { projectId }, { root: true });
    commit("setUploadStatus", { projectId, uploading: false });
  },
  uploadFile: async (
    { dispatch, commit, rootGetters },
    { file, pairedFile, projectId }
  ) => {
    commit("setFileStatus", { file, status: FILE_STATUS.UPLOADING, projectId });

    if (file.file.size > rootGetters.projectConfig.upload.fileSizeLimit) {
      return dispatch("exceedsFileSize", { file, projectId });
    }

    try {
      const data = new FormData();
      data.append("document", file.file);
      data.append("generate_images", true);
      if (pairedFile) data.append("content", pairedFile.file);

      const res = await api(opts).post(`projects/${projectId}/contents`, data, {
        onUploadProgress: (event) =>
          dispatch("setUploadProgress", { event, file, projectId }),
      });
      const fileId = res?.data?.id;
      if (fileId) {
        commit("setFileStatus", {
          file,
          status: FILE_STATUS.COMPLETE,
          projectId,
        });
        dispatch("removeFileTimeout", { file, pairedFile, projectId });
        return fileId;
      }
      commit("setFileStatus", { file, status: FILE_STATUS.FAILED, projectId });
    } catch (err) {
      dispatch(
        "setNotification",
        {
          name: "Error",
          message: err, //`${file.file.name} failed to upload. Try again`,
          type: NOTIFICATION_TYPE.ERROR,
        },
        { root: true }
      );
      const errorMessage = errorDetailsToString(err?.response);
      commit("setFileStatus", {
        file,
        status: FILE_STATUS.FAILED,
        tooltip: errorMessage,
        projectId,
      });
    }
  },
  setUploadProgress: ({ commit }, { event, file, projectId }) => {
    const percent = parseInt(Math.round((event.loaded / event.total) * 100));

    if (percent !== 100)
      commit("setFileStatus", { file, status: percent, projectId });
    else
      commit("setFileStatus", {
        file,
        status: FILE_STATUS.STORING,
        projectId,
      });
  },
  removeFileTimeout: ({ dispatch }, { file, pairedFile, projectId }) => {
    setTimeout(() => {
      dispatch("removeFile", { name: file.file.name, projectId });
      if (pairedFile)
        dispatch("removeFile", { name: pairedFile.file.name, projectId });
    }, 1000);
  },
  exceedsFileSize: (context, { file, projectId }) => {
    const { dispatch, commit, rootGetters } = context;
    const LIMIT_MB = rootGetters.projectConfig.upload.fileSizeLimit / 1e6;
    dispatch(
      "setNotification",
      {
        name: "File Too Large",
        message: `${file.file.name} exceeds the file size limit of ${LIMIT_MB} megabytes`,
        type: NOTIFICATION_TYPE.ERROR,
      },
      { root: true }
    );
    commit("setFileStatus", {
      file,
      status: FILE_STATUS.FAILED,
      tooltip: `This file exceeds the size limit of ${LIMIT_MB} megabytes`,
      projectId,
    });
  },
  summaryNotification: ({ dispatch, commit, getters }, { projectId }) => {
    const uploadCount = getters.uploadCount(projectId);
    const errorCount = getters.errorCount(projectId);
    const s = uploadCount > 1 ? "s" : "";

    if (uploadCount && !errorCount) {
      dispatch(
        "setNotification",
        {
          name: "Uploads Complete",
          message: `${uploadCount} file${s} successfully uploaded.`,
          type: NOTIFICATION_TYPE.SUCCESS,
        },
        { root: true }
      );
    } else if (uploadCount && errorCount) {
      const errorS = errorCount > 1 ? "s" : "";
      dispatch(
        "setNotification",
        {
          name: "Uploads Complete",
          message: `${uploadCount} file${s} successfully uploaded. ${errorCount} file${errorS} failed to upload.`,
          type: NOTIFICATION_TYPE.WARNING,
        },
        { root: true }
      );
    } else if (!uploadCount && errorCount) {
      dispatch(
        "setNotification",
        {
          name: "Uploads Complete",
          message: `0 files successfully uploaded. ${errorCount} file${s} failed to upload.`,
          type: NOTIFICATION_TYPE.ERROR,
        },
        { root: true }
      );
    }

    commit("clearLog", { projectId });
  },
  prepareForUpload: ({ commit, getters }, { projectId }) => {
    getters.filesByStatus(READY, projectId).forEach((file) => {
      const fileIsAvailable = file.file.size > 0;
      commit("setFileStatus", {
        file,
        status: fileIsAvailable ? AWAITING : FAILED,
        tooltip: fileIsAvailable
          ? undefined
          : "File is no longer available to upload.",
        projectId,
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
