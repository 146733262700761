// state
const state = {
  rangeSliderChanging: false,
};

// mutations
const mutations = {
  setRangeSliderChanging(state, payload) {
    state.rangeSliderChanging = payload;
  },
};

// actions
const actions = {
  setRangeSliderChanging(context, payload) {
    context.commit("setRangeSliderChanging", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
