import router from "@/router/index";
import api from "@/api";
import {
  SETTINGS_TABS,
  DEFAULT_IDLE_TIMEOUT_IN_MILLISECONDS,
} from "@/utils/constants";

const state = {
  accounts: {},
  accountsLoading: false,
  defaultConfig: {
    accessKeyLimit: 5,
    settingsTabs: [{ ...SETTINGS_TABS.ACCESS_KEYS, disabled: false }],
    idleTimeout: {
      enabled: true,
      timeoutInMilliseconds: DEFAULT_IDLE_TIMEOUT_IN_MILLISECONDS,
    },
    projects: {
      columnDefinitions: [
        "display_name",
        "description",
        "reviewed_documents",
        "unreviewed_documents",
        "total_documents",
        "updated_at",
        "project_actions",
      ],
    },
  },
  lastActiveAccountId: 0,
};

const getters = {
  account: (state) => (accountId) => {
    return state.accounts[accountId];
  },
  currentAccount: (state) => {
    const id = router.currentRoute.value.params.accountId;
    return state.accounts[id];
  },
  accounts(state) {
    return state.accounts;
  },
  accountsList(state) {
    return Object.values(state.accounts).sort((a, b) => a.id - b.id);
  },
  accountConfig: (state, getters) => (accountId) => {
    return { ...state.defaultConfig, ...getters.account(accountId)?.config };
  },
  disabledSettingsTabs:
    (state, getters, rootState, rootGetters) => (accountId) =>
      getters.accountConfig(accountId).settingsTabs.filter((tab) => {
        if (tab.name === SETTINGS_TABS.ACCESS_KEYS.name) {
          return (
            tab.disabled ||
            !rootGetters["permissions/canViewApiKeys"](accountId)
          );
        } else {
          return tab.disabled;
        }
      }),
  lastActiveAccountId(state) {
    return state.lastActiveAccountId;
  },
  accountsLoading(state) {
    return state.accountsLoading;
  },
};

const mutations = {
  setAccounts(state, payload) {
    state.accounts = payload;
  },
  setLastActiveAccountId(state, { accountId }) {
    state.lastActiveAccountId = accountId;
  },
  setAccountsLoading(state, payload) {
    state.accountsLoading = payload;
  },
};

const actions = {
  async fetchAccounts(context) {
    context.commit("setAccountsLoading", true);
    try {
      const response = await api({
        useInterceptCancelToken: false,
      }).get("accounts");
      let accounts = {};
      response.data.accounts.forEach((account) => {
        accounts[account.id] = account;
      });
      context.commit("setAccounts", accounts);
      context.dispatch("permissions/setRPTToken", response.data.meta.rptToken, {
        root: true,
      });
      if (!response.data.accounts.length) {
        context.commit("setLastActiveAccountId", { accountId: 0 });
        context.commit("setAccountsLoading", false);
        return router.replace({ name: "No Account" });
      }
      const currentAccountId = router.currentRoute.value.params.accountId;
      if (currentAccountId && !context.getters.account(currentAccountId)) {
        context.commit("setLastActiveAccountId", { accountId: 0 });
        router.replace({ name: "Account Restricted Access" });
      }
    } catch (err) {
      console.error(err);
    } finally {
      context.commit("setAccountsLoading", false);
    }
  },
  async changeAccount(context, id) {
    const accounts = context.getters.accounts;
    localStorage.removeItem("vuex");
    context.commit("setAccounts", accounts);
    router.push({ name: "Projects", params: { accountId: id } }).then(() => {
      router.go(0);
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
