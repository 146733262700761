import {
  AuthGuard,
  DisabledRouteGuard,
  DisabledSettingsGuard,
  IsAccountAdminOrManagerGuard,
  IsAdminGuard,
  RequireProjectConfig,
} from "@/router/guards";

import { PROJECT_TABS } from "@/utils/constants";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { size } from "lodash-es";
import { datadogRum } from "@datadog/browser-rum";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/no-account",
    name: "No Account",
    beforeEnter: () => {
      return size(store.state.account.accounts) > 0
        ? {
            name: "Projects",
            params: { accountId: store.state.account.lastActiveAccountId },
          }
        : true;
    },
    component: () => import("../views/NoAccount.vue"),
  },
  {
    path: "/account-restricted-access",
    name: "Account Restricted Access",
    component: () => import("../views/AccountRestrictedAccess.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import("../views/Help.vue"),
  },
  {
    path: "/accounts/:accountId",
    name: "Account",
    component: () => import("../views/Account.vue"),
    children: [
      {
        path: "projects",
        name: "Projects",
        alias: "",
        component: () => import("../views/Projects.vue"),
      },
      {
        path: "projects/:projectId",
        beforeEnter: RequireProjectConfig,
        component: () => import("../views/project/Project.vue"),
        children: [
          {
            path: "",
            name: PROJECT_TABS.DOCUMENTS.route,
            beforeEnter: DisabledRouteGuard,
            component: () => import("../views/project/Documents.vue"),
          },
          {
            path: "documents/:contentId",
            name: "Document",
            component: () => import("../views/Document.vue"),
          },
          {
            path: "upload",
            name: PROJECT_TABS.UPLOAD.route,
            beforeEnter: DisabledRouteGuard,
            component: () => import("../views/project/Upload.vue"),
          },
          {
            path: "exports",
            name: PROJECT_TABS.EXPORTS.route,
            beforeEnter: DisabledRouteGuard,
            component: () => import("../views/project/BulkExportsHistory.vue"),
          },
          {
            path: "reports",
            name: PROJECT_TABS.REPORTS.route,
            beforeEnter: DisabledRouteGuard,
            component: () => import("../views/project/Reports.vue"),
          },
          {
            path: "docs",
            name: PROJECT_TABS.API_DOCS.route,
            beforeEnter: DisabledRouteGuard,
            component: () => import("../views/project/APIDocs.vue"),
          },
          {
            path: "label-wizard",
            name: PROJECT_TABS.LABEL_WIZARD.route,
            beforeEnter: DisabledRouteGuard,
            component: () => import("../views/project/LabelWizard.vue"),
          },
          {
            path: "settings",
            name: PROJECT_TABS.SETTINGS.route,
            beforeEnter: [IsAccountAdminOrManagerGuard, DisabledRouteGuard],
            component: () => import("../views/project/Settings.vue"),
          },
          {
            path: "disabled",
            name: "Disabled Feature",
            component: () => import("../views/project/DisabledFeature.vue"),
          },
        ],
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("../views/Reports.vue"),
      },
      {
        path: "settings",
        beforeEnter: DisabledSettingsGuard,
        name: "Settings",
        redirect: { name: "Password" },
        component: () => import("../views/settings/Settings.vue"),
        children: [
          {
            path: "password",
            name: "Password",
            beforeEnter: DisabledSettingsGuard,
            component: () => import("../views/settings/Password.vue"),
          },
          {
            path: "access-keys",
            name: "Access Keys",
            beforeEnter: DisabledSettingsGuard,
            component: () => import("../views/settings/AccessKeys.vue"),
          },
          {
            path: "storage-profiles",
            name: "Storage Profiles",
            beforeEnter: [IsAdminGuard, DisabledRouteGuard],
            component: () => import("../views/settings/StorageProfiles.vue"),
          },
          {
            path: "disabled-settings-feature",
            name: "Disabled Settings Feature",
            component: () =>
              import("../views/settings/DisabledSettingsFeature.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(() => {
  store.dispatch("cancelPendingRequests");
});

router.beforeEach(AuthGuard);

router.beforeEach((to) => {
  datadogRum.startView({ name: to.path });
});

router.afterEach((to) => {
  const { accountId } = to.params;
  if (accountId && Number(accountId) != store.getters.lastActiveAccountId) {
    store.commit("setLastActiveAccountId", { accountId: Number(accountId) });
  }
});

export default router;
