<template>
  <div>
    <app-notifications v-if="hasNotifications" />
    <div v-if="isSignedIn && $route.name">
      <template v-if="isDocumentMode">
        <app-error-display v-if="displayError" />
        <router-view v-else />
      </template>
      <app-main v-else>
        <app-error-display v-if="displayError" />
        <router-view v-else />
      </app-main>
    </div>
    <div v-else>
      <app-error-display v-if="displayError" />
      <router-view v-else />
    </div>
    <app-auto-logout :init="initAutoLogout" />
  </div>
</template>

<script>
import { startDatadog } from "@/datadog.js";
import AppNotifications from "@/components/layout/Notifications.vue";
import AppMain from "@/components/layout/nav/Main.vue";
import AppErrorDisplay from "@/components/layout/ErrorDisplay.vue";
import AppAutoLogout from "@/components/idle/AutoLogout.vue";

export default {
  components: {
    AppNotifications,
    AppMain,
    AppErrorDisplay,
    AppAutoLogout,
  },

  data() {
    return {
      dataDogStarted: false,
      routeComplete: false,
      accountComplete: false,
      initAutoLogout: false,
    };
  },

  computed: {
    isSignedIn() {
      return this.$store.getters.isSignedIn;
    },
    hasNotifications() {
      return this.$store.getters.notifications.length > 0;
    },
    displayError() {
      return this.$store.getters.displayError !== "";
    },
    windowWidth: {
      get() {
        return this.$store.getters.windowWidth;
      },
      set(windowWidth) {
        this.$store.dispatch("setWindowWidth", windowWidth);
      },
    },
    windowHeight: {
      get() {
        return this.$store.getters.windowHeight;
      },
      set(windowHeight) {
        this.$store.dispatch("setWindowHeight", windowHeight);
      },
    },
    accountsLoading() {
      return this.$store.state.account.accountsLoading;
    },
    routeParams() {
      return this.$route.params;
    },
    routePath() {
      return this.$route.path;
    },
    isDocumentMode() {
      return this.$route.name === "Document";
    },
    setupReady() {
      return this.routeComplete && this.accountComplete;
    },
  },

  watch: {
    routeParams() {
      if (this.routeParams.accountId) this.routeComplete = true;
    },
    accountsLoading() {
      if (!this.accountsLoading) this.accountComplete = true;
    },
    setupReady() {
      if (this.setupReady) {
        this.initAutoLogout = true;
        if (!this.dataDogStarted) this.setupDatadog();
      }
    },
    routePath() {
      if (this.displayError) {
        this.$store.dispatch("setDisplayError", "");
      }
    },
    $keycloak: {
      handler(newVal) {
        this.$store.dispatch("setKeycloak", newVal.keycloak);
      },
      deep: true,
    },
  },

  async created() {
    this.dataDogStarted = false;
    this.$store.dispatch("clearNotifications");
    this.$store.dispatch("getTabId");
    if (this.isSignedIn) {
      this.$store.dispatch("fetchAccounts");
      this.$store.dispatch("connectWebsocket");
    }
  },

  mounted() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("beforeunload", this.storeTabId);
    this.$store.dispatch("fetchAppConfig");
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("beforeunload", this.storeTabId);
  },

  methods: {
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.windowHeight = event.currentTarget.innerHeight;
    },
    storeTabId() {
      this.$store.dispatch("storeTabId");
    },
    setupDatadog() {
      if (
        this.$store.getters.accountConfig(this.$route.params.accountId).datadog
          ?.enabled
      ) {
        const sessionReplaySampleRate = this.$store.getters.accountConfig(
          this.$route.params.accountId
        ).datadog.sessionReplaySampleRate;
        this.dataDogStarted = true;
        startDatadog({
          userId: this.$store.getters.userId,
          email: this.$store.getters.email,
          sessionReplaySampleRate,
        });
      }
    },
  },
};
</script>
