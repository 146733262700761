import { datadogRum } from "@datadog/browser-rum";

export function startDatadog({ userId, email, sessionReplaySampleRate }) {
  const env = import.meta.env.VITE_ENV || "local";
  console.log("Starting RUM", env, sessionReplaySampleRate);
  datadogRum.init({
    applicationId: "2d2281c9-c96b-484f-8129-8fe478453252",
    clientToken: "pub29398e281d4e98bd29e8615941b39cde",
    site: "datadoghq.com",
    service: "dociq",
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "0.1.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate,
    trackViewsManually: true,
    trackUserInteractions: true,
    trackResources: false,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
    enablePrivacyForActionName: true,
    beforeSend: (event) => {
      // Mask the click event value
      if (event.action?.type === "click") {
        event.action.target.name = "[redacted]";
      }
    },
  });

  datadogRum.setUser({
    id: userId,
    email: email,
  });
}
