import api, { skipResolvedError } from "@/api";
import { nextTick } from "vue";
import { scrollToPage } from "@/utils/infoView";
import { cloneDeep } from "lodash-es";
import {
  ENTITY,
  DOCUMENT,
  PAGE,
  IMAGE,
  TABLE,
  NOTIFICATION_TYPE,
  BUTTON,
} from "@/utils/constants";
import {
  getBlocks,
  dedupeBlocks,
  getLabels,
  buildPath,
  getInnerNode,
  findNodeByLabel,
  filterTree,
  validateTree,
  revalidateTree,
  buildTreeIndexAndPath,
  buildTreeIndexAndPathForAssociation,
  getIdxToDelete,
  getInputIndex,
  optimisticRemoveLabelFromBlock,
  optimisticAddLabel,
  optimisticUpdateBlock,
  findNodeByIndex,
} from "@/utils/infoView";

// state
const state = {
  activeLabel: null,
  activeBlock: null,
  fullTreeLoading: true,
  showHideSections: [],
  fullTree: [],
  searchTerm: "",
  labelFilter: [],
  controlKeyDown: false,
  shiftKeyDown: false,
  multiWarningModalInfo: {},
  showPageVerificationModal: {},
  undoAction: {},
  drawerOpen: true,
};

// getters
const getters = {
  activeLabelValidBlockType(state) {
    if (state.activeLabel?.valid_block_types) {
      return state.activeLabel.valid_block_types[0];
    }
    return null;
  },
  blocks(state) {
    const blocks = getBlocks(state.fullTree);
    const clonedBlocks = cloneDeep(blocks);
    const dedupedBlocks = dedupeBlocks(clonedBlocks);
    return dedupedBlocks;
  },
  labels(state) {
    return getLabels(state.fullTree);
  },
  searchTerm(state) {
    return state.searchTerm.toLowerCase();
  },
  fullTree(state) {
    const clonedTree = cloneDeep(state.fullTree);
    const validatedTree = validateTree(clonedTree);
    return revalidateTree(validatedTree);
  },
  filteredTree(_s, getters) {
    const clonedTree = cloneDeep(getters.fullTree);
    return filterTree(clonedTree, getters.searchTerm);
  },
  isDocument(_s, getters) {
    return getters.activeLabelValidBlockType === DOCUMENT;
  },
  isPage(_s, getters) {
    return getters.activeLabelValidBlockType === PAGE;
  },
  isImage(_s, getters) {
    return getters.activeLabelValidBlockType === IMAGE;
  },
  isTable(_s, getters) {
    return getters.activeLabelValidBlockType === TABLE;
  },
  isImageOrTable(_s, getters) {
    return getters.isImage || getters.isTable;
  },
};

// mutations
const mutations = {
  setActiveLabel(state, payload) {
    state.activeLabel = payload;
  },
  setActiveBlock(state, payload) {
    state.activeBlock = payload;
  },
  setFullTreeLoading(state, payload) {
    state.fullTreeLoading = payload;
  },
  setFullTree(state, payload) {
    state.fullTree = payload;
  },
  setShowHideSections(state, payload) {
    const idx = state.showHideSections.indexOf(payload);
    idx === -1
      ? state.showHideSections.push(payload)
      : state.showHideSections.splice(idx, 1);
  },
  resetShowHideSections(state) {
    state.showHideSections = [];
  },
  setSearchTerm(state, payload) {
    state.searchTerm = payload;
  },
  setLabelFilter(state, payload) {
    state.labelFilter = payload;
  },
  resetLabelFilter(state) {
    state.labelFilter = [];
  },
  setControlKeyDown(state, payload) {
    state.controlKeyDown = payload;
  },
  setShiftKeyDown(state, payload) {
    state.shiftKeyDown = payload;
  },
  addRemoveLabelToClassification(state, payload) {
    const path = buildPath(payload.index, state.fullTree);
    let innerNode = getInnerNode(state.fullTree, path);
    let classificationLabel = innerNode.children.find(
      (child) => child.id === payload.labelToAdd.id
    );
    if (classificationLabel._showLabel) {
      delete classificationLabel._showLabel;
      state.activeLabel = null;
    } else {
      classificationLabel._showLabel = true;
      state.activeLabel = payload.labelToAdd;
    }
  },
  setMultiWarningModalInfo(state, payload) {
    state.multiWarningModalInfo = payload;
  },
  updateSubTree(state, payload) {
    state.fullTree[parseInt(payload.treeIndex)] = payload.data;
  },
  setShowPageVerificationModal(state, payload) {
    state.showPageVerificationModal = payload;
  },
  setUndoAction(state, payload) {
    state.undoAction = payload;
  },
  toggleDrawer(state) {
    state.drawerOpen = !state.drawerOpen;
  },
};

// actions
const actions = {
  setActiveLabel(context, payload) {
    context.commit("setActiveLabel", payload);
  },
  setActiveBlock(context, payload) {
    context.commit("setActiveBlock", payload);
  },
  fetchFullTree(context, payload) {
    context.commit("setFullTreeLoading", true);
    api()
      .get(
        `projects/${payload.projectId}/contents/${payload.contentId}/block-tree`
      )
      .then((response) => {
        context.commit("setFullTree", response.data);
        context.commit("setFullTreeLoading", false);
      }, skipResolvedError);
  },
  removeLabelFromBlock(context, payload) {
    const pathForNode = buildPath(payload.index, context.state.fullTree);
    let innerNode = getInnerNode(context.state.fullTree, pathForNode);
    // need to get the innerNode from the getter as the getter has additional
    // metatdata about the node such as _classification
    let innerNodeFromGetters = getInnerNode(
      context.getters.fullTree,
      pathForNode
    );
    const innerNodeClone = cloneDeep(innerNode);
    optimisticRemoveLabelFromBlock(payload.blocks, innerNode);
    // if the label being removed is the last item and its a classification label
    // then set the active label to null as the classification label is about to
    // be hidden.
    if (
      innerNodeFromGetters.children.length === 1 &&
      !!getInnerNode(context.getters.fullTree, pathForNode)._classification
    ) {
      context.commit("setActiveLabel", null);
    }
    context.dispatch("removeLabel", {
      innerNode: innerNodeClone,
      index: payload.index,
      blocks: payload.blocks,
      contentId: payload.contentId,
      projectId: payload.projectId,
    });
  },
  async swapLabels(context, payload) {
    let innerNode = findNodeByLabel(
      context.state.fullTree,
      payload.currentLabel.id
    );
    await context.dispatch("removeLabel", {
      innerNode,
      blocks: payload.blocks,
      index: payload.index,
      contentId: payload.contentId,
      projectId: payload.projectId,
    });
    context.dispatch("addLabelToBlock", {
      contentId: payload.contentId,
      projectId: payload.projectId,
      blocks: payload.blocks,
      label: payload.newLabel,
    });
  },
  async removeLabel(
    context,
    { blocks, innerNode, index, projectId, contentId }
  ) {
    let idxToDelete = getIdxToDelete(blocks, innerNode);
    context.commit("setActiveBlock", null);
    const { treeIndex, path } = buildTreeIndexAndPath(
      index,
      context.state.fullTree,
      idxToDelete
    );
    await api({ interceptError: false })
      .delete(
        `projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}`
      )
      .then(({ data }) => {
        context.commit("updateSubTree", { treeIndex, data });
      })
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not remove label.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
        context.dispatch("fetchFullTree", {
          contentId,
          projectId,
        });
      });
  },
  createLabeledImageOrTableBlock(context, payload) {
    if (!payload.bounding_box) return;
    if (
      payload.bounding_box.x_max - payload.bounding_box.x_min < 5 ||
      payload.bounding_box.y_max - payload.bounding_box.y_min < 5
    ) {
      return;
    }
    context.dispatch("createLabeledBlock", {
      bounding_box: payload.bounding_box,
      text: "",
      children: [],
      page: payload.page,
      contentId: payload.contentId,
      projectId: payload.projectId,
    });
  },
  createLabeledEntityBlock(context, payload) {
    let words =
      context.rootState.documentHighlighting.wordsToBeLabeled[payload.page];
    if (words === undefined || words.length === 0) return;
    let bounding_box = {
      x_max: Math.max(...words.map((w) => w.bounding_box.x_max)),
      x_min: Math.min(...words.map((w) => w.bounding_box.x_min)),
      y_max: Math.max(...words.map((w) => w.bounding_box.y_max)),
      y_min: Math.min(...words.map((w) => w.bounding_box.y_min)),
    };
    let text = words.map((word) => word.text).join(" ");
    let children = words.map((word) => word.uuid);
    context.dispatch("createLabeledBlock", {
      bounding_box,
      text,
      children,
      page: payload.page,
      contentId: payload.contentId,
      projectId: payload.projectId,
    });
  },
  createLabeledBlock(context, payload) {
    let labels = [
      { label: context.state.activeLabel.id, confidence: 1, is_verified: true },
    ];
    const uuid = self.crypto.randomUUID();
    const block = {
      block_type: context.getters.activeLabelValidBlockType,
      bounding_box: payload.bounding_box,
      text: payload.text,
      labels,
      confidence: 1,
      page_number: payload.page,
      formatted_value: payload.text,
      is_verified: true,
      children: payload.children,
      uuid,
    };
    context.commit("setActiveBlock", block);
    context.dispatch(
      "documentHighlighting/resetWordsToBeLabeled",
      {},
      {
        root: true,
      }
    );
    context.dispatch("addLabel", {
      isNew: true,
      projectId: payload.projectId,
      contentId: payload.contentId,
      blocks: [block],
    });
  },
  addLabelToBlock(context, { blocks, label, contentId, projectId }) {
    blocks.forEach((block) => {
      context.commit("setActiveBlock", block);
      const labels = [
        { confidence: label.confidence, label: label.id, is_verified: true },
      ];
      block.labels = labels;
      const activePage = context.rootState.documentData.activePage;
      if (block.block_type === TABLE) {
        context.rootState.documentData.tableBlocks[activePage].find(
          (b) => b.uuid === block.uuid
        ).labels = labels;
      }
      if (block.block_type === IMAGE) {
        context.rootState.documentData.imageBlocks[activePage].find(
          (b) => b.uuid === block.uuid
        ).labels = labels;
      }
    });
    context.dispatch("addLabel", {
      isNew: false,
      projectId,
      contentId,
      blocks,
    });
  },
  addLabel(context, { isNew, projectId, contentId, blocks }) {
    const inputIndex = getInputIndex(
      context.state.activeLabel._index,
      context.state.fullTree,
      blocks[0]
    );
    const { treeIndex, path } = buildTreeIndexAndPath(
      context.state.activeLabel._index,
      context.state.fullTree,
      inputIndex
    );
    optimisticAddLabel(
      context.state.fullTree,
      treeIndex,
      path,
      blocks,
      inputIndex
    );
    // do not allow a section to be collapsed when adding labels
    if (
      context.state.showHideSections.includes(context.state.activeLabel._index)
    ) {
      context.commit("setShowHideSections", context.state.activeLabel._index);
    }
    api({ interceptError: false })
      .post(
        `projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}&is_new=${isNew}`,
        blocks
      )
      .then(({ data }) => {
        const undoBlocks =
          context.getters.activeLabelValidBlockType === PAGE
            ? blocks
            : [getInnerNode(data, path)];
        context.commit("updateSubTree", { treeIndex, data });
        context.commit("setActiveBlock", getInnerNode(data, path));
        if (blocks[0].page_number) {
          context.dispatch(
            "documentData/fetchBlocks",
            {
              contentId,
              projectId,
              page: blocks[0].page_number,
            },
            { root: true }
          );
        }
        context.dispatch("setUndoAction", {
          action: "removeLabelFromBlock",
          payload: {
            index: context.state.activeLabel._index,
            blocks: undoBlocks,
            contentId,
            projectId,
          },
        });
      })
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not add label.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
        context.dispatch("fetchFullTree", {
          contentId,
          projectId,
        });
      });
  },
  setShowHideSections(context, payload) {
    context.commit("setShowHideSections", payload);
  },
  resetShowHideSections(context) {
    context.commit("resetShowHideSections");
  },
  updateBlock(context, { projectId, contentId, index, blocks, inputIndex }) {
    const { treeIndex, path } = buildTreeIndexAndPath(
      index,
      context.state.fullTree,
      inputIndex
    );
    let apiPayload = [];
    blocks.forEach((block) => {
      let b = {
        block_type: block.block_type,
        page_number: block.page_number,
        labels: block.labels,
        uuid: block.uuid,
        bounding_box: block.bounding_box,
      };
      if (block.block_type === ENTITY) {
        b["formatted_value"] = block.formatted_value ?? block.text;
      }
      apiPayload.push(b);
    });
    optimisticUpdateBlock(blocks, context.state.fullTree);
    api({ interceptError: false })
      .put(
        `projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}`,
        apiPayload
      )
      .then(({ data }) => {
        context.commit("updateSubTree", { treeIndex, data });
      })
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not update block.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
        context.dispatch("fetchFullTree", {
          contentId,
          projectId,
        });
      });
  },
  setSearchTerm(context, payload) {
    context.dispatch("resetShowHideSections");
    context.commit("setSearchTerm", payload);
  },
  setLabelFilter(context, payload) {
    context.commit("setLabelFilter", payload);
  },
  resetLabelFilter(context) {
    context.commit("resetLabelFilter");
  },
  setControlKeyDown(context, payload) {
    context.commit("setControlKeyDown", payload);
  },
  setShiftKeyDown(context, payload) {
    context.commit("setShiftKeyDown", payload);
  },
  createGroup(context, { projectId, contentId, node }) {
    const inputIndex = node.children.length;
    const { treeIndex, path } = buildTreeIndexAndPath(
      node._index,
      context.state.fullTree,
      inputIndex
    );
    const payload = [
      { block_type: "GROUP", uuid: self.crypto.randomUUID(), block_uuids: [] },
    ];
    api()
      .post(
        `projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}`,
        payload
      )
      .then(({ data }) => {
        context.commit("updateSubTree", { treeIndex, data });
      });
  },
  deleteGroup(context, payload) {
    const idxArr = payload.index.split("-");
    const i = idxArr[idxArr.length - 1];
    const inputIndex = `${i}:${parseInt(i) + 1}`;
    let { treeIndex, path } = buildTreeIndexAndPathForAssociation(
      payload.index,
      context.state.fullTree,
      inputIndex
    );
    api()
      .delete(
        `projects/${payload.projectId}/contents/${payload.contentId}/block-tree/${treeIndex}?path=${path}`
      )
      .then(({ data }) => {
        context.commit("updateSubTree", { treeIndex, data });
      });
  },
  addRemoveLabelToClassification(context, payload) {
    context.commit("addRemoveLabelToClassification", payload);
  },
  setMultiWarningModalInfo(context, payload) {
    context.commit("setMultiWarningModalInfo", payload);
  },
  setShowPageVerificationModal(context, payload) {
    context.commit("setShowPageVerificationModal", payload);
  },
  setUndoAction(context, payload) {
    context.commit("setUndoAction", payload);
    context.dispatch(
      "setNotification",
      {
        clearNotifications: true,
        name: "Success",
        message: "Label added to item.",
        action: {
          type: BUTTON,
          message: "Undo",
          action: "infoView/undo",
        },
        type: NOTIFICATION_TYPE.SUCCESS,
      },
      { root: true }
    );
  },
  undo(context) {
    context.dispatch("clearNotifications", {}, { root: true });
    if (context.state.activeLabel._classification) {
      const innerNode = findNodeByIndex(
        context.getters.fullTree,
        context.state.activeLabel._index
      );
      if (innerNode.children.length === 1) {
        context.dispatch("setActiveLabel", null);
      }
    }
    context.dispatch(
      context.state.undoAction.action,
      context.state.undoAction.payload
    );
    context.commit("setUndoAction", {});
  },
  toggleDrawer(context) {
    const activePage = cloneDeep(context.rootState.documentData.activePage);
    nextTick(() => {
      context.dispatch("documentData/setActivePage", activePage, {
        root: true,
      });
      nextTick(() => {
        scrollToPage(activePage);
      });
    });
    context.commit("toggleDrawer");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
