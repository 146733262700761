/**
 * Parse status message to readable tooltip
 * @param status_message - could be sting or serialized JSON
 */
export default function parseStatusMessage(status_message) {
  try {
    // expecting this to be an iu serialized error
    const status_object = JSON.parse(status_message);
    const detail = status_object?.error?.detail;
    if (detail) {
      return detail;
    } else {
      return status_message;
    }
  } catch (e) {
    return status_message;
  }
}
