import { AVATAR_COLORS } from "@/utils/constants";

/**
 * @typedef User
 * @property {string} email
 * @property {string} avatarColor
 */

const sortByEmail = (user1, user2) =>
  user1.email < user2.email ? -1 : user1.email > user2.email ? 1 : 0;

const state = {
  /**
   * @type {ContentRoom}
   */
  contentRoomUsers: [],
};

const getters = {
  contentRoomUsers(state, g, r, rootGetters) {
    return state.contentRoomUsers.filter(
      // filter out current user from users in content room
      (user) => user.email !== rootGetters.email
    );
  },
};

const mutations = {
  setContentRoomUsers(state, users) {
    users.forEach((user) => {
      // get user initials from email
      user.initials = user.email.charAt(0).toUpperCase();
      user.avatarColor =
        AVATAR_COLORS[user.email.length % AVATAR_COLORS.length];
    });
    users.sort(sortByEmail);
    state.contentRoomUsers = users;
  },
};

const actions = {
  setContentRoom({ commit }, contentRoom) {
    commit("setContentRoomUsers", contentRoom.users);
  },
  resetContentRoom({ commit }) {
    commit("setContentRoomUsers", []);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
