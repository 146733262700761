import api from "@/api";
import { GROUP, WIZARDMODULES, NOTIFICATION_TYPE } from "@/utils/constants";

const TEMP_TAG = {
  _uuid: null,
  _module_type: null,
  _required: false,
  label: null,
  name: null,
  valid_block_types: [],
  description: null,
  model_name: null,
  private: null,
  datatype: null,
  min_blocks: 0,
  max_blocks: null,
};

const TEMP_GROUP = {
  _uuid: null,
  _module_type: null,
  _required: false,
  group: null,
  name: null,
  labels: [],
  valid_block_types: [],
  allow_multiple: false,
  private: null,
  min_blocks: 0,
  max_blocks: null,
};

const LABEL_WIZARD_VALIDATION_ERRORS = {
  name: [],
  classificationLabelName: [],
  blockTypes: [],
  groupLabels: [],
};

// recursive function
const findTreeByLabel = (label, list) => {
  for (let obj of list) {
    if (obj.label === label) return obj;
    let x = findTreeByLabel(label, obj.children || []);
    if (x) return x;
  }
};

// recursive function
const sanitizeSchema = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(sanitizeSchema);
  }
  const result = {};
  for (const key in obj) {
    if (!key.startsWith("_")) {
      result[key] = sanitizeSchema(obj[key]);
    }
  }
  return result;
};

const setUUIDs = (schema) => {
  // add back _uuid to label_tags
  schema.label_tags.map(
    (labelTag) => (labelTag._uuid = self.crypto.randomUUID())
  );
  // add back _uuid to label_groups and labels within the label group
  schema.label_groups.map((labelGroup) => {
    labelGroup.labels.map((label) => (label._uuid = self.crypto.randomUUID()));
    labelGroup._uuid = self.crypto.randomUUID();
  });
  return schema;
};

const setModuleType = (schema) => {
  // add back _module_type to label_tags
  schema.label_tags.map((labelTag) => {
    if (labelTag.valid_block_types.length === 0) {
      // set headers
      labelTag._module_type = WIZARDMODULES.HEADER;
    } else if (labelTag.valid_block_types.includes(GROUP)) {
      // set tags
      labelTag._module_type = WIZARDMODULES.ASSOCIATION;
    } else {
      labelTag._module_type = WIZARDMODULES.TAG;
    }
  });
  // add back _module_type to label_groups
  schema.label_groups.map((labelGroup) => {
    labelGroup._module_type = WIZARDMODULES.CLASSIFICATION;
  });
  return schema;
};

const setRequired = (schema) => {
  // add back _required to label_tags
  schema.label_tags.map((labelTag) => {
    if (labelTag.min_blocks === 0 && labelTag.max_blocks === null) {
      labelTag._required = false;
    } else {
      labelTag._required = true;
    }
  });
  // add back _required to label_groups
  schema.label_groups.map((labelGroup) => {
    if (labelGroup.min_blocks === 0 && labelGroup.max_blocks === null) {
      labelGroup._required = false;
    } else {
      labelGroup._required = true;
    }
  });
  return schema;
};

// state
const state = {
  labelWizardLoading: false,
  activeModule: "",
  baseModules: [
    {
      moduleName: WIZARDMODULES.ASSOCIATION,
      id: 1,
      description: "Use to create related groups of information.",
      sub_description: "",
    },
    {
      moduleName: WIZARDMODULES.CLASSIFICATION,
      id: 2,
      description: "Use to apply one or more tags to one piece of information.",
      sub_description: "",
    },
    {
      moduleName: WIZARDMODULES.TAG,
      id: 3,
      description: "Create a label to apply to your information.",
      sub_description: "",
    },
    {
      moduleName: WIZARDMODULES.HEADER,
      id: 4,
      description:
        "Use to organize other components into a group. Top level only.",
      sub_description: "",
    },
  ],
  validLabelTags: [],
  validLabelTree: [],
  validLabelGroups: [],
  wizardGroups: [],
  tempTag: { ...TEMP_TAG },
  tempGroup: { ...TEMP_GROUP },
  labelWizardValidationErrors: { ...LABEL_WIZARD_VALIDATION_ERRORS },
  schemaErrors: [],
  treeDepth: 0,
  filteredValidLabelTree: [],
  schemaLastUpdated: null,
};

// getters
const getters = {
  labelWizardLoading(state) {
    return state.labelWizardLoading;
  },
  activeModule(state) {
    return state.activeModule;
  },
  baseModules(state) {
    return state.baseModules;
  },
  validLabelTags(state) {
    return state.validLabelTags;
  },
  validLabelTree(state) {
    return state.validLabelTree;
  },
  validLabelGroups(state) {
    return state.validLabelGroups;
  },
  wizardGroups(state) {
    return state.wizardGroups;
  },
  tempTag(state) {
    return state.tempTag;
  },
  tempGroup(state) {
    return state.tempGroup;
  },
  tempItem: (state) => (itemType) => {
    return itemType === "tag" ? state.tempTag : state.tempGroup;
  },
  labelWizardValidationErrors(state) {
    return state.labelWizardValidationErrors;
  },
  hasSchemaError(state) {
    return state.schemaErrors.includes(true);
  },
  treeDepth(state) {
    return state.treeDepth;
  },
  filteredValidLabelTree(state) {
    return state.filteredValidLabelTree;
  },
  schemaLastUpdated(state) {
    return state.schemaLastUpdated;
  },
};

// mutations
const mutations = {
  setLabelWizardLoading(state, payload) {
    state.labelWizardLoading = payload;
  },
  setActiveModule(state, payload) {
    state.activeModule = payload;
  },
  addTag(state) {
    // create tag
    let t = {
      ...state.tempTag,
      _module_type: state.activeModule,
      _uuid: self.crypto.randomUUID(),
    };
    if (state.activeModule === WIZARDMODULES.ASSOCIATION) {
      t["valid_block_types"] = [GROUP];
    }
    if (state.activeModule === WIZARDMODULES.HEADER) {
      t["min_blocks"] = 0;
    }
    state.validLabelTags.push(t);
    // create tree
    let children = t._module_type === WIZARDMODULES.TAG ? null : [];
    state.validLabelTree.push({ label: t.label, children });
  },
  addGroup(state) {
    // create group
    let g = {
      ...state.tempGroup,
      _module_type: state.activeModule,
      _uuid: self.crypto.randomUUID(),
    };
    state.validLabelGroups.push(g);
    // create tree
    state.validLabelTree.push({ label: g.group, children: null });
  },
  setTempTag(state, payload) {
    state.tempTag = payload;
  },
  resetTempTag(state) {
    state.tempTag = { ...TEMP_TAG };
  },
  setTempGroup(state, payload) {
    state.tempGroup = payload;
  },
  resetTempGroup(state) {
    state.tempGroup = { ...TEMP_GROUP };
  },
  saveEditTag(state, payload) {
    let replaceIdx = state.validLabelTags.findIndex(
      (tag) => tag._uuid === payload._uuid
    );
    // update validLabelTree label
    let tree = findTreeByLabel(
      state.validLabelTags[replaceIdx].label,
      state.validLabelTree
    );
    tree.label = payload.label;
    let idx = state.validLabelTree.findIndex((t) => t.label === tree.label);
    state.validLabelTree[idx] = tree;
    // update validLabelTag
    state.validLabelTags[replaceIdx] = payload;
  },
  saveEditGroup(state, payload) {
    let replaceIdx = state.validLabelGroups.findIndex(
      (group) => group._uuid === payload._uuid
    );
    // update validLabelTree label
    let tree = findTreeByLabel(
      state.validLabelGroups[replaceIdx].group,
      state.validLabelTree
    );
    tree.label = payload.group;
    let idx = state.validLabelTree.findIndex((t) => t.label === tree.label);
    state.validLabelTree[idx] = tree;
    // update validLabelGroup
    state.validLabelGroups[replaceIdx] = payload;
  },
  addGroupToWizardGroups(state, payload) {
    state.wizardGroups.push(payload);
  },
  removeGroupFromWizardGroups(state, payload) {
    const removeIndex = state.wizardGroups.findIndex(
      (g) => g.uuid === payload.groupUUID
    );
    state.wizardGroups.splice(removeIndex, 1);
  },
  setLabelWizardValidationError(state, payload) {
    state.labelWizardValidationErrors[payload.type] = payload.reasons;
  },
  resetLabelWizardErrors(state) {
    state.labelWizardValidationErrors = { ...LABEL_WIZARD_VALIDATION_ERRORS };
  },
  addSchemaError(state, payload) {
    state.schemaErrors.push(payload);
  },
  resetSchemaErrors(state) {
    state.schemaErrors = [];
  },
  setTreeDepth(state, payload) {
    state.treeDepth = payload;
  },
  setFilteredValidLabelTree(state, payload) {
    state.filteredValidLabelTree = payload;
  },
  setLabelSchema(state, payload) {
    state.validLabelTags = payload.label_tags;
    state.validLabelTree = payload.label_tree;
    state.validLabelGroups = payload.label_groups;
  },
  setSchemaLastUpdated(state, payload) {
    state.schemaLastUpdated = payload;
  },
};

// actions
const actions = {
  setLabelWizardLoading(context, payload) {
    context.commit("setLabelWizardLoading", payload);
  },
  setActiveModule(context, payload) {
    context.commit("setActiveModule", payload);
  },
  addTag(context) {
    context.commit("addTag");
    context.dispatch("setActiveModule", "");
    context.dispatch("resetTempTag");
    context.dispatch("resetLabelWizardErrors");
  },
  addGroup(context) {
    context.commit("addGroup");
    context.dispatch("setActiveModule", "");
    context.dispatch("resetTempGroup");
    context.dispatch("resetLabelWizardErrors");
  },
  setTempTag(context, payload) {
    context.commit("setTempTag", payload);
  },
  resetTempTag(context) {
    context.commit("resetTempTag");
  },
  setTempGroup(context, payload) {
    context.commit("setTempGroup", payload);
  },
  resetTempGroup(context) {
    context.commit("resetTempGroup");
  },
  showEditTag(context, payload) {
    let tempTag = context.getters.validLabelTags.find(
      (tag) => tag._uuid === payload._uuid
    );
    context.dispatch("setTempTag", { ...tempTag });
    context.commit("setActiveModule", payload._module_type);
  },
  showEditGroup(context, payload) {
    let tempGroup = context.getters.validLabelGroups.find(
      (group) => group._uuid === payload._uuid
    );
    context.dispatch("setTempGroup", { ...tempGroup });
    context.commit("setActiveModule", payload._module_type);
  },
  saveEditTag(context, payload) {
    context.commit("saveEditTag", payload);
    context.dispatch("setActiveModule", "");
    context.dispatch("resetTempTag");
    context.dispatch("resetLabelWizardErrors");
  },
  saveEditGroup(context, payload) {
    context.commit("saveEditGroup", payload);
    context.dispatch("setActiveModule", "");
    context.dispatch("resetTempGroup");
    context.dispatch("resetLabelWizardErrors");
  },
  addGroupToWizardGroups(context, payload) {
    context.commit("addGroupToWizardGroups", payload);
  },
  removeGroupFromWizardGroups(context, payload) {
    context.commit("removeGroupFromWizardGroups", payload);
  },
  setLabelWizardValidationError(context, payload) {
    context.commit("setLabelWizardValidationError", payload);
  },
  resetLabelWizardErrors(context) {
    context.commit("resetLabelWizardErrors");
  },
  addSchemaError(context, payload) {
    context.commit("addSchemaError", payload);
  },
  resetSchemaErrors(context) {
    context.commit("resetSchemaErrors");
  },
  setTreeDepth(context, payload) {
    context.commit("setTreeDepth", payload);
  },
  setFilteredValidLabelTree(context, payload) {
    context.commit("setFilteredValidLabelTree", payload);
  },
  getLabelSchema(context, payload) {
    context.dispatch("setIsLoading", true);
    return api()
      .get(`projects/${payload}/label-schema`)
      .then((response) => {
        context.dispatch("setLabelSchema", response.data);
      });
  },
  saveLabelSchema(context, payload) {
    context.dispatch("setLabelWizardLoading", true);
    // remove all key/values where the key starts with an underscore
    const sanitizedSchema = sanitizeSchema(payload.schema);
    return api()
      .post(`projects/${payload.projectId}/label-schema`, sanitizedSchema)
      .then((response) => {
        context.dispatch("setLabelSchema", response.data);
        context.dispatch("setNotification", {
          name: "Success",
          type: NOTIFICATION_TYPE.SUCCESS,
          message: `Label Schema saved successfully.`,
        });
      });
  },
  setLabelSchema(context, payload) {
    // add back in internally used key/values
    let schema = payload.label_schema;
    schema = setUUIDs(schema);
    schema = setModuleType(schema);
    schema = setRequired(schema);
    context.commit("setLabelSchema", schema);
    context.commit("setSchemaLastUpdated", payload.created_at);
    context.dispatch("setLabelWizardLoading", false);
    context.dispatch("setIsLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
