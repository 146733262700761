import api from "@/api";
import router from "@/router/index";
import { NOTIFICATION_TYPE } from "@/utils/constants";

const state = {
  history: [],
  selectedExportDocuments: [],
  showExportModal: false,
};

const getters = {
  history(state) {
    return state.history;
  },
  selectedExportDocuments(state) {
    return state.selectedExportDocuments;
  },
  showExportModal(state) {
    return state.showExportModal;
  },
};

const mutations = {
  setHistory(state, payload) {
    state.history = payload;
  },
  setSelectedExportDocuments(state, payload) {
    state.selectedExportDocuments = payload;
  },
  setShowExportModal(state, payload) {
    state.showExportModal = payload;
  },
};

const actions = {
  setShowExportModal(context, payload) {
    context.commit("setShowExportModal", payload);
  },
  fetchHistory(context, payload) {
    const { projectId } = payload;
    context.dispatch("setIsLoading", true);
    api()
      .get(`projects/${projectId}/bulk-exports`, {
        params: { per: 100000 },
      })
      .then((response) => {
        const { bulk_exports } = response.data;
        context.commit("setHistory", bulk_exports);
        context.dispatch("setIsLoading", false);
      });
  },
  setSelectedExportDocuments(context, payload) {
    context.commit("setSelectedExportDocuments", payload);
  },
  removeExports(context, payload) {
    const bulkExportIdsStringWithCommas =
      state.selectedExportDocuments.join(",");
    return api()
      .delete(`/projects/${payload.projectId}/bulk-exports`, {
        params: { export_ids: bulkExportIdsStringWithCommas },
      })
      .then(() => {
        let numDeleted = state.selectedExportDocuments.length;
        context.dispatch("setShowExportModal", false);
        context.dispatch("setSelectedExportDocuments", []);
        context.dispatch("fetchHistory", payload);
        if (router.currentRoute.value.name !== "Bulk Exports") {
          router.push({ name: "Bulk Exports" });
        }
        const message = `${numDeleted} Export${
          numDeleted > 1 ? "s" : ""
        } Deleted`;
        context.dispatch("setNotification", {
          name: "Success",
          type: NOTIFICATION_TYPE.SUCCESS,
          message,
        });
      })
      .catch(() => {
        context.dispatch("setNotification", {
          name: "Failure",
          message: `Sorry, there was error. Exports failed to be deleted.`,
          type: NOTIFICATION_TYPE.ERROR,
        });
      });
  },
  getBulkExport(context, { projectId, bulkExportId }) {
    return api()
      .get(`projects/${projectId}/bulk-exports/${bulkExportId}`)
      .then(({ data }) => data)
      .catch(console.error);
  },
  bulkExportDownload(context, payload) {
    // Only the tab that requested a bulk export needs to download the file
    if (payload.success) {
      if (payload.tab_id === context.getters.tabId) {
        let { project_id: projectId, bulk_export_id: bulkExportId } = payload;
        api()
          .get(`projects/${projectId}/bulk-exports/${bulkExportId}`)
          .then(({ data: { zip: downloadLink } }) => {
            if (downloadLink) {
              context.dispatch("exportDocuments", {
                projectId: projectId,
                bulkExportId: bulkExportId,
              });
            }
          })
          .catch(console.error); // using default handlers frm api, only need to silence it here
      }
      context.dispatch("setNotification", {
        name: "Success",
        message: "Bulk Export Ready - Preparing download",
        type: NOTIFICATION_TYPE.SUCCESS,
      });
    } else {
      context.dispatch("setNotification", {
        name: "Warning",
        message: "Bulk Export failed. Try again",
        type: NOTIFICATION_TYPE.WARNING,
      });
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
