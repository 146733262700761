const state = {
  useClientSearch: true,
};

const getters = {};

const mutations = {
  setUseClientSearch(state, payload) {
    state.useClientSearch = payload;
  },
};

const actions = {
  setUseClientSearch(context, payload) {
    context.commit("setUseClientSearch", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
