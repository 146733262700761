const SetGlobalProperties = {
  install(app) {
    app.config.globalProperties.$apiURL = import.meta.env.VITE_API_URL;
    app.config.globalProperties.$baseLoginRedirectURL =
      import.meta.env.VITE_BASE_LOGIN_REDIRECT_URL;
    app.config.globalProperties.$logoutRedirectURL =
      import.meta.env.VITE_LOGOUT_REDIRECT_URL;
    app.config.globalProperties.$authURL = import.meta.env.VITE_AUTH_URL;
    app.config.globalProperties.$authRealm = import.meta.env.VITE_AUTH_REALM;
    app.config.globalProperties.$dataStorageType =
      import.meta.env.VITE_DATA_STORAGE_TYPE;
  },
};

export default SetGlobalProperties;
