import router from "@/router/index";
import { PROJECT_TABS } from "@/utils/constants";

// I used https://lukashermann.dev/writing/socketio-with-vue-and-vuex/ as a guide for this plugin
export default function createWebSocketPlugin(socket) {
  return (store) => {
    const reactions = {
      // names match to mutation they should react on
      setIsSocketConnected() {
        socket.io.opts.extraHeaders.Authentication = `Bearer ${store.state.auth.keycloak.token}`;
        socket.connect();
      },
      setProjectRoomId(projectId) {
        socket.emit(
          "enter_project_room",
          projectId,
          store.state.auth.keycloak.token
        );
      },
      removeProjectRoomId(projectId) {
        socket.emit("leave_project_room", projectId);
      },
      setContentRoomId(contentId) {
        socket.emit(
          "enter_content_room",
          contentId,
          store.getters.projectRoomId,
          store.state.auth.keycloak.token
        );
      },
      removeContentRoomId(contentId) {
        socket.emit("leave_content_room", contentId);
      },
    };
    socket.on("connect", () => {
      // join rooms User in.
      // we need it as network fails and we could reconnect in the middle of User workflow
      if (store.getters.projectRoomId) {
        reactions.setProjectRoomId(store.getters.projectRoomId);
        if (store.getters.contentRoomId) {
          reactions.setContentRoomId(store.getters.contentRoomId);
        }
      }
    });
    socket.on("reconnect_failed", () => {
      store.dispatch("disconnectWebsocket");
    });
    socket.on("bulk_export_download", (data) => {
      store.dispatch("bulkExportDownload", data);
    });
    socket.on("generate_images", (data) =>
      store.dispatch("updateDocument", data)
    );
    socket.on("async_extraction", (data) =>
      store.dispatch("updateDocument", data)
    );
    socket.on("connect_error", (error) =>
      store.dispatch("connectError", error)
    );
    socket.io.on("reconnect_failed", () => {
      // all reconnect attempts failed
      store.dispatch("signOut");
    });
    socket.on("content_deleted", (data) => {
      if (router.currentRoute.value.name === PROJECT_TABS.DOCUMENTS.route) {
        // dispatch only if User on Documents page
        store.dispatch("forceFetchDocuments", data);
      }
    });
    socket.on("document_updated", (data) => {
      store.dispatch("recommendDocumentRefresh", data);
    });
    socket.on("update_users_in_content_room", (contentRoom) => {
      store.dispatch("setContentRoom", contentRoom);
    });
    socket.on("document_deleted", (data) => {
      store.dispatch("setDocumentDeletedByUser", data.user_email);
    });
    socket.on("questions_updated", (payload) => {
      const contentId = router.currentRoute.value.params.contentId;
      if (payload.content_id.toString() !== contentId) return;
      store.dispatch("assistant/fetchQuestions", {
        projectId: router.currentRoute.value.params.projectId,
        contentId: payload.content_id,
      });
    });

    store.subscribe(({ type, payload }) => {
      // react on certain 'mutations', where mutation.type is a mutation name, like: setProjectRoomId
      // to add new reaction, extend `reactions` object
      const react = reactions[type];
      if (react) {
        react(payload);
      }
    });
  };
}
