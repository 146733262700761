import uploadBase from "@/store/modules/uploadBase";

const state = { ...uploadBase.state() };

const getters = {
  ...uploadBase.getters,
};

const mutations = { ...uploadBase.mutations };

const actions = {
  ...uploadBase.actions,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
