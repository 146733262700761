<template>
  <div
    class="fixed bottom-0 left-0 right-0 z-50 flex flex-col-reverse px-4 py-6 pointer-events-none sm:p-6"
  >
    <transition-group
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform scale-95 translate-y-8 opacity-0 "
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="flex items-end justify-center w-full p-4 pointer-events-none sm:items-start sm:justify-end"
      >
        <app-notification :notification="notification" />
      </div>
    </transition-group>
  </div>
</template>

<script>
import Notification from "@/components/layout/Notification.vue";

export default {
  components: {
    "app-notification": Notification,
  },

  computed: {
    notifications() {
      return this.$store.getters.notifications;
    },
  },
};
</script>
