import { managementApi } from "@/api";
import api from "@/api";
import {
  REVIEWMODES,
  PROJECT_TABS,
  NOTIFICATION_TYPE,
  MODULES,
} from "@/utils/constants";
import getConfigId from "@/utils/getConfigId";
import router from "@/router";

const { UPLOAD, REPORTS, ALERTS, DOCUMENTS, EXPORTS } = PROJECT_TABS;

const state = {
  configLoading: false,
  noProjectAccess: false,
  defaultConfig: {
    tabs: [
      { ...DOCUMENTS, disabled: false },
      { ...UPLOAD, disabled: false },
      { ...REPORTS, disabled: false },
      { ...ALERTS, disabled: false },
      { ...EXPORTS, disabled: false },
    ],
    documentsTab: {
      columnDefinitions: [
        "document_name",
        "status",
        "review_priority_score",
        "created_at",
        "updated_at",
      ],
      disableExport: false,
      disableDelete: false,
      disableDocumentView: false,
    },
    upload: null,
    documentView: {
      mode: REVIEWMODES.INFO_VIEW,
      moduleViewModules: [],
      disableExport: false,
      disableDelete: false,
    },
  },
  projectConfig: {},
  projectConfigId: null,
  accountProjectConfig: {},
  accountProjectConfigId: null,
};

const getters = {
  accountProjectConfig: ({ accountProjectConfig, defaultConfig }) => ({
    ...defaultConfig,
    ...accountProjectConfig,
  }),
  projectConfig: (state) => {
    if (state.projectConfig) {
      return { ...state.defaultConfig, ...state.projectConfig };
    } else {
      return state.defaultConfig;
    }
  },
  projectConfigId: (state) => state.projectConfigId,
  accountProjectConfigId: (state) => state.accountProjectConfigId,
  setConfigLoading: (state) => state.configLoading,
  disabledProjectTabs: (s, getters) =>
    getters.projectConfig.tabs.filter((tab) => tab.disabled),
  projectColumnDefinitions: (s, getters) =>
    getters.projectConfig.documentsTab.columnDefinitions,
  disableDocumentView: (s, getters) =>
    getters.projectConfig.documentsTab.disableDocumentView,
  noProjectAccess: (state) => state.noProjectAccess,
  acceptedFileTypes: (state, getters) =>
    getters.projectConfig.upload.acceptedFileTypes ||
    state.defaultConfig.upload?.acceptedFileTypes,
};

const mutations = {
  setConfigLoading: (state, payload) => (state.configLoading = payload),
  setAccountProjectConfig: (state, { config, configId }) => {
    state.accountProjectConfig = config;
    state.accountProjectConfigId = configId;
  },
  setProjectConfig: (state, { config, configId }) => {
    state.projectConfig = config;
    state.projectConfigId = configId;
  },
  setNoProjectAccess: (state, hasNoAccess) =>
    (state.noProjectAccess = hasNoAccess),
  setAppConfig: (state, payload) =>
    (state.defaultConfig = { ...state.defaultConfig, ...payload }),
};

const actions = {
  setConfigLoading: (context, payload) => {
    context.commit("setConfigLoading", payload);
  },
  fetchAppConfig({ commit, dispatch }) {
    dispatch("setConfigLoading", true);
    return managementApi({ useInterceptCancelToken: false })
      .get("/app/config")
      .then((res) => commit("setAppConfig", res.data))
      .then(() => dispatch("setConfigLoading", false))
      .catch((err) => {
        dispatch("setConfigLoading", false);
        throw err;
      });
  },
  async fetchAccountProjectConfig(context, { accountId } = {}) {
    context.dispatch("setConfigLoading", true);
    const configAccountId =
      accountId || parseInt(router.currentRoute.value.params.accountId);
    try {
      const res = await api({
        interceptError: false,
        useInterceptCancelToken: false,
      }).get(`accounts/${configAccountId}/configuration`);
      context.commit("setAccountProjectConfig", {
        config: res.data,
        configId: configAccountId,
      });
    } catch (err) {
      context.commit("setAccountProjectConfig", {
        config: null,
        configId: configAccountId,
      });
      console.error(err);
      context.dispatch("setNotification", {
        name: "Failure",
        message: `Unable to get default project configuration for account ${accountId}. Using global default project config.`,
        type: NOTIFICATION_TYPE.ERROR,
      });
    }
    context.dispatch("setConfigLoading", false);
  },
  async fetchProjectConfig(context, { id, accountId }) {
    context.dispatch("setConfigLoading", true);
    context.commit("setNoProjectAccess", false);
    const configAccountId =
      accountId || parseInt(router.currentRoute.value.params.accountId);
    try {
      const res = await api({
        interceptError: false,
        useInterceptCancelToken: false,
      }).get(`accounts/${configAccountId}/projects/${id}/configuration`);
      context.commit("setProjectConfig", {
        config: res.data,
        configId: getConfigId(configAccountId, id),
      });
      const modules = res.data.documentView?.moduleViewModules || [];
      context.dispatch("docIQ/setActiveModule", MODULES[modules[0]], {
        root: true,
      });
    } catch (err) {
      if (err?.response?.status === 403) {
        context.commit("setNoProjectAccess", true);
      } else {
        context.commit("setDisplayError", "");
        context.commit("setProjectConfig", {
          config: null,
          configId: getConfigId(configAccountId, id),
        });

        context.dispatch("setNotification", {
          name: "Failed to load project configuration",
          message: `The default project configuration will be used instead.`,
          type: NOTIFICATION_TYPE.ERROR,
        });
      }
    }
    context.dispatch("setConfigLoading", false);
  },
  async saveAccountProjectConfig(context, { config }) {
    context.dispatch("setConfigLoading", true);
    const accountId = parseInt(router.currentRoute.value.params.accountId);
    try {
      const res = await api({
        interceptError: false,
        useInterceptCancelToken: false,
      }).post(`accounts/${accountId}/configuration`, {
        value: config,
      });
      if (res.data)
        context.commit("setAccountProjectConfig", {
          config: res.data,
          configId: accountId,
        });
    } catch (err) {
      console.error(err);
      context.dispatch("setNotification", {
        name: "Failure",
        message: `Unable to save default project configuration for account ${accountId}`,
        type: NOTIFICATION_TYPE.ERROR,
      });
    }
    context.dispatch("setConfigLoading", false);
  },
  async saveProjectConfig(context, { config, id }) {
    context.dispatch("setConfigLoading", true);
    const accountId = parseInt(router.currentRoute.value.params.accountId);
    try {
      const res = await api({
        interceptError: false,
        useInterceptCancelToken: false,
      }).post(`accounts/${accountId}/projects/${id}/configuration`, {
        value: config,
      });
      if (res.data)
        context.commit("setProjectConfig", {
          config: res.data,
          configId: getConfigId(accountId, id),
        });
    } catch (err) {
      console.error(err);
      context.dispatch("setNotification", {
        name: "Failure",
        message: `Unable to save project configuration`,
        type: NOTIFICATION_TYPE.ERROR,
      });
    }
    context.dispatch("setConfigLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
