import api from "@/api";

// state
const state = {
  projectId: "",
  projectName: "",
};

// getters
const getters = {
  projectName(state) {
    return state.projectName;
  },
};

// mutations
const mutations = {
  setProjectName(state, payload) {
    state.projectName = payload;
  },
};

// actions
const actions = {
  setProjectName(context, payload) {
    context.commit("setProjectName", payload);
  },
  fetchProjectName(context, { accountId, projectId }) {
    api()
      .get(`accounts/${accountId}/projects/${projectId}`)
      .then((response) => {
        context.dispatch("setProjectName", response.data.display_name);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
