import { API_DOCS } from "@/utils/constants";

const state = {
  apiDoc: {},
  endpoints: [],
  settings: {},
};

const getters = {};

const mutations = {
  setApiDoc(state, payload) {
    state.apiDoc = payload;
  },
  setEndpoints(state, payload) {
    state.endpoints = payload;
  },
  setSettings(state, payload) {
    state.settings = payload;
  },
};

const actions = {
  setApiDoc(context, payload) {
    context.commit("setApiDoc", payload);
  },
  initEndpoints(context) {
    const result = [];
    for (const path in context.state.apiDoc.paths) {
      for (const httpAction in context.state.apiDoc.paths[path]) {
        const newObj = {
          path: path,
          httpAction: httpAction,
          details: context.state.apiDoc.paths[path][httpAction],
          show: false,
        };
        result.push(newObj);
      }
    }
    context.commit("setEndpoints", result);
  },
  initSettings(context) {
    let settings = {};
    context.state.endpoints.forEach((endpoint, idx) => {
      let activeTab;
      if (endpoint.details.requestBody) activeTab = API_DOCS.REQUEST.id;
      else if (endpoint.details.parameters) activeTab = API_DOCS.PARAMETERS.id;
      else if (endpoint.details.responses) activeTab = API_DOCS.RESPONSES.id;
      settings[idx] = {
        showHide: false,
        showRequestExample: true,
        showResponseExample: true,
        activeTab,
      };
    });
    context.commit("setSettings", settings);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
