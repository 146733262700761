import api from "@/api";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import {
  cloneDeep as _cloneDeep,
  defaultsDeep as _defaultsDeep,
} from "lodash-es";
import { getBlocks, dedupeBlocks } from "@/utils/infoView";
import {
  amendBlockTree,
  getLastIndexInPath,
  lastIndexRegex,
} from "@/utils/docIQ";

const state = {
  activeModule: { id: null, name: null },
  showModulePanel: true,
  isLoading: false,
  blockTree: [],
  activeBlock: null,
  activeBlockFrom: null,
  activeLabel: null,
  query: "",
  extractedLayer: {
    enabled: false,
    viewOptions: {
      showAllBlocks: false,
    },
    blockFilter: {
      activeBlockOnly: false,
      allBlocks: false,
    },
    actions: {
      setActiveBlockOnClick: false,
      useContextMenu: false,
    },
  },
};

const getters = {
  blocks(state) {
    const blocks = getBlocks(state.blockTree);
    const clonedBlocks = _cloneDeep(blocks);
    const dedupedBlocks = dedupeBlocks(clonedBlocks);
    return dedupedBlocks;
  },
};

const mutations = {
  setActiveModule(state, payload) {
    state.activeModule = payload;
  },
  setShowModulePanel(state, payload) {
    state.showModulePanel = payload;
  },
  setBlockTree(state, payload) {
    state.blockTree = amendBlockTree(payload);
  },
  updateBlockTree(state, payload) {
    state.blockTree[parseInt(payload.treeIndex)] = payload.data;
    state.blockTree = amendBlockTree(state.blockTree);
  },
  setActiveBlock(state, payload) {
    state.activeBlock = payload;
  },
  setActiveBlockFrom(state, payload) {
    state.activeBlockFrom = payload;
  },
  setActiveLabel(state, label) {
    state.activeLabel = label;
  },
  setQuery(state, payload) {
    state.query = payload;
  },
  setExtractedLayer(state, payload) {
    state.extractedLayer = _defaultsDeep({}, payload, state.extractedLayer);
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
};

const actions = {
  setActiveModule(context, payload) {
    context.commit("setActiveModule", payload);
  },
  setShowModulePanel(context, payload) {
    context.commit("setShowModulePanel", payload);
  },
  fetchBlockTree(context, { projectId, contentId }) {
    context.commit("setIsLoading", true);
    return api()
      .get(`projects/${projectId}/contents/${contentId}/block-tree`)
      .then((response) => {
        context.commit("setBlockTree", response.data);
        return true;
      })
      .then(() => {
        context.commit("setIsLoading", false);
      });
  },
  setActiveBlock(context, payload) {
    context.commit("setActiveBlock", payload);
  },
  setActiveBlockFrom(context, payload) {
    context.commit("setActiveBlockFrom", payload);
  },
  setActiveLabel(context, payload) {
    context.commit("setActiveLabel", payload);
  },
  setQuery(context, payload) {
    context.commit("setQuery", payload);
  },
  setExtractedLayer(context, payload) {
    context.commit("setExtractedLayer", payload);
  },
  resetExtractedLayer(context) {
    function setAllKeysTo(val, obj) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          if (typeof value === "object") {
            return [key, setAllKeysTo(val, value)];
          } else {
            return [key, val];
          }
        })
      );
    }
    context.commit(
      "setExtractedLayer",
      setAllKeysTo(false, context.state.extractedLayer)
    );
  },
  updateBlock(
    context,
    { projectId, contentId, block, showNotification = true }
  ) {
    return api({ interceptError: false })
      .put(
        `/projects/${projectId}/contents/${contentId}/block-tree/${block._treeIdx}?path=${block._path}`,
        [block]
      )
      .then((response) => {
        context.commit("updateBlockTree", {
          treeIndex: block._treeIdx,
          data: response.data,
        });
        if (showNotification) {
          context.dispatch(
            "setNotification",
            {
              name: "Success",
              message: "Value updated.",
              type: NOTIFICATION_TYPE.SUCCESS,
            },
            { root: true }
          );
        }
      })
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not update value.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
        throw new Error("Could not update value.");
      });
  },
  addBlock(
    context,
    {
      projectId,
      contentId,
      block,
      treeIndex,
      path,
      isNew = true,
      useDefaultNotifications = true,
    } = {}
  ) {
    return api({ interceptError: false })
      .post(
        `/projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}&is_new=${isNew}`,
        [block]
      )
      .then((response) => {
        context.commit("updateBlockTree", {
          treeIndex,
          data: response.data,
        });
        if (useDefaultNotifications) {
          context.dispatch(
            "setNotification",
            {
              name: "Success",
              message: "Value added.",
              type: NOTIFICATION_TYPE.SUCCESS,
            },
            { root: true }
          );
        }
      })
      .catch((e) => {
        if (useDefaultNotifications) {
          context.dispatch(
            "setNotification",
            {
              name: "Error",
              message: "Could not add value.",
              type: NOTIFICATION_TYPE.ERROR,
            },
            { root: true }
          );
        } else {
          throw e;
        }
      });
  },
  async deleteBlock(context, { projectId, contentId, block }) {
    let path = block._path;
    const blockIndex = getLastIndexInPath(block._path);
    if (blockIndex) {
      path = block._path.replace(
        lastIndexRegex,
        `[${blockIndex}:${+blockIndex + 1}]`
      );
    }
    return api({ interceptError: false })
      .delete(
        `projects/${projectId}/contents/${contentId}/block-tree/${block._treeIdx}?path=${path}`
      )
      .then(({ data }) => {
        context.commit("updateBlockTree", {
          treeIndex: block._treeIdx,
          data,
        });
      });
  },
  async deleteBlocks(context, { projectId, contentId, blocks }) {
    const toDelete = blocks.toSorted((a, b) => {
      const lastIndexA = getLastIndexInPath(a._path);
      const lastIndexB = getLastIndexInPath(b._path);
      return lastIndexB - lastIndexA;
    });
    const treeIdxs = [...new Set(blocks.map((block) => block._treeIdx))];
    for (const idx of treeIdxs) {
      let data = [];
      for (const block of toDelete) {
        let path = block._path;
        const blockIndex = getLastIndexInPath(block._path);
        if (blockIndex) {
          path = block._path.replace(
            lastIndexRegex,
            `[${blockIndex}:${+blockIndex + 1}]`
          );
        }
        const response = await api({ interceptError: false }).delete(
          `projects/${projectId}/contents/${contentId}/block-tree/${block._treeIdx}?path=${path}`
        );
        data = response.data;
      }
      context.commit("updateBlockTree", {
        treeIndex: idx,
        data,
      });
    }
  },
  createGroup(context, { projectId, contentId, treeIndex, path }) {
    const payload = [
      { block_type: "GROUP", uuid: self.crypto.randomUUID(), block_uuids: [] },
    ];
    api({ interceptError: false })
      .post(
        `projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}`,
        payload
      )
      .then((response) => {
        context.commit("updateBlockTree", {
          treeIndex,
          data: response.data,
        });
        context.dispatch(
          "setNotification",
          {
            name: "Success",
            message: "Group added.",
            type: NOTIFICATION_TYPE.SUCCESS,
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not add group.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
      });
  },
  deleteGroup(context, { projectId, contentId, treeIndex, path }) {
    api({ interceptError: false })
      .delete(
        `projects/${projectId}/contents/${contentId}/block-tree/${treeIndex}?path=${path}`
      )
      .then((response) => {
        context.commit("updateBlockTree", {
          treeIndex,
          data: response.data,
        });
        context.dispatch(
          "setNotification",
          {
            name: "Success",
            message: "Group deleted.",
            type: NOTIFICATION_TYPE.SUCCESS,
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not delete group.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
