const state = {
  items: [],
};

const getters = {};

const mutations = {
  addItem(state, payload) {
    state.items.push(payload);
  },
  removeItem(state, payload) {
    state.items = state.items.filter((item) => item.id !== payload);
  },
};

const actions = {
  addItem(context, payload) {
    context.commit("addItem", payload);
  },
  removeItem(context, payload) {
    context.commit("removeItem", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
