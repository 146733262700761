import api from "@/api";
import router from "@/router";
// import api from "@/api";

const state = {
  showStorageProfileProjectsModal: false,
  showStorageProfileCloudModal: false,
  showCreateStorageProfileModal: false,
  showDeleteStorageProfileModal: false,
  storageProfiles: [],
  storageProfile: {},
};

const getters = {
  showStorageProfileProjectsModal(state) {
    return state.showStorageProfileProjectsModal;
  },
  showStorageProfileCloudModal(state) {
    return state.showStorageProfileCloudModal;
  },
  showCreateStorageProfileModal(state) {
    return state.showCreateStorageProfileModal;
  },
  showDeleteStorageProfileModal(state) {
    return state.showDeleteStorageProfileModal;
  },
  activeStorageProfiles(state) {
    return state.storageProfiles.filter((profile) => !profile.disabled);
  },
  storageProfile(state) {
    return state.storageProfile;
  },
};

const mutations = {
  setShowStorageProfileProjectsModal(state, payload) {
    state.showStorageProfileProjectsModal = payload;
  },
  setShowStorageProfileCloudModal(state, payload) {
    state.showStorageProfileCloudModal = payload;
  },
  setShowCreateStorageProfileModal(state, payload) {
    state.showCreateStorageProfileModal = payload;
  },
  setShowDeleteStorageProfileModal(state, payload) {
    state.showDeleteStorageProfileModal = payload;
  },
  setStorageProfiles(state, payload) {
    state.storageProfiles = payload;
  },
  setStorageProfile(state, payload) {
    state.storageProfile = payload;
  },
};

const actions = {
  setShowStorageProfileProjectsModal(context, payload) {
    context.commit("setShowStorageProfileProjectsModal", payload);
  },
  setShowStorageProfileCloudModal(context, payload) {
    context.commit("setShowStorageProfileCloudModal", payload);
  },
  setShowCreateStorageProfileModal(context, payload) {
    context.commit("setShowCreateStorageProfileModal", payload);
  },
  setShowDeleteStorageProfileModal(context, payload) {
    context.commit("setShowDeleteStorageProfileModal", payload);
  },
  fetchStorageProfiles(context, payload) {
    const disableLoading = payload && !!payload.disableLoading;
    if (!disableLoading) context.dispatch("setIsLoading", true);
    return api({ useInterceptCancelToken: false })
      .get(
        `accounts/${router.currentRoute.value.params.accountId}/storage-profiles`
      )
      .then((response) => {
        context.commit("setStorageProfiles", response.data);
        if (!disableLoading) context.dispatch("setIsLoading", false);
      });
  },
  async fetchStorageProfile(context, payload) {
    await api({ useInterceptCancelToken: false })
      .get(
        `accounts/${router.currentRoute.value.params.accountId}/storage-profiles/${payload}`
      )
      .then((response) => {
        context.commit("setStorageProfile", response.data);
      });
  },
  createStorageProfile(context, payload) {
    context.dispatch("setIsLoading", true);
    api({ useInterceptCancelToken: false })
      .post(
        `accounts/${router.currentRoute.value.params.accountId}/storage-profiles`,
        payload
      )
      .then(() => {
        context.dispatch("setShowCreateStorageProfileModal", false);
        context.dispatch("fetchStorageProfiles");
      });
  },
  deleteStorageProfile(context, payload) {
    context.dispatch("setIsLoading", true);
    api({ useInterceptCancelToken: false })
      .delete(
        `accounts/${router.currentRoute.value.params.accountId}/storage-profiles/${payload}`
      )
      .then(() => {
        context.dispatch("setShowDeleteStorageProfileModal", false);
        context.dispatch("fetchStorageProfiles");
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
