export const UNPROCESSED = "Unprocessed";
export const PROCESSING = "Processing";
export const PROCESSED = "Processed";
export const PENDING_REVIEW = "Pending Review";
export const REVIEWED = "Reviewed";
export const ERROR = "Error";
export const DOCUMENT = "DOCUMENT";
export const PAGE = "PAGE";
export const ENTITY = "ENTITY";
export const LINE = "LINE";
export const IMAGE = "IMAGE";
export const TABLE = "TABLE";
export const TEXT = "TEXT";
export const WORD = "WORD";
export const LABEL = "LABEL";
export const GROUP = "GROUP";
export const ASSIGNEE = "assignee";
export const BUTTON = "button";

export const ONE_SECOND_IN_MILLISECONDS = 1000;
export const DEFAULT_IDLE_TIMEOUT_IN_MILLISECONDS =
  ONE_SECOND_IN_MILLISECONDS * 60 * 5;
export const IDLE_MODAL_DURATION_IN_MILLISECONDS =
  ONE_SECOND_IN_MILLISECONDS * 20;

export const STATUSCOLORSCLASS = {
  GREEN: "bg-green-400",
  YELLOW: "bg-yellow-400",
  RED: "bg-red-400",
  GRAY: "bg-gray-400",
  ORANGE: "bg-orange-400",
};

export const REVIEWMODES = {
  INFO_VIEW: "INFO_VIEW",
  MODULE_VIEW: "MODULE_VIEW",
};

export const WIZARDMODULES = {
  ASSOCIATION: "Association",
  CLASSIFICATION: "Classification",
  TAG: "Tag",
  HEADER: "Header",
};

export const ASSOCIATION = "association";
export const CLASSIFICATION = "classification";
export const TAG = "tag";
export const HEADER = "header";

export const UPLOAD_MODES = {
  INTERNAL: "INTERNAL",
  EXTERNAL: "EXTERNAL",
};

export const FILE_STATUS = {
  MISSING: "Missing document",
  READY: "Ready to upload",
  AWAITING: "Awaiting upload",
  UPLOADING: "Uploading",
  STORING: "Storing",
  COMPLETE: "Completed",
  FAILED: "Failed to upload",
};

export const KEYCLOAK = {
  ROLES: { ADMIN: "IDP:Admin" },
};

export const NOTIFICATION_TYPE = {
  INFO: "INFO",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

export const DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES = [
  "pdf",
  "txt",
  "doc",
  "docx",
  "rtf",
  "ppt",
  "pptx",
  "jpg",
  "jpeg",
  "png",
  "tif",
  "tiff",
  "bmp",
  "zip",
];

export const QUERY_PARAMS_TO_DELETE = ["idpHint", "iss"];

export const PROJECT_TABS = {
  DOCUMENTS: { name: "Documents", route: "Documents" },
  UPLOAD: { name: "Upload", route: "Upload" },
  EXPORTS: { name: "Exports", route: "Bulk Exports" },
  REPORTS: { name: "Reports", route: "Project Reports" },
  ALERTS: { name: "Alerts", route: "Project Alerts" },
  API_DOCS: { name: "API Docs", route: "API Docs" },
  LABEL_WIZARD: { name: "Label Wizard", route: "Label Wizard" },
  SETTINGS: { name: "Settings", route: "Project Settings" },
};

export const SETTINGS_TABS = {
  PASSWORD: { name: "Password", route: "Password" },
  ACCESS_KEYS: { name: "Access Keys", route: "Access Keys" },
  STORAGE_PROFILES: { name: "Storage Profiles", route: "Storage Profiles" },
};

export const PERMISSION_SCOPE = {
  CREATE: "create",
  EDIT: "edit",
  VIEW: "view",
  DELETE: "delete",
};

export const AZURE = "azure";
export const S3 = "s3";

export const AVATAR_COLORS = [
  "bg-green-600",
  "bg-red-600",
  "bg-blue-600",
  "bg-purple-600",
  "bg-pink-700",
  "bg-yellow-500",
  "bg-orange-600",
];

export const MAX_ZOOM_OUT_LEVEL = 0.3;
export const BASE_ZOOM_LEVEL = 1;
export const MAX_ZOOM_IN_LEVEL = 1.5;
export const ZOOM_INCREMENT = 0.1;

export const PAGINATION = {
  PER_PAGE_MIN: 10,
  PER_PAGE_MAX: 100,
};

export const MAX_TREE_DEPTH = 5;

export const SLIDE_DIRECTION = {
  NEXT: 0,
  PREV: 1,
};

export const IMAGE_URLS_PER = 20;

export const PAGE_MARGIN = 16;

export const API_DOCS = {
  PARAMETERS: { id: "parameters", name: "Parameters" },
  REQUEST: { id: "request", name: "Request Body" },
  RESPONSES: { id: "responses", name: "Responses" },
};

export const MODULES = {
  OVERVIEW: { id: "overview", name: "Overview", icon: "bars-3-bottom-left" },
  APPEALS: { id: "appeals", name: "Appeals", icon: "briefcase" },
  BOOKMARKS: { id: "bookmarks", name: "Bookmarks", icon: "bookmark" },
  UB04: { id: "ub04", name: "UB-04", icon: "document" },
  ASSISTANT: {
    id: "assistant",
    name: "Assistant",
    icon: "sparkles",
    isWide: true,
  },
};

export const USER = "USER";
export const DOCUMENT_API = "DOCUMENT_API";

export const ASSISTANT_FEEDBACKS = [
  { label: "Inaccurate page", id: "INACCURATE_PAGE" },
  { label: "Better page available", id: "BETTER_PAGE_AVAILABLE" },
  { label: "Incorrect information", id: "INCORRECT_INFORMATION" },
  { label: "Not helpful", id: "NOT_HELPFUL" },
  { label: "Incomplete response", id: "INCOMPLETE_RESPONSE" },
  { label: "Other", id: "OTHER" },
];

export const INVALID_NPI = "Invalid NPI";

export const INVALID_NDC = "Invalid NDC";

export const DIAGNOSIS_CODE_IDS = ["67", "69", "70", "72"];

export const FEEDBACK_URL =
  "https://form.asana.com/?k=F30CphDZIqaxgqptmKqy3w&d=926388212493407";

export const BUG_URL =
  "https://aspirion.service-now.com/aspirion?id=sc_cat_item&sys_id=a888349c87038910fa9e639d0ebb358b";

export const CODE_LOOKUP_TOOL_ID = "code-lookup-tool";
