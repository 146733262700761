// state
const state = {
  gridApi: {},
  gridColumnApi: {},
  gridFilterOptions: {},
  columnState: {},
  currentPage: {},
  pageSize: {},
  totalPages: {},
  rowCount: {},
};

// getters
const getters = {
  gridApi: (state) => (tableId) => {
    return state.gridApi[tableId] || {};
  },
  gridColumnApi: (state) => (tableId) => {
    return state.gridColumnApi[tableId] || {};
  },
  gridFilterOptions: (state) => (tableId) => {
    return state.gridFilterOptions[tableId] || {};
  },
  columnState: (state) => (tableId) => {
    return state.columnState[tableId] || {};
  },
  currentPage: (state) => (tableId) => {
    return state.currentPage[tableId] || 1;
  },
  pageSize: (state) => (tableId) => {
    return state.pageSize[tableId] || 10;
  },
  totalPages: (state) => (tableId) => {
    return state.totalPages[tableId] || null;
  },
  rowCount: (state) => (tableId) => {
    return state.rowCount[tableId] || null;
  },
};

// mutations
const mutations = {
  setGridApi(state, { tableId, gridApi }) {
    state.gridApi[tableId] = gridApi;
  },
  setGridColumnApi(state, { tableId, gridColumnApi }) {
    state.gridColumnApi[tableId] = gridColumnApi;
  },
  setGridFilterOptions(state, { tableId, gridFilterOptions }) {
    state.gridFilterOptions[tableId] = gridFilterOptions;
  },
  setColumnState(state, { tableId, columnState }) {
    state.columnState[tableId] = columnState;
  },
  setCurrentPage(state, { tableId, currentPage }) {
    state.currentPage[tableId] = currentPage;
  },
  setPageSize(state, { tableId, pageSize }) {
    state.pageSize[tableId] = pageSize;
  },
  setTotalPages(state, { tableId, totalPages }) {
    state.totalPages[tableId] = totalPages;
  },
  setRowCount(state, { tableId, rowCount }) {
    state.rowCount[tableId] = rowCount;
  },
};

// actions
const actions = {
  setGridApi(context, payload) {
    context.commit("setGridApi", payload);
  },
  setGridColumnApi(context, payload) {
    context.commit("setGridColumnApi", payload);
  },
  setGridFilterOptions(context, payload) {
    context.commit("setGridFilterOptions", payload);
  },
  setColumnState(context, payload) {
    context.commit("setColumnState", payload);
  },
  setCurrentPage(context, payload) {
    context.commit("setCurrentPage", payload);
  },
  setPageSize(context, payload) {
    context.commit("setPageSize", payload);
  },
  setTotalPages(context, payload) {
    context.commit("setTotalPages", payload);
  },
  setRowCount(context, payload) {
    context.commit("setRowCount", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
