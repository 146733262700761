import api from "@/api";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import router from "@/router";

const state = {
  projects: [],
  activeProjectDetailsId: null,
  projectMarkedForDeletion: {
    id: null,
    display_name: null,
  },
  projectBeingEdited: null,
};

const getters = {
  projects(state) {
    return state.projects;
  },
  projectMarkedForDeletion(state) {
    return state.projectMarkedForDeletion;
  },
  projectBeingEdited: (state) => state.projectBeingEdited,
};

const mutations = {
  setProjects(state, payload) {
    state.projects = payload;
  },
  projectMarkedForDeletion(state) {
    return state.projectMarkedForDeletion;
  },
  setProjectForDeletion(state, payload) {
    state.projectMarkedForDeletion = payload;
  },
  setProjectBeingEdited(state, payload) {
    state.projectBeingEdited = payload;
  },
  setActiveProjectDetailsId(state, payload) {
    state.activeProjectDetailsId = payload;
  },
};

const actions = {
  setActiveProjectDetailsId({ commit }, id) {
    commit("setActiveProjectDetailsId", id);
  },
  setProjects(context, payload) {
    context.commit("setProjects", payload);
  },
  fetchProjects(context) {
    context.dispatch("setIsLoading", true);
    api({ useInterceptCancelToken: false })
      .get(`accounts/${router.currentRoute.value.params.accountId}/projects`)
      .then(({ data: projects }) => {
        const ids = projects.map((project) => project.id);
        api()
          .post("project-stats", {
            project_ids: ids,
          })
          .then(({ data: stats }) => {
            for (const project of projects) {
              const id = project.id;
              const {
                Reviewed,
                Processed,
                "Pending Review": PendingReview,
              } = stats[id].status_counts;
              project.total_documents = stats[id].total_documents;
              project.reviewed_documents = Reviewed;
              project.unreviewed_documents = Processed + PendingReview;

              const dates = [
                stats[id].updated_at,
                project.ctime,
                project.mtime,
              ].filter((date) => !!date);

              project.updated_at = dates.sort()[dates.length - 1];
            }
          })
          .then(() => {
            context.commit("setProjects", projects);
            context.dispatch("setIsLoading", false);
          });
      });
  },
  createProject(context, payload) {
    context.dispatch("setIsLoading", true);
    api({ interceptError: false, useInterceptCancelToken: false })
      .post(
        `accounts/${router.currentRoute.value.params.accountId}/projects`,
        payload
      )
      .then(() => {
        context.dispatch("setNotification", {
          name: "Success",
          message: "New Project created",
          type: NOTIFICATION_TYPE.SUCCESS,
        });
        context.dispatch("setIsLoading", false);
      })
      .then(() => {
        context.dispatch("fetchProjects");
      })
      .catch(() => {
        context.dispatch("setNotification", {
          name: "Failure",
          message: `Unable to create project`,
          type: NOTIFICATION_TYPE.ERROR,
        });
        context.dispatch("setIsLoading", false);
      });
  },
  setProjectBeingEdited(context, payload) {
    context.commit("setProjectBeingEdited", payload);
  },
  updateProject(context, payload) {
    return api({
      interceptError: false,
      useInterceptCancelToken: false,
    })
      .put(
        `accounts/${router.currentRoute.value.params.accountId}/projects/${payload.id}`,
        {
          display_name: payload.display_name,
          description: payload.description,
        }
      )
      .then(() => {
        context.dispatch("setNotification", {
          name: "Success",
          message: `Project Updated`,
          type: NOTIFICATION_TYPE.SUCCESS,
        });
      })
      .then(() => {
        context.dispatch("fetchProjects");
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("setNotification", {
          name: "Failure",
          message: `Unable to Update Project`,
          type: NOTIFICATION_TYPE.ERROR,
        });
      });
  },
  setProjectForDeletion(context, payload) {
    context.commit("setProjectForDeletion", payload);
  },
  deleteProject(context, payload) {
    const { id, display_name } = payload;
    context.dispatch("setIsLoading", true);
    api({ interceptError: false, useInterceptCancelToken: false })
      .delete(
        `accounts/${router.currentRoute.value.params.accountId}/projects/${id}`
      )
      .then(() => {
        context.dispatch("setNotification", {
          name: "Success",
          message: `Project ${display_name} deleted`,
          type: NOTIFICATION_TYPE.SUCCESS,
        });
      })
      .then(() => {
        context.dispatch("fetchProjects");
      })
      .then(() => {
        context.dispatch("setProjectForDeletion", {});
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("setNotification", {
          name: "Failure",
          message: `Unable to delete project ${display_name}`,
          type: NOTIFICATION_TYPE.ERROR,
        });
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
