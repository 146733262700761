import router from "@/router/index";
import { managementApi } from "@/api";

const state = {
  authErrors: {},
  showCheckEmailModal: { status: false, title: "", message: "" },
  keycloak: {},
};

const getters = {
  isSignedIn(state) {
    return state.keycloak.authenticated;
  },
  authErrors(state) {
    return state.authErrors;
  },
  showCheckEmailModal(state) {
    return state.showCheckEmailModal;
  },
  email(state) {
    return state.keycloak.tokenParsed.email;
  },
  userId(state) {
    return state.keycloak.tokenParsed.sub;
  },
  username(state) {
    return state.keycloak.tokenParsed.preferred_username;
  },
  isAdmin(state) {
    return state.keycloak.realmAccess.roles.includes("IDP:Admin");
  },
  isAccountAdmin(state) {
    return (accountId) => {
      return state.keycloak.realmAccess.roles.includes(
        `IDP:Account-${accountId}:Admin`
      );
    };
  },
  isAccountManager(state) {
    return (accountId) => {
      return state.keycloak.realmAccess.roles.includes(
        `IDP:Account-${accountId}:Manager`
      );
    };
  },
  isAccountAllProjectsManager(state) {
    return (accountId) => {
      return state.keycloak.realmAccess?.roles.includes(
        `IDP:Account-${accountId}:AllProjects`
      );
    };
  },

  identityProvider(state) {
    return state.keycloak.tokenParsed.identity_provider;
  },
};

const mutations = {
  setKeycloak(state, keycloak) {
    state.keycloak = keycloak;
  },
  setAuthErrors(state, token) {
    state.authErrors = token;
  },
  setShowCheckEmailModal(state, payload) {
    state.showCheckEmailModal = payload;
  },
};

const actions = {
  setKeycloak({ commit }, keycloak) {
    commit("setKeycloak", keycloak);
  },
  setAuthErrors(context, payload) {
    context.commit("setAuthErrors", payload);
  },
  signIn(context, { idpHint }) {
    if (!context.getters.isSignedIn) {
      const loginUrl = context.state.keycloak.createLoginUrl({ idpHint });
      window.location.replace(loginUrl);
    }
  },
  manageAccount({ state }) {
    state.keycloak.accountManagement();
  },
  changePassword(context) {
    managementApi({ useInterceptCancelToken: false })
      .post("auth/update-password", {})
      .then(() => {
        context.commit("setShowCheckEmailModal", {
          status: true,
          title: "Password Change Request",
          message:
            "Please check your email for further instructions on how to change your password.",
        });
      });
  },
  setShowCheckEmailModal(context, payload) {
    context.commit("setShowCheckEmailModal", payload);
  },
  async signOut({ state }) {
    if (state.keycloak.logout) {
      localStorage.clear();
      state.keycloak.logout();
    } else {
      router.push({ name: "Home" });
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
