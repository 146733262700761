import { createStore } from "vuex";
import createPersistedState from "@/plugins/vuex-persistedstate";
import socket from "@/socket";
import createWebSocketPlugin from "@/store/plugins/createWebSocketPlugin";

import account from "./modules/account";
import apiDocs from "./modules/apiDocs";
import assignments from "./modules/assignments";
import assistant from "./modules/docIQ/assistant";
import auth from "./modules/auth";
import bookmarks from "./modules/docIQ/bookmarks";
import bulkExportsHistory from "./modules/bulkExportsHistory";
import cancelTokens from "./modules/cancelTokens";
import codeLookup from "./modules/docIQ/codeLookup";
import config from "./modules/config";
import contentRoom from "./modules/contentRoom";
import docIQ from "./modules/docIQ";
import document from "./modules/document";
import documents from "./modules/documents";
import documentData from "./modules/documentData";
import documentImages from "./modules/documentImages";
import documentHighlighting from "./modules/documentHighlighting";
import infoView from "./modules/infoView";
import labelWizard from "./modules/labelWizard";
import layout from "./modules/layout";
import minimizedDock from "./modules/docIQ/minimizedDock";
import notification from "./modules/notification";
import overview from "./modules/docIQ/overview";
import permissions from "./modules/permissions";
import project from "./modules/project";
import projects from "./modules/projects";
import rangeSlider from "./modules/rangeSlider";
import resizer from "./modules/docIQ/resizer";
import searchClient from "./modules/searchClient";
import searchIndex from "./modules/searchIndex";
import searchServer from "./modules/searchServer";
import storageProfiles from "./modules/storageProfiles";
import tabId from "./modules/tabId";
import table from "./modules/table";
import ub04 from "./modules/docIQ/ub04";
import uploadExternal from "./modules/uploadExternal";
import uploadInternal from "./modules/uploadInternal";
import websocket from "./modules/websocket";
import zoom from "./modules/zoom";

const websocketPlugin = createWebSocketPlugin(socket);

const store = createStore({
  modules: {
    account,
    apiDocs,
    assignments,
    assistant,
    auth,
    bookmarks,
    bulkExportsHistory,
    cancelTokens,
    codeLookup,
    config,
    contentRoom,
    docIQ,
    document,
    documents,
    documentData,
    documentImages,
    documentHighlighting,
    infoView,
    labelWizard,
    layout,
    minimizedDock,
    notification,
    overview,
    permissions,
    project,
    projects,
    rangeSlider,
    resizer,
    searchClient,
    searchIndex,
    searchServer,
    storageProfiles,
    tabId,
    table,
    ub04,
    uploadExternal,
    uploadInternal,
    websocket,
    zoom,
  },
  plugins: [
    websocketPlugin,
    // localStorage by default
    createPersistedState({
      // to fix storage incompatibility, use different name
      key: "v2",
      paths: ["account", "resizer", { table: ["gridApi", "gridColumnApi"] }],
    }),
  ],
});

export { store as default };
