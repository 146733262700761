import { datadogRum } from "@datadog/browser-rum";
import router from "@/router";
import { NOTIFICATION_TYPE, USER } from "@/utils/constants";
import api from "@/api";

const couldNotProvideFeedback = (context, payload) => {
  context.dispatch("fetchQuestions", {
    projectId: payload.projectId,
    contentId: payload.contentId,
  });
  context.dispatch(
    "setNotification",
    {
      name: "Error",
      message: "Could not provide feedback.",
      type: NOTIFICATION_TYPE.ERROR,
    },
    { root: true }
  );
};

const logFeedbackAction = (action, payload) => {
  const value =
    payload?.payload?.is_positive === true
      ? "positive"
      : payload?.payload?.is_positive === false
        ? "negative"
        : null;
  datadogRum.addAction("feedback", {
    type: "assistant_response",
    value,
    reason: payload?.payload?.reason || null,
    action: action,
    accountId: router.currentRoute.value.params.accountId,
    projectId: router.currentRoute.value.params.projectId,
    contentId: router.currentRoute.value.params.contentId,
  });
};

const state = {
  query: "",
  initialStart: true,
  question: "",
  questionAnswers: [],
  activeQuestion: null,
  questionInFlight: false,
  isQuestionable: true,
  scrollQuestionLog: false,
  scrollChatLog: false,
};

const getters = {
  filteredQuestionAnswers(state) {
    return state.questionAnswers.filter((questionAnswer) =>
      questionAnswer.answer_parts
        .map((answerPart) => answerPart.text)
        .join(" ")
        .toLowerCase()
        .includes(state.query.toLowerCase())
    );
  },
};

const mutations = {
  setQuery(state, payload) {
    state.query = payload;
  },
  setInitialStart(state, payload) {
    state.initialStart = payload;
  },
  setQuestion(state, payload) {
    state.query = "";
    state.activeQuestion = null;
    state.question = payload;
  },
  setQuestionAnswers(state, payload) {
    state.questionAnswers = payload;
  },
  deleteQuestion(state, payload) {
    const idxToDelete = state.questionAnswers.findIndex((questionAnswer) => {
      return questionAnswer.uuid === payload;
    });
    state.questionAnswers.splice(idxToDelete, 1);
  },
  setActiveQuestion(state, payload) {
    state.activeQuestion = payload;
  },
  addQuestion(state, payload) {
    const questionAnswer = {
      uuid: null,
      question: payload.question,
      display_question: payload.question,
      question_source: USER,
      answer_parts: [],
      timestamp: Math.floor(new Date().getTime() / 1000.0),
      user_id: payload.email,
      is_resolved: false,
    };
    state.questionAnswers.push(questionAnswer);
  },
  setQuestionInFlight(state, payload) {
    state.questionInFlight = payload;
  },
  setIsQuestionable(state, payload) {
    state.isQuestionable = payload;
  },
  setScrollQuestionLog(state, payload) {
    state.scrollQuestionLog = payload;
  },
  setScrollChatLog(state, payload) {
    state.scrollChatLog = payload;
  },
};

const actions = {
  setQuery(context, payload) {
    context.commit("setQuery", payload);
  },
  setQuestion(context, payload) {
    context.commit("setQuestion", payload);
  },
  async fetchQuestions(context, payload) {
    const response = await api().get(
      `projects/${payload.projectId}/contents/${payload.contentId}/questions`
    );
    context.commit("setQuestionInFlight", false);
    context.commit("setIsQuestionable", response.data.metadata.is_questionable);
    context.commit("setQuestionAnswers", response.data.questions);
  },
  askQuestion(context, payload) {
    context.commit("setScrollQuestionLog", true);
    context.commit("setScrollChatLog", true);
    context.commit("setQuestionInFlight", true);
    context.commit("addQuestion", {
      question: payload.question,
      email: context.rootGetters.email,
    });
    context.dispatch("answerQuestion", payload);
  },
  answerQuestion(context, payload) {
    datadogRum.addAction("ask_question", {
      type: "assistant",
      accountId: router.currentRoute.value.params.accountId,
      projectId: payload.projectId,
      contentId: payload.contentId,
    });
    context.commit("setInitialStart", false);
    context.commit("setQuestion", "");
    api()
      .post(
        `projects/${payload.projectId}/contents/${payload.contentId}/questions`,
        { question: payload.question }
      )
      .catch(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not ask question.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
      });
  },
  deleteQuestion(context, payload) {
    context.commit("deleteQuestion", payload.question.uuid);
    api()
      .delete(
        `projects/${payload.projectId}/contents/${payload.contentId}/questions/${payload.question.uuid}`
      )
      .then(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Success",
            message: `Question deleted.`,
            type: NOTIFICATION_TYPE.SUCCESS,
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch("fetchQuestions", {
          projectId: payload.projectId,
          contentId: payload.contentId,
        });
        context.dispatch(
          "setNotification",
          {
            name: "Error",
            message: "Could not delete question.",
            type: NOTIFICATION_TYPE.ERROR,
          },
          { root: true }
        );
      });
  },
  setActiveQuestion(context, payload) {
    context.commit("setInitialStart", false);
    context.commit("setActiveQuestion", payload);
  },
  resetState(context) {
    context.commit("setQuery", "");
    context.commit("setInitialStart", true);
    context.commit("setQuestion", "");
    context.commit("setQuestionAnswers", []);
    context.commit("setActiveQuestion", null);
    context.commit("setQuestionInFlight", false);
    context.commit("setIsQuestionable", true);
  },
  setScrollQuestionLog(context, payload) {
    context.commit("setScrollQuestionLog", payload);
  },
  setScrollChatLog(context, payload) {
    context.commit("setScrollChatLog", payload);
  },
  createFeedback(context, payload) {
    logFeedbackAction("created", payload);
    api()
      .post(
        `projects/${payload.projectId}/contents/${payload.contentId}/questions/${payload.uuid}/feedbacks`,
        payload.payload
      )
      .then(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Response submitted!",
            message: `Your feedback will be used to improve the Assistant.`,
            type: NOTIFICATION_TYPE.SUCCESS,
          },
          { root: true }
        );
      })
      .catch(() => {
        couldNotProvideFeedback(context, payload);
      });
  },
  updateFeedback(context, payload) {
    logFeedbackAction("updated", payload);
    api()
      .put(
        `projects/${payload.projectId}/contents/${payload.contentId}/questions/${payload.uuid}/feedbacks`,
        payload.payload
      )
      .then(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Response submitted!",
            message: `Your feedback will be used to improve the Assistant.`,
            type: NOTIFICATION_TYPE.SUCCESS,
          },
          { root: true }
        );
      })
      .catch(() => {
        couldNotProvideFeedback(context, payload);
      });
  },
  deleteFeedback(context, payload) {
    logFeedbackAction("deleted", payload);
    api()
      .delete(
        `projects/${payload.projectId}/contents/${payload.contentId}/questions/${payload.uuid}/feedbacks`
      )
      .then(() => {
        context.dispatch(
          "setNotification",
          {
            name: "Success",
            message: `Feedback deleted.`,
            type: NOTIFICATION_TYPE.SUCCESS,
          },
          { root: true }
        );
      })
      .catch(() => {
        couldNotProvideFeedback(context, payload);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
