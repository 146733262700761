import api from "@/api";
import { managementApi } from "@/api";
import { NOTIFICATION_TYPE } from "@/utils/constants";

const state = {
  assignee: null,
  users: [],
  usersLoading: true,
};

const getters = {
  assignee(state) {
    return state.assignee;
  },
  users(state) {
    return state.users;
  },
  usersLoading(state) {
    return state.usersLoading;
  },
};

const mutations = {
  setAssignee(state, payload) {
    state.assignee = payload;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  setUsersLoading(state, payload) {
    state.usersLoading = payload;
  },
};

const actions = {
  setAssignee(context, payload) {
    context.commit("setAssignee", payload);
  },
  addDocumentAssignment(context, payload) {
    return api()
      .post(
        `projects/${payload.projectId}/contents/${payload.contentId}/assignee`,
        { username: payload.username }
      )
      .then((response) => {
        context.dispatch("setAssignee", response.data.assignee);
        if (payload.showSuccessMessage) {
          context.dispatch("setNotification", {
            name: "Success",
            type: NOTIFICATION_TYPE.SUCCESS,
            message: `Document assigned to ${payload.username}`,
          });
        }
      })
      .catch(() => {
        context.dispatch("setNotification", {
          name: "Failure",
          message: `Sorry, there was error. Could not assign document.`,
          type: NOTIFICATION_TYPE.ERROR,
        });
      });
  },
  removeDocumentAssignment(context, payload) {
    return api()
      .delete(
        `projects/${payload.projectId}/contents/${payload.contentId}/assignee`,
        { username: payload.username }
      )
      .then((response) => {
        context.dispatch("setAssignee", response.data.assignee);
        if (payload.showSuccessMessage) {
          context.dispatch("setNotification", {
            name: "Success",
            type: NOTIFICATION_TYPE.SUCCESS,
            message: `${payload.username} no longer assigned.`,
          });
        }
      })
      .catch(() => {
        context.dispatch("setNotification", {
          name: "Failure",
          message: `Sorry, there was error. Could not remove assignment.`,
          type: NOTIFICATION_TYPE.ERROR,
        });
      });
  },
  getUsers(context, payload) {
    managementApi()
      .get(`/accounts/${payload.accountID}/projects/${payload.projectId}/users`)
      .then((response) => {
        context.commit("setUsers", response.data);
        context.commit("setUsersLoading", false);
      });
  },
  setUsersLoading(context, payload) {
    context.commit("setUsersLoading", payload);
  },
  setUsers(context, payload) {
    context.commit("setUsers", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
