<template>
  <svg
    :class="{
      'w-3 h-3': size == 'xxsmall',
      'w-4 h-4': size === 'xsmall',
      'w-5 h-5': size === 'small',
      'w-12 h-12': size === 'medium',
      'w-24 h-24': size === 'large',
    }"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke-width="strokeWidth"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
</template>

<script>
export default {
  props: {
    strokeWidth: { type: String, default: "2" },
    size: { type: String, default: "small" },
  },
};
</script>
