import { datadogRum } from "@datadog/browser-rum";
import router from "@/router";
import api from "@/api";
import pako from "pako";

const getWords = (result, substring, wholeWords) => {
  const words = result.text.split(/\s+/);
  const substringWords = substring.split(/\s+/);
  const indices = [];
  for (let i = 0; i < words.length - substringWords.length + 1; i++) {
    const currentWords = words.slice(i, i + substringWords.length);
    const isMatch = wholeWords
      ? currentWords.join(" ") === substring
      : currentWords.join(" ").includes(substring);
    if (isMatch) {
      const indexRange = Array.from(
        { length: substringWords.length },
        (_, j) => i + j
      );
      indices.push(indexRange);
    }
  }
  return indices.map((indice) =>
    indice.map((i) => ({ ...result.words[i], page_number: result.page_number }))
  );
};

// state
const state = {
  searchIndexReady: false,
  searchIndex: null,
  searchTerm: null,
  searchTotal: null,
  searchResults: [],
  currentResultIndex: 0,
  wholeWords: false,
  caseSensitive: false,
  percentDownloaded: 0,
};

// mutations
const mutations = {
  setSearchIndexReady(state, payload) {
    state.searchIndexReady = payload;
  },
  setSearchIndex(state, payload) {
    state.searchIndex = payload;
  },
  setSearchTerm(state, payload) {
    state.searchTerm = payload;
  },
  setSearchTotal(state, payload) {
    state.searchTotal = payload;
  },
  setSearchResults(state, payload) {
    state.searchResults = payload;
  },
  setCurrentResultIndex(state, payload) {
    state.currentResultIndex = payload;
  },
  setWholeWords(state, payload) {
    state.wholeWords = payload;
  },
  setCaseSensitive(state, payload) {
    state.caseSensitive = payload;
  },
  setPercentDownloaded(state, payload) {
    state.percentDownloaded = payload;
  },
};

// actions
const actions = {
  async fetchSearchIndex(context, payload) {
    context.commit("setSearchIndexReady", false);
    api({ interceptError: false })
      .get(
        `projects/${payload.projectId}/contents/${payload.contentId}/search-index`,
        {
          responseType: "arraybuffer",
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            context.commit("setPercentDownloaded", percentCompleted);
          },
          custom404Handler: true,
        }
      )
      .then((response) => {
        if (response.data instanceof ArrayBuffer) {
          const decompressedData = pako.inflate(response.data, {
            to: "string",
          });
          context.commit("setSearchIndex", JSON.parse(decompressedData));
        } else {
          context.commit("setSearchIndex", response.data);
        }
        context.commit("setSearchIndexReady", true);
      })
      .catch(() => {
        context.dispatch("searchIndex/setUseClientSearch", false, {
          root: true,
        });
      });
  },
  setSearchTerm(context, payload) {
    context.commit("setSearchTerm", payload);
  },
  doSearch(context) {
    datadogRum.addAction("search", {
      type: "document",
      method: "client",
      accountId: router.currentRoute.value.params.accountId,
      projectId: router.currentRoute.value.params.projectId,
      contentId: router.currentRoute.value.params.contentId,
    });
    if (context.state.searchTerm && context.state.searchTerm.length > 1) {
      let searchTerm;
      let filteredSearchIndex;
      if (context.state.caseSensitive) {
        searchTerm = context.state.searchTerm;
        filteredSearchIndex = context.state.searchIndex.filter((t) =>
          t.text.includes(searchTerm)
        );
      } else {
        searchTerm = context.state.searchTerm.toLowerCase();
        const lowerCaseSearchIndex = context.state.searchIndex.map((t) => ({
          ...t,
          text: t.text.toLowerCase(),
        }));
        filteredSearchIndex = lowerCaseSearchIndex.filter((t) =>
          t.text.includes(searchTerm)
        );
      }
      const words = filteredSearchIndex.flatMap((result) =>
        getWords(result, searchTerm, context.state.wholeWords)
      );
      let currentIndex = 0;
      if (context.rootState.documentData.activePage > 1) {
        // find the index of the first word on the current page
        currentIndex = words.findIndex(
          (word) =>
            word[0].page_number >= context.rootState.documentData.activePage
        );
        if (currentIndex === -1) {
          currentIndex = words.length - 1;
        }
      }
      context.commit("setSearchResults", words);
      context.commit("setSearchTotal", words.length);
      context.commit("setCurrentResultIndex", currentIndex);
    } else {
      context.commit("setSearchResults", []);
      context.commit("setSearchTotal", null);
      context.commit("setCurrentResultIndex", 0);
    }
  },
  setCurrentResultIndex(context, payload) {
    context.commit("setCurrentResultIndex", payload);
  },
  resetSearch(context) {
    context.commit("setSearchTerm", null);
    context.commit("setSearchTotal", null);
    context.commit("setSearchResults", []);
    context.commit("setCurrentResultIndex", 0);
  },
  setWholeWords(context, payload) {
    context.commit("setWholeWords", payload);
    context.dispatch("doSearch");
  },
  setCaseSensitive(context, payload) {
    context.commit("setCaseSensitive", payload);
    context.dispatch("doSearch");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
