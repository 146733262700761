import {
  TAG,
  ASSOCIATION,
  HEADER,
  ENTITY,
  NOTIFICATION_TYPE,
} from "@/utils/constants";
import { defaultsDeep as _defaultsDeep } from "lodash-es";
import store from "@/store";

// recursively add _treeIdx and  _path to each object in the block tree
const addTreeIdxPathToNode = (obj, path = [], treeIdx) => {
  if (
    obj &&
    (obj.block_type ||
      obj._type === TAG ||
      obj._type === ASSOCIATION ||
      obj._type === HEADER)
  ) {
    obj._treeIdx = treeIdx;
    obj._path = path.join(".");
  }
  if (obj && obj.children && obj.children.length > 0) {
    obj.children.forEach((child, index) => {
      if (Array.isArray(child)) {
        // Handle nested arrays
        child.forEach((nestedChild, nestedIndex) => {
          const newPath = path.concat([`children[${index}][${nestedIndex}]`]);
          addTreeIdxPathToNode(nestedChild, newPath, treeIdx);
        });
      } else {
        const newPath = path.concat([`children[${index}]`]);
        addTreeIdxPathToNode(child, newPath, treeIdx);
      }
    });
  }
};

export const amendBlockTree = (tree) => {
  return tree.map((item, treeIdx) => {
    addTreeIdxPathToNode(item, [], treeIdx);
    return item;
  });
};

export function createNewLabeledBlock(block = {}) {
  const newLabels = block.labels
    ? block.labels.map(({ label, confidence = 1, is_verified = true } = {}) => {
        if (!label) {
          throw Error("Need a label id to create this block.");
        }
        return { label, confidence, is_verified };
      })
    : [];

  const defaultBlock = {
    block_type: ENTITY,
    bounding_box: null,
    page_number: 1,
    labels: newLabels,
    is_verified: true,
    children: [],
    text: "",
    formatted_value: "",
  };
  return _defaultsDeep({}, block, defaultBlock);
}

export function calculateBlockBoundingBox(words) {
  return {
    x_max: Math.max(...words.map((w) => w.bounding_box.x_max)),
    x_min: Math.min(...words.map((w) => w.bounding_box.x_min)),
    y_max: Math.max(...words.map((w) => w.bounding_box.y_max)),
    y_min: Math.min(...words.map((w) => w.bounding_box.y_min)),
  };
}

export async function useSuccessNotifications(asyncFn, options = {}) {
  _defaultsDeep(options, {
    successMessage: "Success!",
    errorMessage: "An Unknown Error Occurred",
  });
  try {
    await asyncFn();
    await store.dispatch("setNotification", {
      name: "Success",
      type: NOTIFICATION_TYPE.SUCCESS,
      message: options.successMessage,
    });
  } catch (e) {
    store.dispatch("setNotification", {
      name: "Error",
      type: NOTIFICATION_TYPE.ERROR,
      message: options.errorMessage,
    });
  }
}

export const lastIndexRegex = /\[(\d+)\](?!.*\[\d+\])/;
export function getLastIndexInPath(path) {
  return path.match(lastIndexRegex)?.[1];
}
