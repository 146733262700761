import { round } from "lodash-es";

// state
const state = {
  zoomLevel: 1,
};

// getters
const getters = {
  zoomLevel(state) {
    return round(state.zoomLevel, 1);
  },
};

// mutations
const mutations = {
  setZoomLevel(state, payload) {
    state.zoomLevel = payload;
  },
};

// actions
const actions = {
  setZoomLevel(context, payload) {
    context.commit("setZoomLevel", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
