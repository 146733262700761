import api from "@/api";
import router from "@/router";
import { datadogRum } from "@datadog/browser-rum";
import { get as _get, set as _set, reject as _reject } from "lodash-es";
import {
  flattenSections,
  getAllSectionUuids,
  getSectionUuids,
} from "@/utils/docIQ/bookmarks";
import {
  convertIndexesToPath,
  getIndexesFromPath,
} from "@/utils/docIQ/bookmarks";

const state = {
  isLoading: false,
  outline: [],
  editingSections: false,
  newSectionPath: "",
  expandedItems: new Set(),
};

const getters = {
  outline(state, _getters, _rootState, rootGetters) {
    function formatOutline(sections, path = "") {
      return sections.map((section, i) => {
        const currentPath = path + `[${i}]`;
        if (section.uuid === "temp") {
          return {
            ...section,
            path: currentPath,
            children: null,
          };
        }

        const block = rootGetters["docIQ/blocks"].find(
          (block) =>
            block.uuid === section.uuid &&
            block.labels.some(({ label }) => label === "bookmark")
        );

        return {
          ...section,
          path: currentPath,
          formatted_value: block.formatted_value,
          labels: block.labels,
          children: section.children
            ? formatOutline(section.children, currentPath + ".children")
            : null,
        };
      });
    }

    return formatOutline(state.outline);
  },
  flattenedSections(state, getters) {
    return flattenSections(getters.outline);
  },
  sectionIsExpanded: (state) => (uuid) => {
    return state.expandedItems.has(uuid);
  },
  outlineLabel(_state, getters) {
    return getters.bookmarkData.children.find(
      (label) => label.id === "bookmark"
    );
  },
  bookmarkData(_state, _getters, rootState) {
    return (
      rootState.docIQ.blockTree.find(
        (module) => module.id === "bookmark_module"
      ) ?? { children: [] }
    );
  },
  bookmarkCategories(_state, getters) {
    return getters.bookmarkData.children.map(({ id, name, _path }) => ({
      id,
      name,
      _path,
    }));
  },
};

const mutations = {
  setOutline(state, payload) {
    state.outline = payload;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setEditingSections(state, isEditing) {
    state.editingSections = isEditing;
  },
  setNewSectionPath(state, path) {
    state.newSectionPath = path;
  },
  setExpandedItems(state, items) {
    state.expandedItems = items;
  },
  toggleItem(state, uuid) {
    if (state.expandedItems.has(uuid)) {
      state.expandedItems.delete(uuid);
    } else {
      state.expandedItems.add(uuid);
    }
  },
};

const actions = {
  // expanding sections
  toggleItem(context, uuid) {
    context.commit("toggleItem", uuid);
  },
  collapseAllItems(context) {
    context.commit("setExpandedItems", new Set());
  },
  expandAllItems(context) {
    const uuids = getAllSectionUuids(context.state.outline);
    context.commit("setExpandedItems", new Set(uuids));
  },
  expandItems(context, uuids) {
    context.commit("setExpandedItems", new Set(uuids));
  },
  // Editing Sections
  addTempSection(context, { path }) {
    context.dispatch("docIQ/setActiveBlock", null, { root: true });
    if (!context.state.editingSections) {
      context.commit("setNewSectionPath", path);
      const indexes = getIndexesFromPath(path);
      const target = convertIndexesToPath(indexes.slice(0, -1));
      const index = indexes.at(-1);
      if (target.length === 0) {
        const updated = context.state.outline.toSpliced(index, 0, {
          uuid: "temp",
        });
        context.commit("setOutline", updated);
      } else {
        const list = _get(context.state.outline, target) || [];
        const updated = list.toSpliced(index, 0, { uuid: "temp" });
        const updatedOutline = _set(context.state.outline, target, updated);
        context.commit("setOutline", updatedOutline);
      }
      context.commit("setEditingSections", true);
    }
  },
  stopEditingSections(context) {
    if (context.state.newSectionPath !== "") {
      const indexes = getIndexesFromPath(context.state.newSectionPath);
      const index = indexes.at(-1);
      const target = convertIndexesToPath(indexes.slice(0, -1));
      if (target === "") {
        const updated = context.state.outline.toSpliced(index, 1);
        context.commit("setOutline", updated);
      } else {
        const list = _get(context.state.outline, target);
        const updated = list.toSpliced(index, 1);
        const updatedOutline = _set(context.state.outline, target, updated);
        context.commit("setOutline", updatedOutline);
      }
      context.commit("setNewSectionPath", "");
      context.commit("setEditingSections", false);
    }
  },
  // Api calls
  async getOutline(context, { projectId, contentId }) {
    context.commit("setIsLoading", true);

    const { data } = await api().get(
      `projects/${projectId}/contents/${contentId}/bookmark-outline`
    );

    context.commit("setOutline", data);
    context.commit("setIsLoading", false);
  },
  async addSection(context, { uuid, path, projectId, contentId }) {
    const { data: newOutline } = await api().post(
      `projects/${projectId}/contents/${contentId}/bookmark-outline`,
      {
        uuid,
        path,
      }
    );
    context.commit("setOutline", newOutline);
    datadogRum.addAction("add_bookmark", {
      accountId: router.currentRoute.value.params.accountId,
      projectId,
      contentId,
    });
  },
  async deleteSection(context, { section, target, projectId, contentId }) {
    const url = `projects/${projectId}/contents/${contentId}/bookmark-outline`;
    const query = `?path=${section.path}&target=${target}`;
    const { data: newOutline } = await api().delete(url + query);

    const deletedUuids = getSectionUuids(
      context.getters.outline,
      section.path,
      target
    );

    const blocks = context.rootGetters["docIQ/blocks"].filter((block) =>
      deletedUuids.includes(block.uuid)
    );

    context.commit("setOutline", newOutline);
    await context.dispatch(
      "docIQ/deleteBlocks",
      {
        projectId,
        contentId,
        blocks,
      },
      { root: true }
    );
    datadogRum.addAction("delete_bookmark", {
      accountId: router.currentRoute.value.params.accountId,
      projectId,
      contentId,
    });
  },
  async addLabel(context, { projectId, contentId, section, tag }) {
    const block = context.rootGetters["docIQ/blocks"].find(
      (block) => block.uuid === section.uuid
    );
    const newBlock = {
      ...block,
      is_verified: true,
      labels: [
        ...block.labels,
        { label: tag.id, confidence: 1, is_verified: true },
      ],
    };

    await context.dispatch(
      "docIQ/updateBlock",
      {
        projectId,
        contentId,
        block: newBlock,
        showNotification: false,
      },
      { root: true }
    );
  },
  async removeLabel(context, { projectId, contentId, section, tag }) {
    const block = context.rootGetters["docIQ/blocks"].find(
      (block) => block.uuid === section.uuid
    );

    block.labels = _reject(block.labels, (label) => label.label === tag.id);

    await context.dispatch(
      "docIQ/updateBlock",
      {
        projectId,
        contentId,
        block,
        showNotification: false,
      },
      { root: true }
    );
  },
  setOutlineToEmpty(context) {
    context.commit("setOutline", []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
