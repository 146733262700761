<template>
  <div>
    <app-container>
      <div class="mx-auto text-center">
        <div class="mt-16 text-6xl text-gray-500">
          {{ errorCode }}
        </div>
        <div class="text-gray-400">
          {{ errorMessage }}
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
import Container from "@/components/layout/Container.vue";

export default {
  components: {
    "app-container": Container,
  },

  computed: {
    errorCode() {
      return this.$store.getters.displayError;
    },
    errorMessage() {
      let messageMap = { 403: "Forbidden.", 404: "Not found." };
      if (this.errorCode in messageMap) {
        return messageMap[this.errorCode];
      } else {
        return `An error occurred with code ${this.errorCode}`;
      }
    },
  },
};
</script>
