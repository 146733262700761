import { cloneDeep } from "lodash-es";
import {
  ASSOCIATION,
  CLASSIFICATION,
  TAG,
  HEADER,
  DOCUMENT,
  PAGE,
} from "@/utils/constants";

// recursive function
export const forEachNode = (f, blockTree, hierarchy = []) => {
  blockTree.forEach((node, idx) => {
    const _index = [...hierarchy, idx].join("-");
    f(node, _index);
    if (Array.isArray(node.children) && node._type) {
      if (node._type === ASSOCIATION) {
        node.children.forEach((child, childIdx) => {
          forEachNode(f, child, [...hierarchy, idx, childIdx]);
        });
      } else {
        forEachNode(f, node.children, [...hierarchy, idx]);
      }
    }
  });
};

export const getInvalids = (tree) => {
  let result = [];
  forEachNode((node) => {
    if (node.id && node._isValid !== undefined && !node._isValid) {
      result.push(node);
    }
  }, tree);
  return result;
};

export const getBlocks = (tree) => {
  let result = [];
  forEachNode((node) => {
    if (!node._type && node.uuid) {
      if (![DOCUMENT, PAGE].includes(node.block_type)) {
        result.push(node);
      }
    }
  }, tree);
  return result;
};

export const dedupeBlocks = (blocks) => {
  // Find duplicate block UUIDs
  const duplicateUUIDs = blocks
    .map((block) => block.uuid)
    .filter((uuid, index, arr) => arr.indexOf(uuid) !== index);
  if (duplicateUUIDs.length > 0) {
    const uniqueBlocks = {};
    const result = [];
    // Remove duplicate labels from duplicate blocks
    duplicateUUIDs.forEach((blockUUID) => {
      const duplicateBlocks = blocks.filter(
        (block) => block.uuid === blockUUID
      );
      const labels = duplicateBlocks.flatMap((block) => block.labels);
      duplicateBlocks[0].labels = labels;
    });
    // Filter out duplicates and return unique blocks
    blocks.forEach((block) => {
      if (!uniqueBlocks[block.uuid]) {
        uniqueBlocks[block.uuid] = true;
        result.push(block);
      }
    });
    return result;
  } else {
    return blocks;
  }
};

// recursive function
export const getLabels = (input) => {
  return input.reduce((result, item) => {
    if (item._type) {
      const { children, name, id, _type } = item;
      const transformedItem = {
        children: [],
        name,
        id,
        _type,
      };
      if (children && children.length > 0) {
        if (_type === ASSOCIATION) {
          transformedItem.children = getLabels(children[children.length - 1]);
        } else {
          transformedItem.children = getLabels(children);
        }
      }
      result.push(transformedItem);
    }
    return result;
  }, []);
};

export const buildPath = (index, fullTree) => {
  const indexes = index.split("-").map(Number);
  let path = `[${indexes[0]}]`;
  for (let idx of indexes.slice(1)) {
    let node = getInnerNode(fullTree, path);
    path += node.children ? `.children[${idx}]` : `[${idx}]`;
  }
  return path;
};

export const getInnerNode = (fullTree, path) => {
  const segments = path.match(/\w+/g);
  let node = fullTree;
  for (const segment of segments) {
    node = node[segment];
  }
  return node;
};

export const findNodeByLabel = (tree, label) => {
  const StopIteration = Symbol("StopIteration");
  let result = null;
  try {
    forEachNode((node) => {
      if (node.id === label) {
        result = node;
        throw StopIteration;
      }
    }, tree);
  } catch (e) {
    if (e !== StopIteration) {
      throw e;
    }
  }
  return result;
};

export const findAllNodesByLabel = (tree, label) => {
  let result = [];
  forEachNode((node) => {
    if (node.id === label) {
      result.push(node);
    }
  }, tree);
  return result;
};

export const findNodeByIndex = (tree, index) => {
  const StopIteration = Symbol("StopIteration");
  let result = null;
  try {
    forEachNode((node) => {
      if (node._index === index) {
        result = node;
        throw StopIteration;
      }
    }, tree);
  } catch (e) {
    if (e !== StopIteration) {
      throw e;
    }
  }
  return result;
};

export const getReplaceIndex = (innerNode, block) => {
  return innerNode.children.findIndex((item) => {
    if (item.page_number > block.page_number) {
      return true;
    } else if (item.page_number === block.page_number) {
      if (block.bounding_box.y_min < item.bounding_box.y_min) {
        return true;
      } else if (
        block.bounding_box.y_min === item.bounding_box.y_min &&
        block.bounding_box.x_min < item.bounding_box.x_min
      ) {
        return true;
      }
      return false;
    } else if (item.page_number < block.page_number) {
      return false;
    }
  });
};

export const filterTree = (tree, searchTerm) => {
  forEachNode((node) => {
    if (!node._type) {
      let shouldHide;
      if (node.formatted_value) {
        shouldHide = !node.formatted_value.toLowerCase().includes(searchTerm);
      } else {
        shouldHide = true;
      }
      node._hide = shouldHide;
    }
  }, tree);
  return tree;
};

export const classificationLabelInUse = (label) => {
  return (
    (label._showLabel == undefined && label.children.length > 0) ||
    label._showLabel
  );
};

export const isValid = (node) => {
  let childCount;
  if (node._type === CLASSIFICATION) {
    childCount = node.children.flatMap((c) => c.children).length;
  } else {
    childCount = node.children.length;
  }
  const meetsMin = node.min_blocks <= childCount;
  const meetsMax = node.max_blocks === null || node.max_blocks >= childCount;
  let childInvalid = false;
  if (node._type === TAG) {
    node.children.forEach((c) => {
      const isVerified = !c.labels.some((l) => l.is_verified === false);
      if (!isVerified) childInvalid = true;
      c._isVerified = isVerified;
    });
  }
  return meetsMin && meetsMax && !childInvalid;
};

export const validateTree = (tree) => {
  let tagsToSkip = [];
  forEachNode((node, _index) => {
    if (node.id) {
      node._index = _index;
    }
    if (node._type === CLASSIFICATION) {
      node.children.forEach((child) => {
        child._classification = {
          id: node.id,
          allow_multiple: node.allow_multiple,
          name: node.name,
          labels: node.children.map((c) => ({ id: c.id, name: c.name })),
        };
        tagsToSkip.push(child.id);
        if (classificationLabelInUse(child)) {
          child._isValid = isValid(child);
        }
      });
    }
    if (node._type && !tagsToSkip.includes(node.id)) {
      node._isValid = isValid(node);
    }
  }, tree);
  return tree;
};

export const revalidateTree = (tree) => {
  forEachNode((node) => {
    let requiresAttn;
    if (node._type === TAG) return;
    if (node._type === HEADER || node._type === ASSOCIATION) {
      requiresAttn = JSON.stringify(node.children).includes('"_isValid":false');
    }
    if (node._type === CLASSIFICATION) {
      const children = node.children.filter((c) => classificationLabelInUse(c));
      requiresAttn = children.some((c) => !c._isValid);
    }
    node._requiresAttention = requiresAttn;
  }, tree);
  return tree;
};

export const getGroupsAndIdx = (fullPath, fullTree) => {
  const matches = fullPath.match(/\[[0-9]+\]/g);
  if (matches && matches.length > 0) {
    const lastMatch = matches[matches.length - 1];
    const lastIndex = fullPath.lastIndexOf(lastMatch);
    if (lastIndex !== -1) {
      const pathToParent = fullPath.substring(0, lastIndex);
      const groupString = fullPath.substring(lastIndex);
      let group = getInnerNode(fullTree, pathToParent);
      const match = groupString.match(/\d+/);
      if (match) {
        const groupIdx = parseInt(match[0], 10);
        return { group, groupIdx };
      }
    }
  }
};

export const stripGroup = (data) => {
  const newData = cloneDeep(data);
  forEachNode((node) => {
    if (node._type === TAG) {
      node.children = [];
    }
    if (node._type === ASSOCIATION) {
      node.children = [node.children[0]];
    }
  }, newData);
  return newData;
};

export const getInputIndex = (index, fullTree, block) => {
  const path = buildPath(index, fullTree);
  let innerNode = getInnerNode(fullTree, path);
  let inputIndex = getReplaceIndex(innerNode, block);
  if (inputIndex === -1) {
    inputIndex = innerNode.children.length;
  }
  return inputIndex;
};

export const buildTreeIndexAndPath = (index, fullTree, inputIndex) => {
  const treeIndex = index.split("-")[0];
  let path = buildPath(index, fullTree).split(".").slice(1).join(".");
  path = `${path}.children[${inputIndex}]`;
  return { treeIndex, path };
};

export const buildTreeIndexAndPathForAssociation = (
  index,
  fullTree,
  inputIndex
) => {
  const treeIndex = index.split("-")[0];
  const segments = buildPath(index, fullTree).split(".").slice(1, -1);
  const path = segments.length
    ? `${segments.join(".")}.children[${inputIndex}]`
    : `children[${inputIndex}]`;
  return { treeIndex, path };
};

export const getIdxToDelete = (blocks, activeLabel) => {
  let idx;
  const indexes = blocks.map((b) =>
    activeLabel.children.findIndex((c) => c.uuid === b.uuid)
  );
  if (indexes.length === 1 && indexes[0] === -1) {
    idx = `0:${indexes.length}`;
  }
  if (indexes.length === 1 && indexes[0] !== -1) {
    idx = `${indexes[0]}:${indexes[0] + 1}`;
  }
  if (indexes.length > 1) {
    const firstItem = indexes[0];
    const lastItem = indexes[indexes.length - 1];
    idx = `${firstItem}:${lastItem + 1}`;
  }
  return idx;
};

export const scrollToPage = (page, behavior = "instant") => {
  let element = document.getElementById(`image-${page}`);
  if (element) {
    element.scrollIntoView({
      block: "start",
      behavior,
    });
  }
};

export const optimisticRemoveLabelFromBlock = (blocks, innerNode) => {
  blocks.forEach((block) => {
    const idx = innerNode.children.findIndex((c) => c.uuid === block.uuid);
    innerNode.children.splice(idx, 1);
  });
};

export const optimisticAddLabel = (
  tree,
  treeIndex,
  path,
  blocks,
  inputIndex
) => {
  const propertyKeys = path.split(".");
  let currentProperty = tree[treeIndex];
  propertyKeys.forEach((propertyKey, index) => {
    const nestedKeys = propertyKey.split(/[[\].]+/).filter(Boolean);
    nestedKeys.forEach((nestedKey, nestedIndex) => {
      // Check if the current nested key in the property path does not exist in the current property,
      // or if we have reached the last nested key and the last property key in their respective arrays.
      // If either condition is true, it means we have reached the target property and need to insert the label blocks.
      if (
        currentProperty[nestedKey] === undefined ||
        (nestedIndex === nestedKeys.length - 1 &&
          index === propertyKeys.length - 1)
      ) {
        blocks.forEach((block) => {
          currentProperty.splice(inputIndex, 0, block);
        });
      } else {
        currentProperty = currentProperty[nestedKey];
      }
    });
  });
};

export const optimisticUpdateBlock = (blocks, fullTree) => {
  blocks.forEach((block) => {
    let label = block.labels[0].label;
    let innerNode = findNodeByLabel(fullTree, label);
    const idx = innerNode.children.findIndex((c) => c.uuid === block.uuid);
    innerNode.children[idx] = block;
  });
};

export const hideLabel = (node, searchTerm) => {
  if (!searchTerm) return false;
  return !hasFalseValueLabel(node);
};

export const hasFalseValueLabel = (node) => {
  let found = false;
  forEachNode(
    (n) => {
      if (!n._type && n._hide === false) {
        found = true;
        return; // Exit loop early if a false value is found
      }
    },
    [node]
  );
  return found;
};
