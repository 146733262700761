<template>
  <div>
    <div>
      <app-logo-horizontal class="w-auto h-12" />
    </div>
  </div>
</template>

<script>
import AppLogoHorizontal from "@/components/layout/logos/LogoHorizontal.vue";

export default {
  components: {
    AppLogoHorizontal,
  },
};
</script>
