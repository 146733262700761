import { NOTIFICATION_TYPE } from "@/utils/constants";

// state
const state = {
  documentIdsForDeletion: [],
  documentIdsForReview: [],
  documentIdsForNotReviewed: [],
  documentIdsForProcessing: [],
  documentIdsForExport: [],
  documentIdsForAssigning: [],
  documentNamesForOverwrite: [],
  documentDeletedByUser: "",
};

// getters
const getters = {
  documentIdsForDeletion(state) {
    return state.documentIdsForDeletion;
  },
  documentIdsForReview(state) {
    return state.documentIdsForReview;
  },
  documentIdsForNotReviewed(state) {
    return state.documentIdsForNotReviewed;
  },
  documentIdsForProcessing(state) {
    return state.documentIdsForProcessing;
  },
  documentNamesForOverwrite(state) {
    return state.documentNamesForOverwrite;
  },
  documentIdsForExport(state) {
    return state.documentIdsForExport;
  },
  documentIdsForAssigning(state) {
    return state.documentIdsForAssigning;
  },
  documentDeletedByUser(state) {
    return state.documentDeletedByUser;
  },
};

// mutations
const mutations = {
  setDocumentIdsForDeletion(state, payload) {
    state.documentIdsForDeletion = payload;
  },
  setDocumentIdsForReview(state, payload) {
    state.documentIdsForReview = payload;
  },
  setDocumentIdsForNotReviewed(state, payload) {
    state.documentIdsForNotReviewed = payload;
  },
  setDocumentIdsForProcessing(state, payload) {
    state.documentIdsForProcessing = payload;
  },
  setDocumentNamesForOverwrite(state, payload) {
    state.documentNamesForOverwrite = payload;
  },
  setDocumentIdsForExport(state, payload) {
    state.documentIdsForExport = payload;
  },
  setDocumentIdsForAssigning(state, payload) {
    state.documentIdsForAssigning = payload;
  },
  setDocumentDeletedByUser(state, payload) {
    state.documentDeletedByUser = payload;
  },
};

// actions
const actions = {
  exportDocument(context, payload) {
    const { contentId, projectId } = payload;
    context.dispatch("exportSelectedDocuments", {
      contentIds: [contentId],
      projectId,
    });
  },
  recommendDocumentRefresh(context, payload) {
    if (context.rootGetters.email !== payload.user_email) {
      context.dispatch("setNotification", {
        name: "Document Updated",
        message: `${payload.user_email} updated this document. You should refresh the page to see the latest version.`,
        type: NOTIFICATION_TYPE.INFO,
      });
    }
  },
  setDocumentIdsForDeletion(context, payload) {
    context.commit("setDocumentIdsForDeletion", payload);
  },
  setDocumentIdsForReview(context, payload) {
    context.commit("setDocumentIdsForReview", payload);
  },
  setDocumentIdsForNotReviewed(context, payload) {
    context.commit("setDocumentIdsForNotReviewed", payload);
  },
  setDocumentIdsForProcessing(context, payload) {
    context.commit("setDocumentIdsForProcessing", payload);
  },
  setDocumentNamesForOverwrite(context, payload) {
    context.commit("setDocumentNamesForOverwrite", payload);
  },
  setDocumentIdsForExport(context, payload) {
    context.commit("setDocumentIdsForExport", payload);
  },
  setDocumentIdsForAssigning(context, payload) {
    context.commit("setDocumentIdsForAssigning", payload);
  },
  setDocumentDeletedByUser(context, payload) {
    context.commit("setDocumentDeletedByUser", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
