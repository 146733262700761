import { PROJECT_TABS, SETTINGS_TABS } from "@/utils/constants";
import router from "@/router";

// state
const state = {
  windowWidth: null,
  windowHeight: null,
  isLoading: false,
  displayError: "",
  projectTabs: [
    PROJECT_TABS.DOCUMENTS,
    PROJECT_TABS.UPLOAD,
    PROJECT_TABS.EXPORTS,
    PROJECT_TABS.REPORTS,
    PROJECT_TABS.ALERTS,
    PROJECT_TABS.API_DOCS,
    PROJECT_TABS.LABEL_WIZARD,
  ],
  settingsTabs: [SETTINGS_TABS.PASSWORD, SETTINGS_TABS.ACCESS_KEYS],
};

// getters
const getters = {
  windowWidth(state) {
    return state.windowWidth;
  },
  windowHeight(state) {
    return state.windowHeight;
  },
  isLoading(state) {
    return state.isLoading;
  },
  displayError(state) {
    return state.displayError;
  },
  projectTabs(state) {
    return state.projectTabs;
  },
  configuredProjectTabs(state, getters) {
    let {
      isAdmin,
      isAccountManager,
      disabledProjectTabs,
      projectTabs,
      projectConfig: { tabs: projectConfigTabs },
    } = getters;

    let tabs = JSON.parse(JSON.stringify(projectTabs));
    const projectConfigTabRoutes = projectConfigTabs.reduce(
      (ac, projectConfigTab) => {
        ac.push(projectConfigTab.route);
        return ac;
      },
      []
    );
    for (let i = 0; i < tabs.length; i++) {
      // sort projectTabsCopy in order from projectConfigTabs
      const sortedIndex = projectConfigTabRoutes.indexOf(tabs[i].route);
      if (sortedIndex > -1 && sortedIndex != i) {
        const missplacedTab = tabs[sortedIndex];
        const currentTab = tabs[i];
        tabs[i] = missplacedTab;
        tabs[sortedIndex] = currentTab;
      }
    }

    if (isAdmin || isAccountManager(router.currentRoute.value.params?.accountId)) {
      tabs = tabs.concat(PROJECT_TABS.SETTINGS);
    }

    const disabledTabNames = disabledProjectTabs.map((tab) => tab.name);
    return tabs.filter((tab) => !disabledTabNames.includes(tab.name));
  },
  settingsTabs(state) {
    return state.settingsTabs;
  },
  configuredSettingsTabs: (state, getters) => {
    let { isAdmin, settingsTabs: tabs, disabledSettingsTabs } = getters;
    const accountId = router.currentRoute.value.params.accountId;

    if (isAdmin) tabs = tabs.concat(SETTINGS_TABS.STORAGE_PROFILES);

    const disabledTabNames = disabledSettingsTabs(accountId).map(
      (tab) => tab.name
    );
    return tabs.filter((tab) => !disabledTabNames.includes(tab.name));
  },
};

// mutations
const mutations = {
  setWindowWidth(state, payload) {
    state.windowWidth = payload;
  },
  setWindowHeight(state, payload) {
    state.windowHeight = payload;
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setDisplayError(state, payload) {
    state.displayError = payload;
  },
};

// actions
const actions = {
  setWindowWidth(context, payload) {
    context.commit("setWindowWidth", payload);
  },
  setWindowHeight(context, payload) {
    context.commit("setWindowHeight", payload);
  },
  setIsLoading(context, payload) {
    context.commit("setIsLoading", payload);
  },
  setDisplayError(context, payload) {
    context.commit("setDisplayError", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
