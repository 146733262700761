// state
const state = {
  notifications: [],
};

// getters
const getters = {
  notifications(state) {
    return state.notifications;
  },
};

// mutations
const mutations = {
  setNotification(state, payload) {
    if (payload.clearNotifications) {
      state.notifications = [];
      setTimeout(() => {
        state.notifications.push(payload);
      }, 0);
    } else {
      state.notifications.push(payload);
    }
  },
  clearNotification(state, payload) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== payload
    );
  },
  clearNotifications(state) {
    state.notifications = [];
  },
};

// actions
const actions = {
  setNotification(context, payload) {
    context.commit("setNotification", {
      id: Math.random().toString(36).substring(2, 11),
      ...payload,
    });
  },
  clearNotification(context, payload) {
    context.commit("clearNotification", payload);
  },
  clearNotifications(context) {
    context.commit("clearNotifications");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
