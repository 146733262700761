import {
  KEYCLOAK,
  PROJECT_TABS,
  QUERY_PARAMS_TO_DELETE,
} from "@/utils/constants";

import getConfigId from "@/utils/getConfigId.js";
import store from "@/store/index";

export const AuthGuard = async (to) => {
  const unguardedRoutes = ["Home"];
  if (unguardedRoutes.includes(to.name)) {
    return;
  }

  if (store.getters.isSignedIn) {
    if (to.hash !== "" && to.hash.startsWith("#state")) {
      return { path: to.path, hash: "", replace: true };
    }
    const existingParamsToRemove = Object.keys(to.query).filter((param) =>
      QUERY_PARAMS_TO_DELETE.includes(param)
    );

    if (existingParamsToRemove.length > 0) {
      let query = Object.assign({}, to.query);
      existingParamsToRemove.forEach((param) => delete query[param]);
      return { path: to.path, query: query, replace: true };
    } else {
      return;
    }
  } else {
    let idpHint = to.query.idpHint;
    store.dispatch("signIn", { idpHint });
  }
};

export const IsAdminGuard = async () => {
  const isAdmin = store.state.auth.keycloak.realmAccess.roles.includes(
    KEYCLOAK.ROLES.ADMIN
  );
  return isAdmin || { name: "Home" };
};

export const IsAccountAdminOrManagerGuard = async (to) => {
  const { accountId } = to.params;
  const isAccountAdmin = store.state.auth.keycloak.realmAccess.roles.includes(
    `IDP:Account-${accountId}:Admin`
  );
  const isAccountManager = store.state.auth.keycloak.realmAccess.roles.includes(
    `IDP:Account-${accountId}:Manager`
  );
  const isAdmin = store.state.auth.keycloak.realmAccess.roles.includes(
    KEYCLOAK.ROLES.ADMIN
  );

  isAccountAdmin || isAccountManager || isAdmin || { name: "Home" };
};

export const IsAccountManagerGuard = async (to) => {
  const { accountId } = to.params;
  store.getters.isAccountManager(accountId) || { name: "Home" };
};

export const RequireProjectConfig = async (to) => {
  const { accountId, projectId } = to.params;
  const toConfigId = getConfigId(accountId, projectId);
  if (store.getters.projectConfigId !== toConfigId) {
    await store.dispatch("fetchProjectConfig", {
      id: to.params.projectId,
      accountId,
    });
  }
};

export const DisabledRouteGuard = async (to) => {
  const disabledTab = store.getters.disabledProjectTabs.find(
    (tab) => tab.route === to.name
  );

  if (disabledTab?.name === PROJECT_TABS.DOCUMENTS.name) {
    const firstTab = store.getters.configuredProjectTabs[0];
    return {
      name: firstTab.route,
      replace: true,
      params: { ...to.params },
    };
  } else if (disabledTab) {
    return {
      name: "Disabled Feature",
      params: { ...to.params },
      replace: true,
    };
  }
};

export const DisabledSettingsGuard = async (to) => {
  const { accountId } = to.params;
  const disabledTab = store.getters
    .disabledSettingsTabs(accountId)
    .find((tab) => tab.route === to.name);

  if (disabledTab) {
    return {
      name: "Disabled Settings Feature",
      replace: true,
    };
  }
};
