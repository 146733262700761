import api, { skipResolvedError } from "@/api";
import { WORD, LINE, TEXT, IMAGE, TABLE } from "@/utils/constants";

const state = {
  activePage: 1,
  documentName: null,
  metaDataLoading: true,
  documentUUID: null,
  pages: [],
  pageCount: null,
  metaData: null,
  documentIsVerified: null,
  status: null,
  job: null,
  isFromJob: null,
  blockRequestsInFlight: new Set(),
  downloadedBlocks: new Set(),
  wordBlocks: [],
  lineBlocks: [],
  textBlocks: [],
  imageBlocks: [],
  tableBlocks: [],
  generateImagesStatus: null,
  generateImagesSuccess: null,
};

const getters = {
  wordBlocks: (state) => (page) => {
    return state.wordBlocks[page] || [];
  },
  lineBlocks: (state) => (page) => {
    return state.lineBlocks[page] || [];
  },
  textBlocks: (state) => (page) => {
    return state.textBlocks[page] || [];
  },
  imageBlocks: (state) => (page) => {
    return state.imageBlocks[page] || [];
  },
  tableBlocks: (state) => (page) => {
    return state.tableBlocks[page] || [];
  },
};

const mutations = {
  setActivePage(state, payload) {
    state.activePage = payload;
  },
  setDocumentName(state, payload) {
    state.documentName = payload;
  },
  setMetaDataLoading(state, payload) {
    state.metaDataLoading = payload;
  },
  setDocumentUUID(state, payload) {
    state.documentUUID = payload;
  },
  setPages(state, payload) {
    state.pages = payload;
  },
  setPageCount(state, payload) {
    state.pageCount = payload;
  },
  setMetaData(state, payload) {
    state.metaData = payload;
  },
  setDocumentIsVerified(state, payload) {
    state.documentIsVerified = payload;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  setJob(state, payload) {
    state.job = payload;
  },
  isFromJob(state, payload) {
    state.isFromJob = payload;
  },
  addDownloadedBlock(state, payload) {
    state.downloadedBlocks.add(payload);
  },
  addBlockRequestsInFlight(state, payload) {
    state.blockRequestsInFlight.add(payload);
  },
  removeBlockRequestsInFlight(state, payload) {
    state.blockRequestsInFlight.delete(payload);
  },
  resetBlocks(state) {
    state.wordBlocks = [];
    state.lineBlocks = [];
    state.textBlocks = [];
    state.imageBlocks = [];
    state.tableBlocks = [];
    state.blockRequestsInFlight = new Set();
    state.downloadedBlocks = new Set();
  },
  setWordBlocks(state, payload) {
    state.wordBlocks[payload.page] = payload.words;
  },
  setLineBlocks(state, payload) {
    state.lineBlocks[payload.page] = payload.lines;
  },
  setTextBlocks(state, payload) {
    state.textBlocks[payload.page] = payload.text;
  },
  setImageBlocks(state, payload) {
    state.imageBlocks[payload.page] = payload.text;
  },
  setTableBlocks(state, payload) {
    state.tableBlocks[payload.page] = payload.text;
  },
  setImageMetadata(state, payload) {
    state.generateImagesStatus = payload.generate_images_status;
    state.generateImagesSuccess = payload.generate_images_success;
  },
};

const actions = {
  setMetaDataLoading(context, payload) {
    context.commit("setMetaDataLoading", payload);
  },
  async setActivePage(context, payload) {
    context.commit("setActivePage", payload);
  },
  async fetchMetaData(context, { setLoading = true, projectId, contentId }) {
    context.commit("setDocumentName", null);
    context.commit("setMetaDataLoading", setLoading);
    await api()
      .get(`projects/${projectId}/contents/${contentId}/metadata`)
      .then(({ data }) => {
        context.commit("setDocumentName", data.document_name);
        context.commit("setDocumentUUID", data.document_uuid);
        context.commit("setPages", data.pages_metadata);
        context.commit("setPageCount", data.content_metadata.page_count);
        context.dispatch("setAssignee", data.assignee, {
          root: true,
        });
        context.commit("setMetaData", data.content_metadata);
        context.commit("setImageMetadata", data);
        context.dispatch("setStatusInfo", {
          document_is_verified: data.content_metadata.is_verified,
          status: data.status,
          job: data.job,
          is_from_job: data.is_from_job,
        });
        context.commit("setMetaDataLoading", false);
      }, skipResolvedError);
  },
  setStatusInfo(context, payload) {
    context.commit("setDocumentIsVerified", payload.document_is_verified);
    context.commit("setStatus", payload.status);
    context.commit("setJob", payload.job);
    context.commit("isFromJob", payload.is_from_job);
  },
  fetchImagesAndBlocks(context, payload) {
    if (context.rootState.rangeSlider.rangeSliderChanging) return;
    const activePage = parseInt(payload.page);
    const startPage = Math.max(1, activePage - 2);
    const endPage = Math.min(payload.pageCount, activePage + 2);
    for (let i = startPage; i <= endPage; i++) {
      context.dispatch(
        "documentImages/fetchImage",
        {
          page: i,
          contentId: payload.contentId,
          projectId: payload.projectId,
        },
        {
          root: true,
        }
      );
      context.dispatch("fetchBlocks", {
        page: i,
        contentId: payload.contentId,
        projectId: payload.projectId,
      });
    }
  },
  fetchBlocks(context, payload) {
    // fetch blocks if the blocks have not already been downloaded
    // and the blocks are in not in the process of being downloaded
    if (
      !context.state.downloadedBlocks.has(payload.page) &&
      !context.state.blockRequestsInFlight.has(payload.page)
    ) {
      context.dispatch("addBlockRequestsInFlight", payload.page);
      api()
        .get(
          `projects/${payload.projectId}/contents/${payload.contentId}/pages/${payload.page}/blocks?block_type=${WORD}&block_type=${LINE}&block_type=${TEXT}&block_type=${IMAGE}&block_type=${TABLE}`
        )
        .then((response) => {
          context.commit("setWordBlocks", {
            words: response.data[WORD],
            page: payload.page,
          });
          context.commit("setLineBlocks", {
            lines: response.data[LINE],
            page: payload.page,
          });
          context.commit("setTextBlocks", {
            text: response.data[TEXT],
            page: payload.page,
          });
          context.commit("setImageBlocks", {
            text: response.data[IMAGE],
            page: payload.page,
          });
          context.commit("setTableBlocks", {
            text: response.data[TABLE],
            page: payload.page,
          });
          context.dispatch("removeBlockRequestsInFlight", payload.page);
          context.commit("addDownloadedBlock", payload.page);
        }, skipResolvedError)
        .catch(() => {
          context.dispatch("removeBlockRequestsInFlight", payload.page);
        });
    }
  },
  resetBlocks(context) {
    context.commit("resetBlocks");
  },
  addBlockRequestsInFlight(context, payload) {
    context.commit("addBlockRequestsInFlight", payload);
  },
  removeBlockRequestsInFlight(context, payload) {
    context.commit("removeBlockRequestsInFlight", payload);
  },
  setMetaData(context, payload) {
    context.commit("setMetaData", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
