import { CODE_LOOKUP_TOOL_ID } from "@/utils/constants";

const state = {
  showCodeLookup: false,
};

const getters = {
  isMinimized(_state, _getters, rootState) {
    return rootState.minimizedDock.items.some(
      (item) => item.id === CODE_LOOKUP_TOOL_ID
    );
  },
};

const mutations = {
  setShowCodeLookup(state, payload) {
    state.showCodeLookup = payload;
  },
};

const actions = {
  setShowCodeLookup(context, payload) {
    context.commit("setShowCodeLookup", payload);
    if (!payload) {
      context.dispatch("minimizedDock/removeItem", CODE_LOOKUP_TOOL_ID, {
        root: true,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
