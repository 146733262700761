// state
const state = {
  tabId: null,
};

// getters
const getters = {
  tabId(state) {
    return state.tabId;
  },
};

// mutations
const mutations = {
  setTabId(state, payload) {
    state.tabId = parseInt(payload);
  },
};

// actions
const actions = {
  getTabId(context) {
    if (sessionStorage.tabId) {
      // if the tab id was previously set, lets resuse it
      context.commit("setTabId", sessionStorage.tabId);
      // remove the tab id from session storage so that if the tab is duplicated, the tab id is not reused
      sessionStorage.removeItem("tabId");
    } else {
      // otherwise generate a new tab id
      context.commit("setTabId", Math.floor(Math.random() * 1000000));
    }
  },
  storeTabId(context) {
    // store the tab id in session storage so it persists through a refresh
    sessionStorage.tabId = context.getters.tabId;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
