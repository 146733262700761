import { PERMISSION_SCOPE } from "@/utils/constants";
import router from "@/router";

const state = {
  /**
   * Array of objects: {rsid, rsname, scopes: [str]}
   */
  rptToken: [],
};

const getters = {
  //#region project permissions
  accountProjectPermission: (state) => {
    const permissionName = `idp:account-${router.currentRoute.value.params.accountId}:projects`;
    return (
      state.rptToken.find(
        (permission) => permission["rsname"] === permissionName
      ) || {}
    );
  },
  hasProjectScope:
    (state, getters, rootState, rootGetters) =>
    ({ scope }) => {
      if (rootGetters.isAdmin) {
        return true;
      }
      const { accountId } = router.currentRoute.value.params;
      if (
        (rootGetters.accountConfig(accountId).allowProjectManagement &&
          (scope === PERMISSION_SCOPE.CREATE ||
            scope === PERMISSION_SCOPE.DELETE)) ||
        scope === PERMISSION_SCOPE.EDIT
      ) {
        return (
          (getters.accountProjectPermission?.scopes || []).indexOf(scope) > -1
        );
      }

      return false;
    },
  canCreateProject: (_, getters) =>
    getters.hasProjectScope({ scope: PERMISSION_SCOPE.CREATE }),
  canEditProject: (s, getters) =>
    getters.hasProjectScope({ scope: PERMISSION_SCOPE.EDIT }),
  canDeleteProject: (s, getters) =>
    getters.hasProjectScope({ scope: PERMISSION_SCOPE.DELETE }),
  //#endregion project permissions

  //#region api-key permissions
  accountApiKeysPermission: (state) => (accountId) => {
    const permissionName = `idp:account-${accountId}:api-keys`;
    return (
      state.rptToken.find(
        (permission) => permission["rsname"] === permissionName
      ) || {}
    );
  },
  canDoWithApiKeys:
    (_state, getters, rootState, rootGetters) =>
    ({ scope, accountId }) => {
      if (
        rootGetters.isAdmin ||
        rootGetters.isAccountAdmin(accountId) ||
        rootGetters.isAccountManager(accountId)
      ) {
        return true;
      } else {
        return (
          (getters.accountApiKeysPermission(accountId)?.scopes || []).indexOf(
            scope
          ) > -1
        );
      }
    },
  canCreateApiKeys: (_, getters) => (accountId) =>
    getters.canDoWithApiKeys({ scope: PERMISSION_SCOPE.CREATE, accountId }),
  canViewApiKeys: (_, getters) => (accountId) =>
    getters.canDoWithApiKeys({ scope: PERMISSION_SCOPE.VIEW, accountId }),
  canDeleteApiKeys: (_, getters) => (accountId) =>
    getters.canDoWithApiKeys({ scope: PERMISSION_SCOPE.DELETE, accountId }),
  //#endregion api-key permissions
};

const mutations = {
  setRPTToken: (state, newToken) => (state.rptToken = newToken),
};

const actions = {
  setRPTToken: (context, rptToken) => {
    context.commit("setRPTToken", rptToken);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
