import api, { filesStreamingApi } from "@/api";

const state = {
  images: {},
  imageRequestsInFlight: new Set(),
  downloadedImages: new Set(),
  failedImages: new Set(),
  regenerationRequestsInFlight: new Set(),
};

const getters = {};

const mutations = {
  addImage(state, payload) {
    state.images[payload.page] = payload.image;
  },
  addDownloadedImage(state, payload) {
    state.downloadedImages.add(payload);
  },
  addFailedImage(state, payload) {
    state.failedImages.add(payload);
  },
  removeFailedImage(state, payload) {
    state.failedImages.delete(payload);
  },
  addImageRequestsInFlight(state, payload) {
    state.imageRequestsInFlight.add(payload);
  },
  removeImageRequestsInFlight(state, payload) {
    state.imageRequestsInFlight.delete(payload);
  },
  resetImages(state) {
    state.images = {};
  },
  resetImageRequestsInFlight(state) {
    state.imageRequestsInFlight = new Set();
  },
  resetDownloadedImages(state) {
    state.downloadedImages = new Set();
  },
  resetFailedImages(state) {
    state.failedImages = new Set();
  },
  addRegenerationRequestsInFlight(state, payload) {
    state.regenerationRequestsInFlight.add(payload);
  },
  removeRegenerationRequestsInFlight(state, payload) {
    state.regenerationRequestsInFlight.delete(payload);
  },
};

const actions = {
  addImage(context, payload) {
    context.commit("addImage", payload);
    context.commit("addDownloadedImage", payload.page);
  },
  addFailedImage(context, payload) {
    context.commit("addFailedImage", payload);
  },
  removeFailedImage(context, payload) {
    context.commit("removeFailedImage", payload);
  },
  addImageRequestsInFlight(context, payload) {
    context.commit("addImageRequestsInFlight", payload);
  },
  removeImageRequestsInFlight(context, payload) {
    context.commit("removeImageRequestsInFlight", payload);
  },
  fetchImage(context, { page, contentId, projectId }) {
    // fetch image if the image has not already been downloaded
    // and the image is in not in the process of being downloaded
    // and the image has not failed to download
    if (
      !context.state.downloadedImages.has(page) &&
      !context.state.imageRequestsInFlight.has(page) &&
      !context.state.failedImages.has(page)
    ) {
      context.dispatch("addImageRequestsInFlight", page);
      const documentUUID = context.rootState.documentData.documentUUID;
      filesStreamingApi({ interceptError: false })
        .get(
          `projects/${projectId}/contents/${contentId}/${documentUUID}/images/${page}`
        )
        .then((response) => {
          const image = `data:${response.headers["content-type"]};base64,${response.data}`;
          context.dispatch("addImage", { page, image });
          context.dispatch("removeImageRequestsInFlight", page);
        })
        .catch(() => {
          context.dispatch("addFailedImage", page);
          context.dispatch("removeImageRequestsInFlight", page);
        });
    }
  },
  resetImages(context) {
    context.commit("resetImages");
  },
  resetImageRequestsInFlight(context) {
    context.commit("resetImageRequestsInFlight");
  },
  resetDownloadedImages(context) {
    context.commit("resetDownloadedImages");
  },
  reloadPage(context, { page, contentId, projectId }) {
    context.dispatch("removeFailedImage", page);
    context.dispatch("fetchImage", { page, contentId, projectId });
  },
  async regenerateImage(context, { page, contentId, projectId }) {
    context.commit("addRegenerationRequestsInFlight", page);
    const maxRetries = 10;
    const delay = 1000;
    try {
      await api({ interceptError: false }).post(
        `projects/${projectId}/contents/${contentId}/generate-page-image/${page}`
      );
      await context.dispatch(
        "documentData/fetchMetaData",
        { setLoading: false, contentId, projectId },
        { root: true }
      );
      // Check if image generation was unsuccessful and retry if necessary
      if (
        context.rootState.documentData.generateImagesSuccess === false &&
        context.rootState.documentData.generateImagesStatus === "requested"
      ) {
        for (let retry = 0; retry < maxRetries; retry++) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          // Retry fetching metadata
          await context.dispatch(
            "documentData/fetchMetaData",
            { setLoading: false, contentId, projectId },
            { root: true }
          );
          // Exit retry loop if successful
          if (context.rootState.documentData.generateImagesSuccess) {
            break;
          }
        }
      }
      context.dispatch("reloadPage", { page, contentId, projectId });
    } catch (error) {
      context.dispatch("addFailedImage", page);
    }
    context.commit("removeRegenerationRequestsInFlight", page);
  },
  resetFailedImages(context) {
    context.commit("resetFailedImages");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
