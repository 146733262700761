<template>
  <main>
    <div
      class="pt-0 pb-6 mx-auto max-w-8xl sm:px-6 lg:px-8"
      :class="{ 'pb-0': removeBottomPadding }"
    >
      <div class="px-4 py-10 sm:px-0" :class="{ 'pb-0': removeBottomPadding }">
        <slot name="header"></slot>
        <div :class="bgWhite ? 'p-6 bg-white rounded-md shadow' : ''">
          <slot />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    bgWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeBottomPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
