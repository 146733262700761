import axios from "axios";

export const generateData = (field, data) => {
  const functions = {
    "01": generate01Data,
    "02": generate02Data,
    "3a": generate3aData,
    "3b": generate3aData,
    "04": generate04Data,
    "05": generate3aData,
    "06": generate06Data,
    "08-11": generate08Data,
    12: generate12Data,
    13: generate13Data,
    14: generate04Data,
    15: generate04Data,
    16: generate13Data,
    17: generate04Data,
    "18-28": generate18Data,
    29: generate29Data,
    "31-34": generate31Data,
    "35-36": generate35Data,
    38: generate38Data,
    "39-41": generate39Data,
    "42-48": generate42Data,
    _creationDate: generateCreationDateData,
    _charges: generateChargesData,
    "50-55": generate50Data,
    56: generate56Data,
    57: generate3aData,
    "58-62": generate58Data,
    63: generate3aData,
    64: generate3aData,
    65: generate29Data,
    66: generate29Data,
    67: generate67Data,
    69: generate04Data,
    70: generate04Data,
    71: generate04Data,
    72: generate72Data,
    74: generate74Data,
    76: generate76Data,
    77: generate77Data,
    "78-79": generate78Data,
    80: generate29Data,
    81: generate81Data,
  };

  return functions[field.id](data);
};

const findItemFromData = (id, data) => {
  return data.find((d) => d.id === id);
};

const getItemFromHeader = (id, data, name) => {
  return {
    label: id,
    name,
    data: data[0].children.find((c) => c.id === id).children[0] || {},
  };
};

const getItemFromTag = (data, name) => {
  return {
    label: data[0].id,
    name,
    data: data[0].children[0] || {},
  };
};

const getItemsFromTag = (data, name) => {
  return data[0].children.map((child) => {
    let obj = {};
    obj[name] = {
      label: data[0].id,
      name,
      data: child,
    };
    return obj;
  });
};

const getItemFromAssociations = (values, data) => {
  let items = [];
  data[0].children.forEach((child) => {
    let obj = {};
    values.forEach((v) => {
      obj[v.value] = {
        label: v.key,
        name: v.name,
        data: child.find((c) => c.id === v.key)?.children[0] || {},
      };
    });
    items.push(obj);
  });

  return items;
};

const generate01Data = (data) => {
  const name = getItemFromHeader("1_provider_name", data, "name");
  const street = getItemFromHeader("1_provider_address_street", data, "street");
  const city = getItemFromHeader("1_provider_address_city", data, "city");
  const state = getItemFromHeader("1_provider_address_state", data, "state");
  const zip = getItemFromHeader("1_provider_address_zip_code", data, "zip");
  const phone = getItemFromHeader("1_provider_phone_number", data, "phone");

  return {
    name,
    street,
    city,
    state,
    zip,
    phone,
  };
};

const generate02Data = (data) => {
  const name = getItemFromHeader("2_pay_to_name", data, "name");
  const street = getItemFromHeader("2_pay_to_address_street", data, "street");
  const city = getItemFromHeader("2_pay_to_address_city", data, "city");
  const state = getItemFromHeader("2_pay_to_address_state", data, "state");
  const zip = getItemFromHeader("2_pay_to_address_zip_code", data, "zip");
  const id = getItemFromHeader("2_pay_to_id", data, "ID");

  return {
    name,
    street,
    city,
    state,
    zip,
    id,
  };
};

const generate3aData = (data) => {
  const number = getItemFromTag(data, "number");

  return { number };
};

const generate04Data = (data) => {
  const code = getItemFromTag(data, "code");

  return { code };
};

const generate06Data = (data) => {
  const startDate = getItemFromTag(
    [findItemFromData("6_statement_start_date", data)],
    "start"
  );
  const endDate = getItemFromTag(
    [findItemFromData("6_statement_end_date", data)],
    "end"
  );

  return { startDate, endDate };
};

const generate08Data = (data) => {
  const id = getItemFromTag([findItemFromData("8a_patient_id", data)], "ID");
  const name = getItemFromTag(
    [findItemFromData("8b_patient_name", data)],
    "name"
  );
  const birthDate = getItemFromTag(
    [findItemFromData("10_patient_birth_date", data)],
    "birth date"
  );
  const sex = getItemFromTag([findItemFromData("11_patient_sex", data)], "sex");
  const street = getItemFromHeader(
    "9a_patient_address_street",
    [findItemFromData("9_patient_address", data)],
    "street"
  );
  const city = getItemFromHeader(
    "9b_patient_address_city",
    [findItemFromData("9_patient_address", data)],
    "city"
  );
  const state = getItemFromHeader(
    "9c_patient_address_state",
    [findItemFromData("9_patient_address", data)],
    "state"
  );
  const zip = getItemFromHeader(
    "9d_patient_address_zip_code",
    [findItemFromData("9_patient_address", data)],
    "zip"
  );
  const countryCode = getItemFromHeader(
    "9e_patient_address_country_code",
    [findItemFromData("9_patient_address", data)],
    "country"
  );

  return {
    id,
    name,
    street,
    city,
    state,
    zip,
    countryCode,
    birthDate,
    sex,
  };
};

const generate12Data = (data) => {
  const startDate = getItemFromTag(data, "date");

  return { startDate };
};

const generate13Data = (data) => {
  const hour = getItemFromTag(data, "hour");

  return { hour };
};

const generate18Data = (data) => {
  const codes = getItemsFromTag(data, "code");

  return { codes };
};

const generate29Data = (data) => {
  const text = getItemFromTag(data, "text");

  return { text };
};

const generate31Data = (data) => {
  const values = [
    { value: "code", key: "31_34_occurrence_code", name: "code" },
    { value: "startDate", key: "31_34_occurrence_date", name: "start date" },
  ];

  return { codes: getItemFromAssociations(values, data) };
};

const generate35Data = (data) => {
  const values = [
    { value: "code", key: "35_36_occurrence_span_code", name: "code" },
    {
      value: "startDate",
      key: "35_36_occurrence_span_start_date",
      name: "start",
    },
    {
      value: "endDate",
      key: "35_36_occurrence_span_end_date",
      name: "end",
    },
  ];

  return { codes: getItemFromAssociations(values, data) };
};

const generate38Data = (data) => {
  const base = "38_responsible_party";
  const name = getItemFromHeader(`${base}_name`, data, "name");
  const street = getItemFromHeader(`${base}_address_street`, data, "street");
  const city = getItemFromHeader(`${base}_address_city`, data, "city");
  const state = getItemFromHeader(`${base}_address_state`, data, "state");
  const zip = getItemFromHeader(`${base}_address_zip_code`, data, "zip");
  const phone = getItemFromHeader(`${base}_phone_number`, data, "phone");

  return {
    name,
    street,
    city,
    state,
    zip,
    phone,
  };
};

const generate39Data = (data) => {
  const values = [
    { value: "code", key: "39_41_value_code_code", name: "code" },
    { value: "currency", key: "39_41_value_code_amount", name: "amount" },
  ];

  return { codes: getItemFromAssociations(values, data) };
};

const generate42Data = (data) => {
  const values = [
    { value: "revenueCode", key: "42_revenue_code", name: "code" },
    { value: "description", key: "43_description", name: "description" },
    { value: "hcpcs", key: "44_hcpcs_rate_hipps_code", name: "hcpcs" },
    { value: "serviceDate", key: "45_service_date", name: "date" },
    { value: "serviceUnits", key: "46_service_units", name: "unit" },
    { value: "totalCharges", key: "47_total_charges", name: "charge" },
    {
      value: "nonCoveredCharges",
      key: "48_non_covered_charges",
      name: "charge",
    },
  ];

  return { items: getItemFromAssociations(values, data) };
};

const generateCreationDateData = (data) => {
  const date = getItemFromTag(
    [findItemFromData("creation_date", data)],
    "date"
  );

  return { startDate: date };
};

const generateChargesData = (data) => {
  const totalCharges = getItemFromTag(
    [findItemFromData("total_charges_total", data)],
    "charge"
  );

  const nonCoveredCharges = getItemFromTag(
    [findItemFromData("non_covered_charges_total", data)],
    "charge"
  );

  return { charges: [totalCharges, nonCoveredCharges] };
};

const generate50Data = (data) => {
  const values = [
    {
      value: "payerName",
      key: "50_payer_name",
      name: "name",
    },
    {
      value: "healthPlanId",
      key: "51_health_plan_id",
      name: "ID",
    },
    {
      value: "releaseCertification",
      key: "52_release_of_information_certification",
      name: "certification",
    },
    {
      value: "benefitsCertification",
      key: "53_assignment_of_benefits_certification",
      name: "certification",
    },
    {
      value: "priorPayments",
      key: "54_prior_payments",
      name: "payment",
    },
    {
      value: "estimatedAmountDue",
      key: "55_estimated_amount_due",
      name: "amount",
    },
  ];

  return { items: getItemFromAssociations(values, data) };
};

const generate56Data = (data) => {
  const code = getItemFromTag(data, "code");

  return { code };
};

const generate58Data = (data) => {
  const values = [
    { value: "insuredName", key: "58_insured_name", name: "name" },
    {
      value: "patientRelationship",
      key: "59_patient_relationship",
      name: "relationship",
    },
    { value: "insuredUniqueId", key: "60_insured_unique_id", name: "ID" },
    {
      value: "insuranceGroupName",
      key: "61_insurance_group_name",
      name: "name",
    },
    {
      value: "insuranceGroupNumber",
      key: "62_insurance_group_number",
      name: "number",
    },
  ];

  return { items: getItemFromAssociations(values, data) };
};

const generate67Data = (data) => {
  const code = getItemFromHeader(
    "67_principal_diagnosis_code",
    [findItemFromData("67_principal_diagnosis", data)],
    "code"
  );
  const presentOnAdmissionIndicator = getItemFromHeader(
    "67_principal_diagnosis_present_on_admission_indicator",
    [findItemFromData("67_principal_diagnosis", data)],
    "indicator"
  );

  const values = [
    {
      value: "code",
      key: "67_other_diagnosis_code",
      name: "code",
    },
    {
      value: "presentOnAdmissionIndicator",
      key: "67_other_diagnosis_present_on_admission_indicator",
      name: "indicator",
    },
  ];
  let codes = getItemFromAssociations(values, [
    findItemFromData("67_other_diagnosis", data),
  ]);

  codes.unshift({ code, presentOnAdmissionIndicator });

  return { codes };
};

const generate72Data = (data) => {
  const values = [
    { value: "code", key: "72_external_cause_of_injury_code", name: "code" },
    {
      value: "presentOnAdmissionIndicator",
      key: "72_external_cause_of_injury_present_on_admission_indicator",
      name: "indicator",
    },
  ];

  return { codes: getItemFromAssociations(values, data) };
};

const generate74Data = (data) => {
  const code = getItemFromHeader(
    "74_principal_procedure_code",
    [findItemFromData("74_principal_procedure", data)],
    "code"
  );
  const startDate = getItemFromHeader(
    "74_principal_procedure_date",
    [findItemFromData("74_principal_procedure", data)],
    "date"
  );

  const values = [
    {
      value: "code",
      key: "74_other_procedure_code",
      name: "code",
    },
    {
      value: "startDate",
      key: "74_other_procedure_date",
      name: "date",
    },
  ];
  let codes = getItemFromAssociations(
    values,
    [findItemFromData("74_other_procedure", data)],
    "date"
  );

  codes.unshift({ code, startDate });

  return { codes };
};

const generate76Data = (data) => {
  const physician = {
    npi: getItemFromHeader("76_attending_physician_npi", data, "NPI"),
    idQualifier: getItemFromHeader(
      "76_attending_physician_id_qualifier",
      data,
      "ID qualifier"
    ),
    idNumber: getItemFromHeader(
      "76_attending_physician_id_number",
      data,
      "ID number"
    ),
    lastName: getItemFromHeader(
      "76_attending_physician_last_name",
      data,
      "last name"
    ),
    firstName: getItemFromHeader(
      "76_attending_physician_first_name",
      data,
      "first name"
    ),
  };

  return { physicians: [physician] };
};

const generate77Data = (data) => {
  const physician = {
    npi: getItemFromHeader("77_operating_physician_npi", data, "NPI"),
    idQualifier: getItemFromHeader(
      "77_operating_physician_id_qualifier",
      data,
      "ID qualifier"
    ),
    idNumber: getItemFromHeader(
      "77_operating_physician_id_number",
      data,
      "ID number"
    ),
    lastName: getItemFromHeader(
      "77_operating_physician_last_name",
      data,
      "last name"
    ),
    firstName: getItemFromHeader(
      "77_operating_physician_first_name",
      data,
      "first name"
    ),
  };

  return { physicians: [physician] };
};

const generate78Data = (data) => {
  const values = [
    {
      value: "physicianType",
      key: "78_79_other_physician_type",
      name: "physician type",
    },
    { value: "npi", key: "78_79_other_physician_npi", name: "NPI" },
    {
      value: "idQualifier",
      key: "78_79_other_physician_id_qualifier",
      name: "ID qualifier",
    },
    {
      value: "idNumber",
      key: "78_79_other_physician_id_number",
      name: "ID number",
    },
    {
      value: "lastName",
      key: "78_79_other_physician_last_name",
      name: "last name",
    },
    {
      value: "firstName",
      key: "78_79_other_physician_first_name",
      name: "first name",
    },
  ];

  return { physicians: getItemFromAssociations(values, data) };
};

const generate81Data = (data) => {
  const values = [
    { value: "ccQualifier", key: "81_cc_qualifier", name: "qualifier" },
    { value: "ccCode", key: "81_cc_code", name: "code" },
    { value: "ccValue", key: "81_cc_value", name: "value" },
  ];

  return { ccs: getItemFromAssociations(values, data) };
};

const extractCodeInfo = (obj) => {
  return {
    field_name: obj.label,
    code: obj.data.formatted_value || "",
  };
};

const isRevCode002X = (revCode) => {
  const pattern = /^(002\d|02\d|2\d)$/;
  return pattern.test(revCode);
};

export const generateBillingPayload = async (fields) => {
  let filteredFields = fields.filter(
    (field) => !["67", "69", "70", "71", "72", "74"].includes(field.id)
  );
  let payload = [];
  filteredFields.forEach((field) => {
    if (field.id === "42-48") {
      field.data.items.map((item) => {
        if (
          isRevCode002X(item.revenueCode.data.formatted_value) &&
          item.hcpcs
        ) {
          payload.push(extractCodeInfo(item.hcpcs));
        }
        payload.push(extractCodeInfo(item.revenueCode));
      });
    } else {
      field.codes.forEach((code) => {
        const data = Object.values(field.data)[0];
        if (Array.isArray(data)) {
          const codesArray = data
            .map((d) => {
              const obj = d[code];
              return obj ? extractCodeInfo(obj) : null;
            })
            .filter(Boolean);
          payload.push(...codesArray);
        } else {
          const obj = field.data[code];
          if (obj) {
            payload.push(extractCodeInfo(obj));
          }
        }
      });
    }
  });
  return { codes: payload };
};

const isInPatientClaim = (fields) => {
  const typeOfBillValue = getTypeOfBillValue(fields);
  const pattern = /^11\d$/;
  return pattern.test(typeOfBillValue);
};

const isOutPatientClaim = (fields) => {
  const typeOfBillValue = getTypeOfBillValue(fields);
  const pattern = /^(12|13|14)\d$/;
  return pattern.test(typeOfBillValue);
};

const getTypeOfBillValue = (fields) => {
  const typeOfBill = fields.find((field) => field.id === "04");
  return parseInt(typeOfBill?.data?.code?.data?.formatted_value, 10);
};

export const generateMedicalPayload = async (fields) => {
  let payload = [];
  fields.forEach((field) => {
    if (
      field.id === "42-48" &&
      (isOutPatientClaim(fields) || isInPatientClaim(fields))
    ) {
      field.data.items.forEach((item) => {
        if (!isRevCode002X(item.revenueCode.data.formatted_value)) {
          payload.push({
            field_name: item.hcpcs.label,
            lookup_key: isInPatientClaim(fields) ? "pcs" : "cpt",
            code: item.hcpcs.data.formatted_value || "",
          });
        }
      });
    } else if (["67", "72"].includes(field.id)) {
      field.data.codes.forEach((code) => {
        payload.push({
          field_name: code.code.label,
          lookup_key: "cm",
          code: code.code.data.formatted_value || "",
        });
      });
    } else if (["69", "70"].includes(field.id)) {
      payload.push({
        field_name: field.data.code.label,
        lookup_key: "cm",
        code: field.data.code.data.formatted_value || "",
      });
    } else if (field.id === "71" && isInPatientClaim(fields)) {
      payload.push({
        field_name: field.data.code.label,
        lookup_key: "drg",
        code: field.data.code.data.formatted_value || "",
      });
    } else if (
      field.id === "74" &&
      (isOutPatientClaim(fields) || isInPatientClaim(fields))
    ) {
      field.data.codes.forEach((code) => {
        payload.push({
          field_name: code.code.label,
          lookup_key: isInPatientClaim(fields) ? "pcs" : "cpt",
          code: code.code.data.formatted_value || "",
        });
      });
    }
  });
  return { codes: payload };
};

export const generateNPI = (fields) => {
  return fields.find((field) => field.id === "56").data.code.data
    .formatted_value;
};

export const NPILookup = async (number) => {
  const env = import.meta.env.VITE_ENV;
  let url =
    env === "prod"
      ? "https://zvtjp6ikqglvzyrupvawgajpym0qigzg.lambda-url.us-east-2.on.aws"
      : "https://rybmgqxhrlpfefmszkdvetaiva0goqop.lambda-url.us-east-2.on.aws";
  const response = await axios.get(`${url}/?number=${number}`);
  return response?.data?.results[0] || {};
};

export const generateHcpcsPayload = async (fields) => {
  const serviceItem = fields.find((field) => field.id === "42-48");
  let payload = [];
  if (isOutPatientClaim(fields) || isInPatientClaim(fields)) {
    serviceItem.data.items.forEach((item) => {
      if (!isRevCode002X(item.revenueCode.data.formatted_value)) {
        payload.push({
          code: item.hcpcs.data.formatted_value || "",
        });
      }
    });
  }
  return { codes: payload };
};

export const looksLikeNDC = (str) => {
  return str && str.length >= 13 && str.substring(0, 2) === "N4";
};

const generateUB04NDCs = (ub04NDCs) => {
  let NDCs = [];
  ub04NDCs.forEach((ub04NDC) => {
    if (looksLikeNDC(ub04NDC)) {
      let ndc = ub04NDC.split("N4")[1];
      const firstFive = ndc.substring(0, 5);
      let firstSegment = firstFive;
      const secondFour = ndc.substring(5, 9);
      let secondSegment = secondFour;
      NDCs.push({
        ub04NDC: ub04NDC,
        packageNDC: `${firstSegment}-${secondSegment}`,
      });
      if (firstFive[0] === "0" && firstFive[1] === "0") {
        firstSegment = firstFive.substring(1, 5);
        NDCs.push({
          ub04NDC: ub04NDC,
          packageNDC: `${firstSegment}-${secondSegment}`,
        });
      }
      if (secondFour[0] === "0") {
        secondSegment = secondFour.substring(1, 4);
        NDCs.push({
          ub04NDC: ub04NDC,
          packageNDC: `${firstSegment}-${secondSegment}`,
        });
      }
    }
  });
  return NDCs;
};

export const generateNDCs = async (fields) => {
  const possibleNDCs =
    fields
      .find((field) => field.id === "42-48")
      ?.data.items.map((item) => item.description.data.formatted_value) || [];
  return generateUB04NDCs(possibleNDCs);
};

const createNDCApiQuery = (array) => {
  const query = array.join("+OR+product_ndc:");
  const apiUrl = "https://api.fda.gov/drug/ndc.json?search=product_ndc:";
  return `${apiUrl}${query}&limit=${array.length}`;
};

export const NDCDrugLookup = async (drugCodes) => {
  const NDCApiQuery = createNDCApiQuery(drugCodes);
  const response = await axios.get(NDCApiQuery);
  return response.data.results;
};

export const supplementNDCs = (NDCs, NDCsResponse) => {
  NDCsResponse.forEach((n) => {
    n["_ub04_NDCs"] = [];
  });
  NDCs.forEach((ndc) =>
    NDCsResponse.find((n) => {
      if (n.product_ndc === ndc.packageNDC) {
        n["_ub04_NDCs"].push(ndc.ub04NDC);
      }
    })
  );
  return NDCsResponse;
};

export const modifyHcpcsCodes = (hcpcsCodes) => {
  let codes = hcpcsCodes.map((obj) => {
    return {
      ...obj,
      field_name: "44_hcpcs_rate_hipps_code",
      description: obj.full_description,
      code: obj.hcpcs_code,
      _from_hcpcs_api: true,
    };
  });
  return codes;
};

export const filterMedicalCodes = (hcpcsCodes, medicalCodes) => {
  const hcpcsSet = new Set(
    hcpcsCodes.filter((code) => code.valid).map((code) => code.hcpcs_code)
  );
  return medicalCodes.filter(
    (medCode) =>
      !(
        medCode.field_name === "44_hcpcs_rate_hipps_code" &&
        hcpcsSet.has(medCode.code)
      )
  );
};
