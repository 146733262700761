import overlaps from "@/utils/overlaps";
import fillMissingNumbers from "@/utils/fillMissingNumbers";
import { uniqBy } from "lodash-es";

// state
const state = {
  wordsToBeLabeled: {},
  isHighlighting: false,
  highlightingInProgress: false,
};

// getters
const getters = {};

// mutations
const mutations = {
  setWordsToBeLabeled(state, payload) {
    state.wordsToBeLabeled[payload.page] = payload.words;
  },
  resetWordsToBeLabeled(state) {
    state.wordsToBeLabeled = {};
  },
  setIsHighlighting(state, payload) {
    state.isHighlighting = payload;
  },
  setHighlightingInProgress(state, payload) {
    state.highlightingInProgress = payload;
  },
};

// actions
const actions = {
  setWordsToBeLabeled(context, payload) {
    context.commit("setWordsToBeLabeled", payload);
  },
  resetWordsToBeLabeled(context) {
    context.commit("resetWordsToBeLabeled");
  },
  setIsHighlighting(context, payload) {
    context.commit("setIsHighlighting", payload);
  },
  setHighlightingInProgress(context, payload) {
    context.commit("setHighlightingInProgress", payload);
  },
  generateWordsToBeLabeled(context, payload) {
    const downAndLeft =
      !payload.rect1.negativeHeight && payload.rect1.negativeWidth;
    const downAndRight =
      !payload.rect1.negativeHeight && !payload.rect1.negativeWidth;
    const upAndLeft =
      payload.rect1.negativeHeight && payload.rect1.negativeWidth;
    const upAndRight =
      payload.rect1.negativeHeight && !payload.rect1.negativeWidth;

    const text = context.rootGetters["documentData/textBlocks"](
      payload.page
    ).filter((text) => overlaps(payload.rect1, text.bounding_box));
    let wordsFromTextBlocks = [];
    //////////////////////////////////////////////////////////////
    // Get all relevant words from across text blocks if the user
    // draws a rectangle that goes fully across text blocks
    //////////////////////////////////////////////////////////////
    const textIndexes = text.map((t) => {
      const index = context.rootGetters["documentData/textBlocks"](
        payload.page
      ).findIndex((text) => text.uuid === t.uuid);
      return index !== -1 ? index : undefined;
    });
    const fullyHighlightedTextBlockIndexes = fillMissingNumbers(textIndexes);
    if (fullyHighlightedTextBlockIndexes.length > 1) {
      const fullyHighlightedTextBlockChildrenUUIDs =
        fullyHighlightedTextBlockIndexes
          .map(
            (index) =>
              context.rootGetters["documentData/textBlocks"](payload.page)[
                index
              ]
          )
          .map((t) => t.children)
          .flat();
      const linesInFullyHighlightedTextBlocks =
        fullyHighlightedTextBlockChildrenUUIDs
          .map((lineUUID) => {
            return context.rootGetters["documentData/lineBlocks"](
              payload.page
            ).find((line) => line.uuid === lineUUID);
          })
          .filter((item) => item);
      const wordUUIDsFromTextBlocks = linesInFullyHighlightedTextBlocks
        .map((line) => line.children)
        .flat();
      wordsFromTextBlocks = context.rootGetters["documentData/wordBlocks"](
        payload.page
      ).filter((word) => wordUUIDsFromTextBlocks.includes(word.uuid));
    }
    //////////////////////////////////////////////////////////////
    // Figure out what lines are being used based on text blocks
    //////////////////////////////////////////////////////////////
    const textChildren = text.map((text) => text.children).flat();
    const linesInTextBlocks = textChildren
      .map((lineUUID) =>
        context.rootGetters["documentData/lineBlocks"](payload.page).find(
          (line) => line.uuid === lineUUID
        )
      )
      .filter((item) => item);
    let lines = [];
    linesInTextBlocks.map((line) => {
      if (
        line.bounding_box.y_min < payload.rect1.y_max &&
        line.bounding_box.y_max > payload.rect1.y_min
      ) {
        lines.push(line);
      }
    });
    if (lines.length > 1) {
      //////////////////////////////////////////////////////////////
      // Get relevant words from first line
      //////////////////////////////////////////////////////////////
      const allFirstLineWords = context.rootGetters["documentData/wordBlocks"](
        payload.page
      ).filter((word) => lines[0].children.includes(word.uuid));
      // the behavior of what words to include/exclude for the first line
      // varies based on which direction the user is highlighting
      let highlightedFirstLineWords;
      if (downAndLeft || upAndRight) {
        highlightedFirstLineWords = allFirstLineWords.filter(
          (word) => word.bounding_box.x_max > payload.rect1.x_max
        );
      }
      if (downAndRight || upAndLeft) {
        const firstWord = payload.words[0];
        highlightedFirstLineWords = allFirstLineWords.slice(
          allFirstLineWords.indexOf(firstWord),
          allFirstLineWords.length
        );
      }
      //////////////////////////////////////////////////////////////
      // Get relevant words from last line
      //////////////////////////////////////////////////////////////
      const lastLine = lines[lines.length - 1];
      const allLastLineWords = context.rootGetters["documentData/wordBlocks"](
        payload.page
      ).filter((word) => lastLine.children.includes(word.uuid));
      // the behavior of what words to include/exclude for the last line
      // varies based on which direction the user is highlighting
      let highlightedLastLineWords;
      if (downAndLeft || upAndRight) {
        highlightedLastLineWords = allLastLineWords.filter(
          (word) => word.bounding_box.x_min < payload.rect1.x_min
        );
      }
      if (downAndRight || upAndLeft) {
        const lastWord = payload.words[payload.words.length - 1];
        highlightedLastLineWords = allLastLineWords.slice(
          0,
          allLastLineWords.indexOf(lastWord) + 1
        );
      }
      if (highlightedLastLineWords.length === 0) {
        highlightedLastLineWords = allLastLineWords;
      }
      //////////////////////////////////////////////////////////////
      // In the event that the user hasn't selected lines from other
      // text blocks, get all the relevant words from all lines
      // between the first and last line
      //////////////////////////////////////////////////////////////
      let highlightedWordsBetweenFirstLastLine = [];
      lines.pop();
      lines.shift();
      if (lines.length > 0) {
        const wordUUIDsFromLines = lines.map((line) => line.children).flat();
        highlightedWordsBetweenFirstLastLine = context.rootGetters[
          "documentData/wordBlocks"
        ](payload.page).filter((word) =>
          wordUUIDsFromLines.includes(word.uuid)
        );
      }
      //////////////////////////////////////////////////////////////
      // Clean up and remove all greedily flagged words that might
      // have been duplicated between all the logic
      //////////////////////////////////////////////////////////////
      const startIdx = wordsFromTextBlocks.findIndex(
        (word) => word.uuid === highlightedFirstLineWords[0]?.uuid
      );
      const endIdx = wordsFromTextBlocks.findIndex(
        (word) =>
          word.uuid ===
          highlightedLastLineWords[highlightedLastLineWords.length - 1]?.uuid
      );
      wordsFromTextBlocks = wordsFromTextBlocks.slice(startIdx, endIdx);
      //////////////////////////////////////////////////////////////
      // Combine all the different pieces together into one array
      //////////////////////////////////////////////////////////////
      payload.words = uniqBy(
        [
          ...wordsFromTextBlocks,
          ...highlightedFirstLineWords,
          ...highlightedWordsBetweenFirstLastLine,
          ...highlightedLastLineWords,
        ],
        "uuid"
      );
    }
    context.commit("setWordsToBeLabeled", {
      page: payload.page,
      words: payload.words,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
