<template>
  <div class="h-full">
    <div class="min-h-full">
      <div
        v-show="computedSidebarOpen"
        class="fixed inset-0 z-40 flex md:hidden"
        role="dialog"
      >
        <transition
          name="off-canvas-menu-overlay"
          enter-active-class="transition-opacity duration-300 ease-linear"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition-opacity duration-300 ease-linear"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="sidebarOpen"
            class="fixed inset-0 bg-gray-600 bg-opacity-75"
            @click="closeSidebar()"
          />
        </transition>
        <transition
          name="off-canvas-menu"
          enter-active-class="transition duration-300 ease-in-out transform"
          enter-from-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition duration-300 ease-in-out transform"
          leave-from-class="translate-x-0"
          leave-to-class="-translate-x-full"
        >
          <div
            v-if="sidebarOpen"
            class="relative flex flex-col flex-1 w-full max-w-xs bg-gray-800"
          >
            <transition
              name="close-button"
              enter-active-class="duration-300 ease-in-out"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-300 ease-in-out"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-if="sidebarOpen"
                class="absolute top-0 right-0 pt-2.5 -mr-12"
              >
                <button
                  type="button"
                  class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="closeSidebar()"
                >
                  <span class="sr-only">Close sidebar</span>
                  <svg
                    class="w-6 h-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </transition>
            <div class="flex-1 h-0 pt-2 pb-4 overflow-y-auto">
              <div class="flex items-center px-4 pl-4 shrink-0 sm:pl-6">
                <app-logo />
              </div>
              <app-nav-items />
            </div>
            <div class="flex p-4 bg-gray-700 shrink-0">
              <a href="#" class="block shrink-0 group">
                <div class="flex items-center">
                  <div>
                    <img
                      class="inline-block w-10 h-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-white">Tom Cook</p>
                    <p
                      class="text-sm font-medium text-gray-400 group-hover:text-gray-300"
                    >
                      View profile
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </transition>
        <div class="shrink-0 w-14"></div>
      </div>
      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div class="flex flex-col pt-2 pb-4 overflow-y-auto bg-gray-800 grow">
          <div class="flex items-center px-2 my-1 shrink-0">
            <app-logo />
          </div>
          <app-nav-items />
        </div>
      </div>
      <div class="flex flex-col flex-1 md:pl-64">
        <!-- Mobile top nav -->
        <div
          class="sticky top-0 z-10 inline-flex justify-between px-4 py-4 bg-gray-800 sm:px-6 md:hidden"
        >
          <app-logo />
          <button
            v-if="!sidebarOpen"
            type="button"
            class="text-gray-300 rounded-md hover:text-gray-500"
            @click="openSidebar()"
          >
            <svg
              class="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <main class="flex-1 pb-8">
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/layout/nav/Logo.vue";
import NavItems from "@/components/layout/nav/NavItems.vue";

export default {
  components: {
    "app-logo": Logo,
    "app-nav-items": NavItems,
  },

  data() {
    return {
      sidebarOpen: false,
      sidebarOpenDelay: false,
    };
  },

  computed: {
    route() {
      return this.$route.path;
    },
    computedSidebarOpen() {
      if (this.sidebarOpen) return this.sidebarOpen;
      return this.sidebarOpenDelay;
    },
  },

  watch: {
    route() {
      this.closeSidebar();
    },
  },

  methods: {
    openSidebar() {
      this.sidebarOpenDelay = true;
      this.sidebarOpen = true;
    },
    closeSidebar() {
      this.sidebarOpen = false;
      setTimeout(() => {
        this.sidebarOpenDelay = false;
      }, 300);
    },
  },
};
</script>
