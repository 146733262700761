import { referenceApi } from "@/api";
import { NOTIFICATION_TYPE } from "@/utils/constants";
import {
  generateNPI,
  generateNDCs,
  NPILookup,
  NDCDrugLookup,
  supplementNDCs,
  generateBillingPayload,
  generateMedicalPayload,
  generateHcpcsPayload,
  modifyHcpcsCodes,
  filterMedicalCodes,
} from "@/utils/docIQ/ub04.js";

const state = {
  activeForm: 0,
  codes: [],
  NPI: {},
  NDCs: [],
};

const getters = {};

const mutations = {
  setActiveForm(state, payload) {
    state.activeForm = payload;
  },
  setCodes(state, payload) {
    state.codes = payload;
  },
  setNPI(state, payload) {
    state.NPI = payload;
  },
  setNDCs(state, payload) {
    state.NDCs = payload;
  },
};

const actions = {
  setActiveForm(context, payload) {
    context.commit("setActiveForm", payload);
  },
  async fetchNPI(context, NPI) {
    try {
      let data = await NPILookup(NPI);
      context.commit("setNPI", data);
    } catch {
      context.commit("setNPI", {});
    }
  },
  async fetchNDCs(context, NDCs) {
    try {
      let NDCsResponse = await NDCDrugLookup(NDCs.map((ndc) => ndc.packageNDC));
      NDCsResponse = supplementNDCs(NDCs, NDCsResponse);
      context.commit("setNDCs", NDCsResponse);
    } catch {
      context.commit("setNDCs", []);
    }
  },
  async fetchCodes(context, { fieldsWithCodes }) {
    const NPI = generateNPI(fieldsWithCodes);
    if (NPI) {
      await context.dispatch("fetchNPI", NPI);
    } else {
      context.commit("setNPI", {});
    }
    const NDCs = await generateNDCs(fieldsWithCodes);
    if (NDCs.length > 0) {
      await context.dispatch("fetchNDCs", NDCs);
    }
    const billingPayload = await generateBillingPayload(fieldsWithCodes);
    const medicalPayload = await generateMedicalPayload(fieldsWithCodes);
    const hcpcsPayload = await generateHcpcsPayload(fieldsWithCodes);
    try {
      let [billingResponse, medicalResponse, hcpcsResponse] = await Promise.all(
        [
          referenceApi().post("/billing", billingPayload),
          referenceApi().post("/medical", medicalPayload),
          referenceApi().post("/hcpcs", hcpcsPayload),
        ]
      );
      const billingCodes = billingResponse.data.codes;
      let medicalCodes = medicalResponse.data.codes;
      let hcpcsCodes = hcpcsResponse.data.codes;
      if (hcpcsCodes.length > 0) {
        hcpcsCodes = modifyHcpcsCodes(hcpcsCodes);
        medicalCodes = filterMedicalCodes(hcpcsCodes, medicalCodes);
      }
      context.commit("setCodes", [
        ...billingCodes,
        ...medicalCodes,
        ...hcpcsCodes,
      ]);
    } catch (error) {
      context.dispatch(
        "setNotification",
        {
          name: "Error",
          message: "Could not fetch codes.",
          type: NOTIFICATION_TYPE.ERROR,
        },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
