<template>
  <div
    class="w-full max-w-sm overflow-hidden border rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
    :class="baseStyleObject"
  >
    <div
      v-if="timerEnabled"
      class="h-2"
      :class="timerStyleObject"
      :style="{ width: percentLeft + '%' }"
    ></div>
    <div class="p-4">
      <div class="flex items-start">
        <div class="shrink-0">
          <app-icon-exclamation-circle
            v-if="isInfo | isWarning | isError"
            :classes="`w-5 h-5 ${notificationColor}`"
            outline
          />
          <app-icon-check-mark-circle v-else outline />
        </div>
        <div class="flex-1 w-0 ml-3">
          <p class="text-sm font-medium text-gray-900 break-all">
            {{ notification.name }}
          </p>
          <p v-if="notification.message" class="mt-1 text-sm text-gray-500">
            {{ notification.message }}
          </p>
          <p v-if="notification.details" class="mt-1 text-sm text-gray-500">
            {{ notification.details }}
          </p>
          <div v-if="notification.action" class="mt-2 text-sm text-gray-500">
            <button
              v-if="notification.action.type === BUTTON"
              type="button"
              class="rounded-md bg-green-50 border border-gray-200 px-2.5 py-1 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-100"
              @click="doAction()"
            >
              {{ notification.action.message }}
            </button>
          </div>
        </div>
        <div class="flex ml-4 shrink-0">
          <button
            class="inline-flex text-gray-400 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            data-test="dismiss-notification"
            @click="dismiss(notification.id)"
          >
            <span class="sr-only">Close</span>
            <app-icon-x stroke-width="3" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BUTTON } from "@/utils/constants";
import CheckMarkCircle from "@/components/icons/CheckMarkCircle.vue";
import ExclamationCircle from "@/components/icons/ExclamationCircle.vue";
import iconX from "@/components/icons/X.vue";

const DURATION_IN_MILLISECONDS = 6000;

export default {
  components: {
    "app-icon-check-mark-circle": CheckMarkCircle,
    "app-icon-exclamation-circle": ExclamationCircle,
    "app-icon-x": iconX,
  },

  props: {
    notification: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      timeLeft: DURATION_IN_MILLISECONDS,
      decrement: 10,
      downloadTimer: null,
    };
  },

  computed: {
    BUTTON() {
      return BUTTON;
    },
    isInfo() {
      return this.notification.type === "INFO";
    },
    isSuccess() {
      return this.notification.type === "SUCCESS";
    },
    isWarning() {
      return this.notification.type === "WARNING";
    },
    isError() {
      return this.notification.type === "ERROR";
    },
    notificationColor() {
      if (this.isInfo) {
        return "text-gray-500";
      } else if (this.isSuccess) {
        return "text-green-500";
      } else if (this.isWarning) {
        return "text-yellow-500";
      } else if (this.isError) {
        return "text-red-500";
      } else {
        return "text-gray-500";
      }
    },
    timerEnabled() {
      return this.notification.timer === undefined
        ? true
        : this.notification.timer;
    },
    percentLeft() {
      return (this.timeLeft / DURATION_IN_MILLISECONDS) * 100;
    },
    baseStyleObject() {
      return {
        "border-gray-200 bg-gray-50": this.isInfo,
        "border-green-200 bg-green-50": this.isSuccess,
        "border-yellow-200 bg-yellow-50": this.isWarning,
        "border-red-200 bg-red-50": this.isError,
      };
    },
    timerStyleObject() {
      return {
        "bg-gray-200": this.isInfo,
        "bg-green-200": this.isSuccess,
        "bg-yellow-200": this.isWarning,
        "bg-red-200": this.isError,
      };
    },
  },

  created() {
    if (this.timerEnabled) {
      this.downloadTimer = setInterval(() => {
        if (this.timeLeft <= 0) {
          this.dismiss(this.notification.id);
        }
        this.timeLeft -= this.decrement;
      }, this.decrement);
    }
  },

  unmounted() {
    clearTimeout(this.downloadTimer);
  },

  methods: {
    dismiss(notificationId) {
      clearTimeout(this.downloadTimer);
      this.$store.dispatch("clearNotification", notificationId);
    },
    doAction() {
      this.$store.dispatch(this.notification.action.action);
    },
  },
};
</script>
