import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import "component-library/styles.css";
import "@/assets/styles/tailwind.css";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import SetGlobalProperties from "@/globalProperties";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { directive } from "vue3-click-away";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_KEY);

ModuleRegistry.registerModules([
  CsvExportModule,
  ClientSideRowModelModule,
  MenuModule,
  ServerSideRowModelModule,
  SetFilterModule,
]);

const app = createApp(App).use(SetGlobalProperties);

app.use(VueKeyCloak, {
  config: {
    realm: app.config.globalProperties.$authRealm,
    url: `${app.config.globalProperties.$authURL}/auth`,
    clientId: "browser",
  },
  init: {
    onLoad: "check-sso",
    silentCheckSsoRedirectUri:
      window.location.origin + "/silent-check-sso.html",
  },
  onReady: initVue,
  onInitError: (err) => {
    console.error("Keycloak init error: ", err);
    initVue();
  },
});

app.directive("click-away", directive);

app.config.errorHandler = (err) => {
  // log uncaught errors
  console.error(err);
};

function initVue(keycloak) {
  store.dispatch("setKeycloak", keycloak);
  app.use(store).use(router).mount("#app");
}
