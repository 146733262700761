/////////////////////////////////////////////////////////////////////////////////////////
// Due to a snyk security concern in an imported package and vuex-persistedstate being //
// archived by the original author, we have decided to take responsibility for the     //
// package and remove it as a dependency and include the code in our codebase in       //
// order to control dependencies/versions to eliminate said snyk vulnerability.        //
// Original package: https://github.com/robinvdvleuten/vuex-persistedstate             //
// JIRA ticket: https://jira.infiniaml.net/browse/IDPA-923                             //
// SNYK vulnerability: https://snyk.io/advisor/npm-package/vuex-persistedstate         //
/////////////////////////////////////////////////////////////////////////////////////////

import merge from "deepmerge";
import { get, set } from "lodash-es";

/* OPTIONS
key <String>: The key to store the persisted state under. Defaults to vuex.
paths <Array>: An array of any paths to partially persist the state. If no paths are given, the complete state is persisted. If an empty array is given, no state is persisted. Paths must be specified using dot notation. If using modules, include the module name. eg: "auth.user" Defaults to undefined.
reducer <Function>: A function that will be called to reduce the state to persist based on the given paths. Defaults to include the values.
subscriber <Function>: A function called to setup mutation subscription. Defaults to store => handler => store.subscribe(handler).
storage <Object>: Instead of (or in combination with) getState and setState. Defaults to localStorage.
getState <Function>: A function that will be called to rehydrate a previously persisted state. Defaults to using storage.
setState <Function>: A function that will be called to persist the given state. Defaults to using storage.
filter <Function>: A function that will be called to filter any mutations which will trigger setState on storage eventually. Defaults to () => true.
overwrite <Boolean>: When rehydrating, whether to overwrite the existing state with the output from getState directly, instead of merging the two objects with deepmerge. Defaults to false.
arrayMerger <Function>: A function for merging arrays when rehydrating state. Defaults to function (store, saved) { return saved } (saved state replaces supplied state).
rehydrated <Function>: A function that will be called when the rehydration is finished. Useful when you are using Nuxt.js, which the rehydration of the persisted state happens asynchronously. Defaults to store => {}
fetchBeforeUse <Boolean>: A boolean indicating if the state should be fetched from storage before the plugin is used. Defaults to false.
assertStorage <Function>: An overridable function to ensure storage is available, fired on plugins's initialization. Default one is performing a Write-Delete operation on the given Storage instance. Note, default behaviour could throw an error (like DOMException: QuotaExceededError).
*/

export default function (options) {
  options = options || {};
  const storage = options.storage || (window && window.localStorage);
  const key = options.key || "vuex";

  function getState(key, storage) {
    const value = storage.getItem(key);
    try {
      return typeof value === "string"
        ? JSON.parse(value)
        : typeof value === "object"
          ? value
          : undefined;
    } catch (err) {
      console.error(err);
    }
    return undefined;
  }

  function filter() {
    return true;
  }

  function setState(key, state, storage) {
    return storage.setItem(key, JSON.stringify(state));
  }

  function reducer(state, paths) {
    if (!Array.isArray(paths)) return state;

    return paths.reduce((substate, path) => {
      if (typeof path === "string") {
        return set(substate, path, get(state, path));
      } else if (typeof path === "object") {
        const [modulePath, excludeProps] = Object.entries(path)[0];
        const moduleState = get(state, modulePath);
        if (moduleState) {
          const filteredModuleState = Object.keys(moduleState).reduce(
            (acc, key) => {
              if (!excludeProps.includes(key)) {
                acc[key] = moduleState[key];
              }
              return acc;
            },
            {}
          );
          return set(substate, modulePath, filteredModuleState);
        }
      }
      return substate;
    }, {});
  }

  function subscriber(store) {
    return function (handler) {
      return store.subscribe(handler);
    };
  }

  const assertStorage =
    options.assertStorage ||
    (() => {
      storage.setItem("@@", 1);
      storage.removeItem("@@");
    });

  assertStorage(storage);

  const fetchSavedState = () => (options.getState || getState)(key, storage);
  let savedState;

  if (options.fetchBeforeUse) {
    savedState = fetchSavedState();
  }

  return function (store) {
    if (!options.fetchBeforeUse) {
      savedState = fetchSavedState();
    }
    if (typeof savedState === "object" && savedState !== null) {
      store.replaceState(
        options.overwrite
          ? savedState
          : merge(store.state, savedState, {
              arrayMerge:
                options.arrayMerger ||
                function (store, saved) {
                  return saved;
                },
              clone: false,
            })
      );
      (options.rehydrated || function () {})(store);
    }
    (options.subscriber || subscriber)(store)(function (mutation, state) {
      if ((options.filter || filter)(mutation)) {
        (options.setState || setState)(
          key,
          (options.reducer || reducer)(state, options.paths),
          storage
        );
      }
    });
  };
}
