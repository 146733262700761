const state = {
  toolbarWidth: null,
  documentWidth: null,
  modulePanelWidth: null,
};

const getters = {};

const mutations = {
  setToolbarWidth(state, payload) {
    state.toolbarWidth = payload;
  },
  setDocumentWidth(state, payload) {
    state.documentWidth = payload;
  },
  setModulePanelWidth(state, payload) {
    state.modulePanelWidth = payload;
  },
};

const actions = {
  setToolbarWidth(context, payload) {
    context.commit("setToolbarWidth", payload);
  },
  setDocumentWidth(context, payload) {
    context.commit("setDocumentWidth", payload);
  },
  setModulePanelWidth(context, payload) {
    context.commit("setModulePanelWidth", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
