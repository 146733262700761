<template>
  <app-idle-modal :show-modal="showIdleModal" />
</template>

<script>
import { throttle } from "lodash-es";
import { datadogRum } from "@datadog/browser-rum";
import { DEFAULT_IDLE_TIMEOUT_IN_MILLISECONDS } from "@/utils/constants";
import AutoLogoutWorker from "@/workers/autoLogout.worker?worker&inline";
import AppIdleModal from "@/components/idle/IdleModal.vue";

export default {
  components: {
    AppIdleModal,
  },

  props: {
    init: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      autologoutWorker: null,
      timeoutInMilliseconds: DEFAULT_IDLE_TIMEOUT_IN_MILLISECONDS,
      enableAutologout: true,
      showIdleModal: false,
    };
  },

  computed: {
    activeModule() {
      return this.$store.state.docIQ.activeModule;
    },
  },

  watch: {
    init() {
      if (this.init) {
        this.initializeAutoLogout();
      }
    },
  },

  beforeUnmount() {
    this.stopAutologoutTimer();
    if (this.autologoutWorker) {
      this.autologoutWorker.terminate();
    }
    window.removeEventListener("mousemove", this.resetAutologoutTimer);
    window.removeEventListener("keydown", this.resetAutologoutTimer);
    document.removeEventListener("visibilitychange", this.handleChange);
    window.removeEventListener("storage", this.handleStorage);
  },

  methods: {
    startAutologoutTimer() {
      this.autologoutWorker.postMessage({
        type: "start",
        timeoutInMilliseconds: this.timeoutInMilliseconds,
      });
    },
    resetAutologoutTimer: throttle(function () {
      this.updateActivity();
      this.autologoutWorker.postMessage({
        type: "reset",
        timestamp: Date.now(),
      });
      if (this.showIdleModal === true) {
        this.trackAutoLogout("dismissed_modal");
      }
      this.showIdleModal = false;
    }, 1000),
    stopAutologoutTimer() {
      this.autologoutWorker.postMessage({ type: "stop" });
    },
    updateActivity() {
      const now = Date.now().toString();
      localStorage.setItem("lastActivity", now);
      this.autologoutWorker.postMessage({ type: "sync", timestamp: now });
    },
    getAccountConfigIdleSettings() {
      const accounts = this.$store.state.account.accounts;
      const accountId = this.$route.params.accountId;
      const account = accounts[accountId];
      const enabled = account?.config?.idleTimeout?.enabled;
      const timeout = account?.config?.idleTimeout?.timeoutInMilliseconds;
      if (enabled !== undefined) this.enableAutologout = enabled;
      if (timeout !== undefined) this.timeoutInMilliseconds = timeout;
    },
    initializeAutoLogout() {
      this.getAccountConfigIdleSettings();
      if (!this.enableAutologout) return;
      this.autologoutWorker = new AutoLogoutWorker();
      this.autologoutWorker.onmessage = (e) => {
        if (e.data === "logout") {
          this.trackAutoLogout("logged_out");
          this.$store.dispatch("signOut");
        } else if (e.data === "showModal") {
          this.trackAutoLogout("shown_modal");
          this.showIdleModal = true;
        }
      };
      this.startAutologoutTimer();
      window.addEventListener("mousemove", this.resetAutologoutTimer);
      window.addEventListener("keydown", this.resetAutologoutTimer);
      document.addEventListener("visibilitychange", this.handleChange);
      window.addEventListener("storage", this.handleStorage);
    },
    handleChange() {
      if (document.hidden) {
        this.startAutologoutTimer();
      } else {
        this.resetAutologoutTimer();
      }
    },
    handleStorage(event) {
      if (event.key === "lastActivity") {
        this.autologoutWorker.postMessage({
          type: "sync",
          timestamp: event.newValue,
        });
        this.resetAutologoutTimer();
      }
    },
    trackAutoLogout(action) {
      datadogRum.addAction("auto_logout", {
        action,
        module: this.activeModule.id,
        timeout: this.timeoutInMilliseconds,
        accountId: this.$route.params.accountId,
        projectId: this.$route.params.projectId,
        contentId: this.$route.params.contentId,
      });
    },
  },
};
</script>
