<template>
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83.24 36.4"
    data-test="logo-horizontal"
  >
    <defs></defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path
          class="cls-2"
          d="m7.97,10.19h4.27c4.1,0,6.55,2.64,6.55,8.28,0,6-2.62,8.52-6.86,8.52h-3.96V10.19Zm4.15,14.59c2.62,0,4.08-1.56,4.08-6.24,0-4.3-1.3-6.12-4.08-6.12h-1.56v12.36h1.56Z"
        />
        <path
          class="cls-2"
          d="m21.81,20.68c0-4.32,2.54-6.55,5.69-6.55s5.64,2.18,5.64,6.5-2.54,6.62-5.69,6.62-5.64-2.28-5.64-6.58Zm8.74-.02c0-2.95-1.22-4.39-3.07-4.39s-3.07,1.44-3.07,4.39,1.22,4.46,3.07,4.46,3.07-1.51,3.07-4.46Z"
        />
        <path
          class="cls-2"
          d="m36.67,20.7c0-4.39,2.78-6.58,6.05-6.58,1.99,0,3.36.7,4.49,1.87l-1.61,1.68c-.86-.89-1.58-1.42-3.02-1.42-2.09,0-3.34,1.75-3.34,4.34,0,2.74,1.08,4.51,3.43,4.51,1.3,0,2.26-.65,3.1-1.61l1.51,1.56c-1.08,1.27-2.57,2.18-4.71,2.18-3.24,0-5.9-2.18-5.9-6.55Z"
        />
      </g>
      <rect class="cls-1" x="1" y="1" width="51.42" height="34.4" />
      <g>
        <path
          class="cls-2"
          d="m59.26,25h2.54v-12.72h-2.54v-2.04h7.66v2.04h-2.54v12.72h2.54v2.04h-7.66v-2.04Z"
        />
        <path
          class="cls-2"
          d="m79.93,26.7c-.72.41-1.54.6-2.45.6-3.58,0-5.71-3.14-5.71-8.67s2.18-8.66,5.74-8.66,5.74,3.14,5.74,8.66c0,2.95-.65,5.28-1.82,6.74l1.51,1.9-1.54,1.3-1.46-1.87Zm-1.46-1.75l-1.61-2.09,1.51-1.3,1.44,1.87c.53-.98.84-2.59.84-4.8,0-4.49-1.18-6.38-3.19-6.38s-3.1,1.87-3.1,6.38,1.2,6.48,3.17,6.48c.34,0,.65-.07.94-.17Z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #3b82f6;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.cls-2 {
  fill: #fff;
  stroke-width: 0px;
}
</style>
