import api from "@/api";
import router from "@/router/index";
import {
  PROCESSED,
  REVIEWED,
  PENDING_REVIEW,
  PROCESSING,
  ERROR,
  NOTIFICATION_TYPE,
} from "@/utils/constants";
import parseStatusMessage from "@/utils/parseStatusMessage";

// state
const state = {
  documents: [],
  selectedDocuments: [],
  updatedDocument: null,
  refreshCount: 0,
  queryParams: {
    startRow: 0,
    endRow: 10,
    filterModel: {},
    sortModel: [],
  },
};

// getters
const getters = {
  documents(state) {
    if (state.updatedDocument == null) {
      return state.documents;
    } else {
      // map udpatedDocument and documents
      return state.documents.map((document) =>
        document.id === state.updatedDocument.id
          ? state.updatedDocument
          : document
      );
    }
  },
  selectedDocuments(state) {
    return state.selectedDocuments;
  },
  updatedDocument(state) {
    return state.updatedDocument;
  },
  refreshCount(state) {
    return state.refreshCount;
  },
};

// mutations
const mutations = {
  setDocuments(state, { documents }) {
    state.documents = documents;
  },
  setSelectedDocuments(state, payload) {
    state.selectedDocuments = payload;
  },
  updateDocument(state, { doc }) {
    state.updatedDocument = doc;
  },
  updateRefreshCount(state) {
    state.refreshCount = state.refreshCount + 1;
  },
  setQueryParams(state, payload) {
    state.queryParams = payload;
  },
};

/**
 * NOTE:
 * every update here should be mirrored with DocumentApi backend application
 * It has the same logic for SQL filter/ordering of contents
 * Place in the code:
 * https://gitlab.com/infiniaml/document-analysis/document-api-server/-/blob/master/src/extraction/ag_grid_query_param_schemas.py#L283
 * method: `_add_fields(queryset: models.QuerySet[Content]) -> models.QuerySet[Content]`
 * field: status_alias
 */
function processDocument(doc) {
  if (
    doc.status == PROCESSED ||
    doc.status == REVIEWED ||
    doc.status == PENDING_REVIEW
  ) {
    if (
      doc.generate_images_status == "requested" ||
      doc.generate_images_status == "processing"
    ) {
      doc.status = PROCESSING;
    } else if (
      doc.generate_images_status == "not_requested" ||
      doc.generate_images_status == "error" ||
      doc.generate_images_status == "expired"
    ) {
      doc.status = ERROR;
      // This will allow the error tooltip in the documents table to show a useful message
      doc.status_message =
        "Images have not been generated. Image status is: " +
        doc.generate_images_status;
    }
  }
  doc.status_message = parseStatusMessage(doc.status_message);
  return doc;
}

// actions
const actions = {
  setDocuments(context, payload) {
    context.commit("setDocuments", { documents: payload });
  },
  setSelectedDocuments(context, payload) {
    context.commit("setSelectedDocuments", payload);
  },
  updateDocument(context, { id, ...update }) {
    const doc = context.getters.documents.find((doc) => doc.id == id);
    if (!doc) return;
    const updatedDoc = processDocument({ ...doc, ...update });

    context.commit("updateDocument", { doc: updatedDoc });
  },
  removeDocuments(context, { projectId, contentIds }) {
    api()
      .delete(`projects/${projectId}/contents`, { data: contentIds })
      .then(() => {
        context.dispatch("setSelectedDocuments", []);
        context.dispatch("setDocumentIdsForDeletion", []);
        if (router.currentRoute.value.name !== "Documents") {
          router.push({ name: "Documents" });
        }
        const message = `Document${contentIds.length > 1 ? "s" : ""} removed`;
        context.dispatch("setNotification", {
          name: "Success",
          message: message,
          type: NOTIFICATION_TYPE.SUCCESS,
        });
      });
  },
  processDocuments(context, payload) {
    const apiPayload = payload.contentIds.map((contentId) => {
      return { id: contentId, generate_images: true };
    });
    return api()
      .post(`projects/${payload.projectId}/start-extraction`, apiPayload)
      .then(() => {
        context.dispatch("setDocumentIdsForProcessing", []);
        const plural = `${payload.contentIds.length > 1 ? "s" : ""}`;
        context.dispatch("setNotification", {
          name: `Document${plural} processing`,
          message: `${payload.contentIds.length} document${plural} processing.`,
          type: NOTIFICATION_TYPE.SUCCESS,
        });
        context.dispatch("refreshDocuments");
      });
  },
  markDocumentReviewStatus(context, payload) {
    api({ useInterceptCancelToken: false })
      .put(
        `projects/${payload.projectId}/contents`,
        payload.contentIds.map((id) => ({
          id,
          status: payload.status,
        }))
      )
      .then((response) => {
        context.dispatch("documentData/setStatusInfo", {
          status: response.data[0].status,
          job: response.data[0].job,
          is_from_job: response.data[0].is_from_job,
        });
        context.dispatch("setDocumentIdsForReview", []);
        context.dispatch("setDocumentIdsForNotReviewed", []);
        context.dispatch("refreshDocuments");
        let updateStatusGroups = response.data.reduce(
          (groups, document, _index, _array, status = document.status) => (
            (groups[status] || (groups[status] = [])).push(document), groups
          ),
          {}
        );
        Object.entries(updateStatusGroups).forEach(([status, group]) => {
          const message = `Document${group.length > 1 ? "s" : ""} marked as ${
            status === PROCESSED ? "not" : ""
          } reviewed`;
          context.dispatch("setNotification", {
            name: "Success",
            message: message,
            type: NOTIFICATION_TYPE.SUCCESS,
          });
        });
      });
  },
  forceFetchDocuments(context, payload) {
    const currentProjectId = router.currentRoute.value.params.projectId;
    if (parseInt(currentProjectId) === payload.project_id) {
      context.dispatch("refreshDocuments");
      if (context.rootGetters.userId !== payload.user_id) {
        context.dispatch("setNotification", {
          name: "Documents Refreshed",
          message: `${payload.user_email} deleted a document. Your documents have been refreshed.`,
          type: NOTIFICATION_TYPE.INFO,
        });
      }
    }
  },
  queryDocuments(context, { projectId, queryParams, save = true }) {
    context.dispatch("setIsLoading", true);
    return api()
      .post(`projects/${projectId}/contents/_query`, queryParams)
      .then((response) => {
        const documents = response.data.contents.map(processDocument);
        if (save) {
          context.dispatch("setDocuments", documents);
        }
        context.dispatch("setIsLoading", false);
        return { rowData: documents, rowCount: response.data.count };
      });
  },
  refreshProjectDocuments(context, { projectId }) {
    const { name, params } = router.currentRoute.value;
    const onDocsPage = name === "Documents";
    const onProject = params.projectId?.toString() === projectId?.toString();
    if (onDocsPage && onProject) {
      context
        .dispatch("queryDocuments", {
          projectId,
          queryParams: context.state.queryParams,
        })
        .catch((error) => {
          if (error.response.status == 400) {
            context.dispatch("setNotification", {
              name: "Reset Filters",
              message: "Please clear your filters and try again",
              type: NOTIFICATION_TYPE.ERROR,
              timer: false,
            });
          }
        });
      context.dispatch("refreshDocuments");
    }
  },
  exportSelectedDocuments(context, payload) {
    context.dispatch("setIsLoading", true);
    const apiPayload = {
      content_ids: payload.contentIds,
      tab_id: context.getters.tabId,
    };
    if (payload.fileName) {
      apiPayload["file_name"] = payload.fileName;
    }
    return api()
      .post(`projects/${payload.projectId}/start-bulk-export`, apiPayload)
      .then(() => {
        context.dispatch("setDocumentIdsForExport", []);
        const message = `Document${
          payload.contentIds.length > 1 ? "s" : ""
        } requested for export (this may take a few moments)`;
        context.dispatch("setNotification", {
          name: "Info",
          message,
          type: NOTIFICATION_TYPE.INFO,
        });
      })
      .finally(() => {
        context.dispatch("setIsLoading", false);
      });
  },
  exportDocuments(context, payload) {
    api()
      .get(
        `projects/${payload.projectId}/bulk-exports/${payload.bulkExportId}`,
        { custom404Handler: true }
      )
      .then((response) => {
        window.open(response.data.zip, "_blank");
      });
  },
  refreshDocuments(context) {
    context.commit("updateRefreshCount");
  },
  setQueryParams(context, payload) {
    context.commit("setQueryParams", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
