const state = {
  isConnected: false,
  projectRoom: {
    id: null,
    contentRoomId: null,
  },
};

const getters = {
  projectRoomId(state) {
    return state.projectRoom.id;
  },
  contentRoomId(state) {
    return state.projectRoom.contentRoomId;
  },
};

const mutations = {
  setProjectRoomId(state, projectId) {
    state.projectRoom.id = projectId;
  },
  removeProjectRoomId(state, projectId) {
    if (state.projectRoom.id === projectId) {
      state.projectRoom.id = null;
    }
  },
  setContentRoomId(state, contentId) {
    state.projectRoom.contentRoomId = contentId;
  },
  removeContentRoomId(state, contentId) {
    if (state.projectRoom.contentRoomId === contentId) {
      state.projectRoom.contentRoomId = null;
    }
  },
  setIsSocketConnected(state, payload) {
    state.isConnected = payload;
  },
};

const actions = {
  connectWebsocket({ commit }) {
    commit("setIsSocketConnected", true);
  },
  disconnectWebsocket({ commit }) {
    commit("setIsSocketConnected", false);
  },
  connectEvent(context) {
    context.commit("setConnected", true);
  },
  connectError(context, payload) {
    console.error(payload);
  },
  enterProjectRoom({ commit, getters }, { projectId }) {
    // only update value, ignore similar
    if (getters.projectRoomId !== projectId) {
      commit("setProjectRoomId", projectId);
    }
  },
  leaveProjectRoom(context, { projectId }) {
    context.commit("removeProjectRoomId", projectId);
  },
  enterContentRoom({ commit, getters }, { contentId, projectId }) {
    // sometimes enterContentRoom comes before enterProjectRoom
    if (!getters.projectRoomId) {
      commit("setProjectRoomId", projectId);
    }
    commit("setContentRoomId", contentId);
  },
  leaveContentRoom({ commit, dispatch }, { contentId }) {
    commit("removeContentRoomId", contentId);
    dispatch("resetContentRoom");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
