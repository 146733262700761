import { get as _get } from "lodash-es";

export function getAllSectionUuids(tree) {
  if (tree == null) {
    return [];
  }
  const result = tree.reduce((uuids, node) => {
    uuids.push(node.uuid);
    if (node.children) {
      return [...uuids, ...getAllSectionUuids(node.children)];
    } else {
      return uuids;
    }
  }, []);
  return result;
}

export function getSectionUuids(tree, path, target = "") {
  const node = _get(tree, path);
  switch (target) {
    case "parent":
      return [node.uuid];
    case "children":
      return getAllSectionUuids(node.children);
    default:
      return [node.uuid, ...getAllSectionUuids(node.children)];
  }
}

export function flattenSections(tree) {
  return tree.reduce((list, node) => {
    if (!node.children) {
      list.push(node);
      return list;
    } else {
      list.push(node, ...flattenSections(node.children));
      return list;
    }
  }, []);
}

export function getIndexesFromPath(path, intsOnly = false) {
  if (intsOnly) {
    return [...path.matchAll(/\[(\d+)\]/g)].map(([_, match]) => match);
  } else {
    const matches = [...path.matchAll(/\[(\d+)\]|\.(children)/g)]
      .map(([_, int, children]) => [int, children])
      .flat()
      .filter((i) => i != undefined);
    return matches;
  }
}

export function convertIndexesToPath(indexes) {
  if (indexes.length > 0) {
    const strings = indexes.map((i) => {
      if (!Number.isNaN(parseInt(i))) {
        return `[${i}]`;
      } else {
        return i;
      }
    });
    const joined = strings.slice(1).reduce((str, index, i) => {
      if (i % 2 === 1) {
        str += index;
      } else {
        str += "." + index;
      }
      return str;
    }, strings[0]);

    return joined;
  }
  return "";
}
