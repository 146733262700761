import { NOTIFICATION_TYPE } from "@/utils/constants";
import axios from "axios";
import store from "@/store";

const status404 = Symbol("Resolved 404 response");

/**
 * Factory to create an api instance
 * @param url
 * @param options
 * @returns {AxiosInstance}
 */
function createApi(url, options) {
  options = Object.assign(
    { requiresAuth: true, interceptError: true, useInterceptCancelToken: true },
    options
  );
  const headers =
    options.requiresAuth && store.getters.isSignedIn
      ? {
          Authorization: `Bearer ${store.state.auth.keycloak.token}`,
        }
      : {};
  let api = axios.create({
    baseURL: url,
    headers,
  });
  if (options.useInterceptCancelToken) {
    api.interceptors.request.use(
      (config) => {
        let source = axios.CancelToken.source();
        config.cancelToken = source.token;
        store.dispatch("addCancelToken", source);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isCancel(error)) return new Promise(() => {});
      if (error.response.status === 401) {
        store.dispatch("signOut");
        store.dispatch("setNotification", {
          name: "Session Expired",
          message: "Please sign in to continue",
          type: NOTIFICATION_TYPE.ERROR,
          timer: true,
        });
      } else if (error.response.status === 403) {
        if (store.state.auth.keycloak.authenticated) {
          store.dispatch("setDisplayError", "403");
        } else {
          store.dispatch("signOut");
          store.dispatch("setNotification", {
            name: "Session Expired",
            message: "Please sign in to continue",
            type: NOTIFICATION_TYPE.ERROR,
            timer: true,
          });
        }
      } else if (
        error.response.status === 404 &&
        !error.config.custom404Handler
      ) {
        store.dispatch("setDisplayError", "404");
        return Promise.reject(status404);
      } else {
        if (options.interceptError) {
          store.dispatch("setNotification", {
            name: error.name,
            message:
              error.response.data.details?.error ||
              error.response.data.detail ||
              error.message,
            type: NOTIFICATION_TYPE.ERROR,
            timer: true,
          });
        }
        return Promise.reject(error);
      }
    }
  );
  return api;
}

/**
 * exporting 2 api factories:
 * - 1 for document api - default
 * - 2 for management api - managementApi
 *
 * Usage:
 *   import api from "@/api";
 *   api(options).post();
 */
export default createApi.bind(null, "/api/v1/");
export const managementApi = createApi.bind(null, "/management/api/v1");
export const referenceApi = createApi.bind(null, "/reference/api/v1");
export const filesStreamingApi = createApi.bind(null, "/files/api/v1");

/**
 * Is error was resolved, skip it.
 * Otherwise re-throw it again
 */
export function skipResolvedError(error) {
  if (error !== status404) throw error;
}
