import io from "socket.io-client";

// see https://socket.io/docs/v4/client-options/ for details
const socket = io({
  extraHeaders: {
    // this will be set via vuex in the websocket store action connectWebsocket
    Authentication: "",
  },
  autoConnect: false,
  reconnectionAttempts: 20,
  debug: true,
});

export default socket;
