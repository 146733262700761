import { datadogRum } from "@datadog/browser-rum";
import api from "@/api";
import { WORD } from "@/utils/constants";
import router from "@/router";

const SEARCH_PER = 100;

const initState = () => ({
  searchTerm: "",
  searchPage: null,
  searchTotal: null,
  currentItemIndex: null,
  foundItems: null,
  isLoadingResults: false,
});

// state
const state = { ...initState() };

// getters
const getters = {
  searchTerm(state) {
    return state.searchTerm;
  },
  searchPage(state) {
    return state.searchPage;
  },
  searchTotal(state) {
    return state.searchTotal;
  },
  foundItems(state) {
    return state.foundItems;
  },
  currentItem(state) {
    return state.foundItems?.[state.currentItemIndex] ?? null;
  },
  currentItemIndex(state) {
    return state.currentItemIndex;
  },
  currentItemPosition(state) {
    if (state.searchTotal == 0) {
      return 0;
    } else if (state.searchPage !== null && state.currentItemIndex !== null) {
      return (state.searchPage - 1) * SEARCH_PER + state.currentItemIndex + 1;
    } else {
      return null;
    }
  },
  isFirstCurrentItemIndex(state) {
    return state.currentItemIndex === 0;
  },
  isLastCurrentItemIndex(state) {
    return state.currentItemIndex === state.foundItems?.length - 1;
  },
  isLastCurrentItemPosition(state, getters) {
    return getters.currentItemPosition === state.searchTotal;
  },
  isLoadingResults(state) {
    return state.isLoadingResults;
  },
  isSearchInProgress(state) {
    return state.isLoadingResults || state.searchTotal !== null;
  },
  areResultsLessThanOnePageLong(state) {
    if (state.searchTotal !== null) return state.searchTotal <= SEARCH_PER;
    else return false;
  },
};

// mutations
const mutations = {
  setSearchTerm(state, payload) {
    state.searchTerm = payload;
  },
  setSearchPage(state, payload) {
    state.searchPage = payload;
  },
  setSearchTotal(state, payload) {
    state.searchTotal = payload;
  },
  setFoundItems(state, payload) {
    state.foundItems = payload;
  },
  setCurrentItemIndex(state, payload) {
    state.currentItemIndex = payload;
  },
  setisLoadingResults(state, payload) {
    state.isLoadingResults = payload;
  },
  resetSearch(state) {
    Object.assign(state, initState());
  },
};

// actions
const actions = {
  async doSearch(context) {
    const { accountId, projectId, contentId } =
      router.currentRoute.value.params;
    datadogRum.addAction("search", {
      type: "document",
      method: "server",
      accountId,
      projectId,
      contentId,
    });
    if (context.getters.searchTerm === "") {
      context.commit("resetSearch");
      return;
    }
    context.commit("setisLoadingResults", true);
    const { pagination, result } = await fetchSearchResults(
      projectId,
      contentId,
      1,
      context.getters.searchTerm
    );
    context.commit("setisLoadingResults", false);
    context.commit("setSearchTerm", context.getters.searchTerm);
    context.commit("setSearchPage", pagination.page);
    context.commit("setSearchTotal", pagination.count);
    context.commit("setFoundItems", result);
    context.commit("setCurrentItemIndex", 0);
  },
  async nextWord({ commit, getters }) {
    if (getters.isLoadingResults) return;
    if (getters.isLastCurrentItemIndex) {
      const nextPage = getters.isLastCurrentItemPosition
        ? 1
        : getters.searchPage + 1;
      if (getters.areResultsLessThanOnePageLong) {
        commit("setCurrentItemIndex", 0);
        return;
      }
      const { projectId, contentId } = router.currentRoute.value.params;
      commit("setisLoadingResults", true);
      const { pagination, result } = await fetchSearchResults(
        projectId,
        contentId,
        nextPage,
        getters.searchTerm
      );
      commit("setisLoadingResults", false);
      commit("setSearchPage", pagination.page);
      commit("setSearchTotal", pagination.count);
      commit("setFoundItems", result);
      commit("setCurrentItemIndex", 0);
    } else {
      commit("setCurrentItemIndex", getters.currentItemIndex + 1);
    }
  },
  async prevWord({ commit, getters }) {
    if (getters.isLoadingResults) return;
    if (getters.isFirstCurrentItemIndex) {
      if (getters.areResultsLessThanOnePageLong) {
        commit("setCurrentItemIndex", getters.foundItems.length - 1);
        return;
      }
      const { projectId, contentId } = router.currentRoute.value.params;
      commit("setisLoadingResults", true);
      const { pagination, result } = await fetchSearchResults(
        projectId,
        contentId,
        getters.searchPage - 1,
        getters.searchTerm
      );
      commit("setisLoadingResults", false);
      commit("setSearchPage", pagination.page);
      commit("setSearchTotal", pagination.count);
      commit("setFoundItems", result);
      commit("setCurrentItemIndex", result.length - 1);
    } else {
      commit("setCurrentItemIndex", getters.currentItemIndex - 1);
    }
  },
  resetSearch({ commit }) {
    commit("resetSearch");
  },
  setSearchTerm(context, payload) {
    context.commit("setSearchTerm", payload);
  },
};

async function fetchSearchResults(projectId, contentId, page, searchTerm) {
  const resp = await api().post(
    `projects/${projectId}/contents/${contentId}/blocks/_query`,
    {
      pagination: {
        page: page,
        per: SEARCH_PER,
      },
      query: {
        type: "MultiWord",
        block_type: WORD,
        text: {
          type: "contains",
          value: searchTerm,
        },
      },
    }
  );
  return resp.data;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
